export const HeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: false,
        label: 'Id',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_id',
        numeric: true,
        disablePadding: false,
        label: 'User Id',
        sort: true,
        type: 'text',
    },
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: true,
        disablePadding: true,
        label: 'Last Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'text',
    },
    {
        id: 'assigned',
        numeric: true,
        disablePadding: false,
        label: 'Assigned At',
        sort: true,
        type: 'date',
    },
];

export const dataFields = [
    'id',
    'user_id',
    'first_name',
    'last_name',
    'user_email',
    'user_phone',
    'assigned',
];
