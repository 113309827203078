import { useQuery } from 'react-query';

import { Employee } from '../types';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllEmployees = async (): Promise<Employee[]> => {
    const { data } = await axios.get(`v1/get-assigned-employees/all`);
    return data.employees;
};

type TGetAssignedEmployees = {
    params: {
        location_id: number;
        role: string;
    };
};
export const getAssignedEmployees = async ({ params }: TGetAssignedEmployees) => {
    const { data } = await axios.get(
        `v1/get-assigned-employees?location_id=${params?.location_id}&role=${params?.role}`
    );
    return data.employees;
};
