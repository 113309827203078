import { CorporateFare, EditOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { GiftCard, User } from '../type';
import { handleDateFormat } from '@/utils/commonHelperFunc';
import Delete from '@/components/Icons/Delete';
import { Button } from '@/components/Elements';

import { axios } from '@/lib/axios';

const ViewGiftCard = ({ giftcardData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<GiftCard | null>(null);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [transactionData, setTransactionData] = useState<any | null>(null);

    const getTransactionData = async (id: number) => {
        const { data } = await axios.get(`v1/get-user-loyalty-points/${id}`);
        setTransactionData(data);
    };

    const changeStatus = async () => {
        await axios
            .post('v1/update-gift-card', {
                id: giftcardData.id,
                active: !data.active,
            })
            .then((response) => {
                // const giftcard: any = {};
                // giftcard.id = response.data.gift.id;
                // giftcard.gift_card_code = response.data.gift.gift_card_code;
                // giftcard.customer =
                //     response.data.gift.gift_card_recipient_first_name +
                //     ' ' +
                //     response.data.gift.gift_card_recipient_last_name;
                // giftcard.gift_card_purchaser =
                //     response.data.gift.gift_card_purchaser_first_name +
                //     ' ' +
                //     response.data.gift.gift_card_purchaser_last_name;
                // giftcard.gift_card_purchased = response.data.gift.gift_card_purchased;
                // giftcard.gift_card_amout =
                //     '$' +
                //     (response.data.gift.gift_card_amout > 0
                //         ? (response.data.gift.gift_card_amout / 100).toFixed(2)
                //         : 0.0);
                // giftcard.gift_card_remain_amout =
                //     '$' +
                //     (response.data.gift.gift_card_remain_amout > 0
                //         ? (response.data.gift.gift_card_remain_amout / 100).toFixed(2)
                //         : 0.0);
                // giftcard.active = response.data.gift.active;
                setData((prev: any) => ({ ...prev, active: response.data.gift.active }));
            });
    };

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setData(giftcardData);
        getTransactionData(giftcardData.id);
    }, [giftcardData]);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        GiftCard - {data?.gift_card_code}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <PermIdentityOutlinedIcon className="pColor" />
                                <h4>{data?.customer}</h4>
                            </div>
                            <div className="left-info">
                                <CardGiftcardOutlinedIcon className="pColor" />
                                <strong>Remaining Budget:</strong>
                                <span style={{ marginLeft: '10px' }}>
                                    {data?.gift_card_remain_amout}
                                </span>
                            </div>
                        </div>
                        <div
                            className="info-header"
                            style={{ paddingTop: '22px', paddingBottom: '5px' }}
                        >
                            <div className="left-info">
                                <strong>Details</strong>
                            </div>
                            <div className="flex gap-3">
                                <label htmlFor="active">Active:</label>
                                {data?.active ? 'Yes' : 'No'}
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        name="active"
                                        checked={data?.active}
                                        onClick={() => {
                                            changeStatus();
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <ul className="user-details-list" style={{ paddingBottom: '10px' }}>
                            <li>
                                <strong>Last Used:</strong> <span></span>
                            </li>
                            <li>
                                <strong>Purchased Amount:</strong>{' '}
                                <span style={{ marginLeft: '10px' }}>{data?.gift_card_amout}</span>
                            </li>
                            <li>
                                <strong>Purchased:</strong>
                                <span>
                                    {moment(data?.gift_card_purchased).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Created By:</strong>
                                <span>{data?.gift_card_purchaser}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewGiftCard;
