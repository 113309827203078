import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllGiftCard } from '../api/CRUD/getAllGiftCard';
import CreateGiftCard from '../components/create';
import ViewGiftCard from '../components/view';
import { HeadCells, dataFields } from '../constant';
import { GiftCard, User } from '../type';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import { axios } from '@/lib/axios';
import '../styles/styles.css';

export const GiftCardPage = () => {
    const { close, open, isOpen } = useDisclosure();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [giftcard, setGiftCard] = useState<GiftCard[]>([]);
    const [createGiftCard, setCreateGiftCard] = useState<boolean>(false);
    const [editGiftCard, setEditGiftCard] = useState<GiftCard | null>(null);
    const [viewGiftCard, setviewGiftCard] = useState<GiftCard | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    // const [checkboxFilter, setCheckboxFilter] = useState<string>('all');
    const [confirmMsg, setConfirmMsg] = useState<User | any>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getGiftCard() {
        setIsLoading(true);
        let resMod = [];
        const res = await getAllGiftCard();

        resMod = res.map((item: any) => {
            const giftcard: any = {};
            giftcard.id = item.id;
            giftcard.gift_card_code = item.gift_card_code;
            giftcard.customer =
                item.gift_card_recipient_first_name + ' ' + item.gift_card_recipient_last_name;
            giftcard.gift_card_purchaser =
                item.gift_card_purchaser_first_name + ' ' + item.gift_card_purchaser_last_name;
            giftcard.gift_card_purchased = item.gift_card_purchased;
            giftcard.gift_card_amout = '$' + (item.gift_card_amout / 100).toFixed(2);
            giftcard.gift_card_remain_amout = '$' + (item.gift_card_remain_amout / 100).toFixed(2);
            giftcard.active = item.active;

            return giftcard;
        });

        setGiftCard(resMod);
        setIsLoading(false);
    }
    useEffect(() => {
        getGiftCard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!giftcard) return null;
    const filteredEmployees = giftcard?.filter((giftcard: GiftCard | any) => {
        if (searchVal || locationFilter) {
            if (searchVal) {
                return (
                    giftcard?.customer?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    giftcard?.gift_card_code?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    giftcard?.gift_card_purchaser?.toLowerCase()?.includes(searchVal.toLowerCase())
                );
            }
        } else {
            return giftcard;
        }
    });

    const deleteGiftCard = async (id: any) => {
        const updateData = {
            active: 0,
        };

        const { success, message }: any = await axios.post(
            `v1/update-giftcard-multiplier/${id}`,
            updateData
        );
        return { success, message };
    };

    const handleDelete = async (giftcardData: GiftCard) => {
        // await deleteGiftCard(giftcardData.id).then(() => {
        //     addNotification({
        //         type: 'success',
        //         title: 'GiftCard Deleted',
        //     });
        // });
        getGiftCard();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditGiftCard(null);
                setviewGiftCard(data);
                setCreateGiftCard(false);
                open();
                break;
            case 'edit':
                setviewGiftCard(null);
                setEditGiftCard(data);
                setCreateGiftCard(false);
                open();
                break;
            case 'add-user':
                setviewGiftCard(null);
                setEditGiftCard(null);
                setCreateGiftCard(false);
                open();
                break;
            case 'add-invoice':
                setviewGiftCard(null);
                setEditGiftCard(null);
                setCreateGiftCard(false);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewGiftCard(null);
        setEditGiftCard(null);
        setCreateGiftCard(false);
        close();
        getGiftCard();
    };

    // const LocationOptions = new Set(giftcard?.map((item: any) => item.location_name));

    return (
        <ContentLayout title="" topNavBarData={{ num: giftcard.length, title: 'Gift Cards' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        size={50}
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>

                                {/* <div className="checkboxes flex items-center justify-center ml-4 gap-4 pt-2">
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="all"
                                                name="all"
                                                value="All"
                                                checked={checkboxFilter === 'all'}
                                                onChange={() => {
                                                    checkboxFilter !== 'all' &&
                                                        setCheckboxFilter('all');
                                                }}
                                            />
                                            <span>All</span>
                                        </label>
                                    </div>
                                    <div className="all-bx live">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="pos_terminal"
                                                name="pos_terminal"
                                                value="pos_terminal"
                                                checked={checkboxFilter === 'POS Terminal'}
                                                onChange={() => {
                                                    checkboxFilter === 'POS Terminal'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('POS Terminal');
                                                }}
                                            />
                                            <span>POS&nbsp;Terminal</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="mobile_app"
                                                name="mobile_app"
                                                value="mobile_app"
                                                checked={checkboxFilter === 'Mobile App'}
                                                onChange={() => {
                                                    checkboxFilter === 'Mobile App'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Mobile App');
                                                }}
                                            />
                                            <span>Mobile&nbsp;App</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="administrator"
                                                name="administrator"
                                                value="administrator"
                                                checked={checkboxFilter === 'Administrator'}
                                                onChange={() => {
                                                    checkboxFilter === 'Administrator'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Administrator');
                                                }}
                                            />
                                            <span>Administrator</span>
                                        </label>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="add-btn" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => (setCreateGiftCard(!createGiftCard), open())}
                                >
                                    Add Gift Card
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                // optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {createGiftCard && <CreateGiftCard closeDialog={closeDialog} />}
                    {viewGiftCard && (
                        <ViewGiftCard
                            giftcardData={viewGiftCard}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {/* {editGiftCard && <EditGiftCard giftcardData={editGiftCard} closeDialog={closeDialog} />} */}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
