import { useEffect, useState } from 'react';
import { getAllLoyalty, useLoyalty } from '../api/CRUD/getAllLoyalty';
import EditLoyalty from '../components/edit';
import ViewLoyalty from '../components/view';
import { HeadCells, dataFields } from '../constant';
import { Loyalty } from '../type';
import { Dialog, Spinner } from '@/components/Elements';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { ContentLayout } from '@/components/Layout';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useNotificationStore } from '@/stores/notifications';
//import { HeadCells } from '@/features/users/constant';

import { axios } from '@/lib/axios';
import '../styles/styles.css';

export const LoyaltyPage = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const loyaltyQuery = useLoyalty();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loyalty, setLoyalty] = useState<Loyalty[]>([]);
    const [createLoyalty, setCreateLoyalty] = useState<boolean>(false);
    const [editLoyalty, setEditLoyalty] = useState<Loyalty | null>(null);
    const [viewLoyalty, setviewLoyalty] = useState<Loyalty | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getLoyalty() {
        setIsLoading(true);
        let resMod = [];
        const res = await getAllLoyalty();

        resMod = res.map((item) => {
            const transaction_order = item.order_type.split('-');

            const loyalty: any = {};
            loyalty.active = item.active;
            loyalty.base = item.base;
            loyalty.date_created = item.date_created;
            loyalty.date_updated = item.date_updated;
            loyalty.id = item.id;
            loyalty.location_name = item.location_name;
            loyalty.multiplier = item.multiplier;
            loyalty.transaction_type = transaction_order[0];
            loyalty.order_type = transaction_order[1];
            return loyalty;
        });

        setLoyalty(resMod);
        setIsLoading(false);
    }
    useEffect(() => {
        getLoyalty();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loyaltyQuery.data, close]);

    if (loyaltyQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!loyalty) return null;
    const filteredEmployees = loyalty?.filter((loyalty: Loyalty) => {
        if (searchVal || locationFilter) {
            if (loyalty?.location_name === locationFilter) {
                return loyalty;
            }
            if (searchVal) {
                return (
                    loyalty?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    loyalty?.order_type?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    loyalty?.transaction_type?.toLowerCase()?.includes(searchVal.toLowerCase())
                );
            }
        } else {
            return loyalty;
        }
    });

    const deleteLoyalty = async (id: any) => {
        const updateData = {
            active: 0,
        };

        const { success, message }: any = await axios.post(
            `v1/update-loyalty-multiplier/${id}`,
            updateData
        );
        loyaltyQuery.refetch();
        return { success, message };
    };

    const handleDelete = async (loyaltyData: Loyalty) => {
        await deleteLoyalty(loyaltyData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Loyalty Deleted',
            });
        });
        getLoyalty();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditLoyalty(null);
                setviewLoyalty(data);
                setCreateLoyalty(false);
                open();
                break;
            case 'edit':
                setviewLoyalty(null);
                setEditLoyalty(data);
                setCreateLoyalty(false);
                open();
                break;
            case 'add-user':
                setviewLoyalty(null);
                setEditLoyalty(null);
                setCreateLoyalty(false);
                open();
                break;
            case 'add-invoice':
                setviewLoyalty(null);
                setEditLoyalty(null);
                setCreateLoyalty(false);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewLoyalty(null);
        setEditLoyalty(null);
        setCreateLoyalty(false);
        close();
    };

    const LocationOptions = new Set(loyalty?.map((item) => item.location_name));

    return (
        <ContentLayout title="" topNavBarData={{ num: loyalty.length, title: 'Loyalty' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group pos-dev">
                                    <select
                                        className="form-control ml-1 w-auto pos-select"
                                        name="location"
                                        value={locationFilter}
                                        onChange={(e) => setLocationFilter(e.target.value)}
                                    >
                                        <option value="">Select Location</option>
                                        {[...LocationOptions]?.map((location_name) => (
                                            <option key={location_name} value={location_name}>
                                                {location_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={true}
                optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {/* {createLoyalty && <CreatePos closeDialog={closeDialog} />} */}
                    {viewLoyalty && (
                        <ViewLoyalty
                            loyaltyData={viewLoyalty}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editLoyalty && (
                        <EditLoyalty
                            loyaltyQuery={loyaltyQuery}
                            loyaltyData={editLoyalty}
                            closeDialog={closeDialog}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
