export const HeadCells = [
    {
        id: 'product_image',
        numeric: false,
        disablePadding: true,
        label: 'Product Images',
        sort: true,
        type: 'image',
    },
    {
        id: 'product_name',
        numeric: false,
        disablePadding: true,
        label: 'Product Name',
        sort: true,
        type: 'text',
    },
];

export const dataFields = [
    'product_image',
    'product_name'
];
