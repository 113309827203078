/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-unknown-property */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as z from 'zod';
import editCheck from '../../../assets/edit-check.png';
import { axios } from '@/lib/axios';
import { Form, InputField } from '@/components/Form';
import { Button, Spinner } from '@/components/Elements';
import ShowUsers from '@/features/employees/components/ShowUsers';
import { useUsers } from '@/features/users/api/getUsers';
const schema = z.object({});

type LoginValues = {};

const EditCoupon = ({ couponData, closeDialog }: any) => {
    const intialCouponData = {
        id: couponData?.id,
        active: couponData?.active,
        coupon_days: couponData?.coupon_days,
        coupon_code__type_name: couponData?.coupon_code__type_name,
        coupon_days_start: '12:06:30',
        coupon_days_end: '08:16:20',
        deduction_mode: couponData?.deduction_mode,
        deduction_type: couponData?.deduction_type,
        deduction_amount: couponData?.deduction_amount,
        deduction_min_cart: couponData?.deduction_min_cart,
        deduction_max_dollar: couponData?.deduction_max_dollar,
        coupon_type_id: couponData?.coupon_type_id,
        coupon_code: couponData?.coupon_code,
        coupon_name: couponData?.coupon_name,
        coupon_description: couponData?.coupon_description,
        coupon_code_uses: couponData?.coupon_code_uses,
        coupon_code_used: couponData?.coupon_code_used,
        coupon_budget: couponData?.coupon_budget,
        coupon_location_id: couponData?.coupon_location_id,
        coupon_mode_id: couponData?.coupon_mode_id,
        coupon_ambassador_id: couponData?.coupon_ambassador_id,
        coupon_user_id: couponData?.coupon_user_id,
        coupon_activation_time: couponData?.coupon_activation_time,
        coupon_activation_time_date: '',
        coupon_activation_time_time: '',
        coupon_expiration_time: couponData?.coupon_expiration_time,
        coupon_expiration_time_date: '',
        coupon_expiration_time_time: '',
        isAlwaysAvailable: false,
    };

    const [data, setData] = useState(intialCouponData);
    const [locations, setLocations] = useState();
    const usersQuery = useUsers();
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
    const [searchVal, setSearchVal] = useState<string>(
        couponData?.coupon_user_id ? couponData?.coupon_user_id : ''
    );
    const [isShowList, setIsShowList] = useState<boolean>(false);

    useEffect(() => {
        getAllLocations();
    }, []);

    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/get-all-locations`);
        setLocations(data.locations);
    };

    //////
    const handleSearch = () => {
        const tempFilteredUsers = usersQuery?.data?.filter((user) => {
            return (
                user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.user_company?.toLowerCase()?.includes(searchVal.toLowerCase())
            );
        });
        setIsShowList(true);
        setFilteredUsers([...tempFilteredUsers]);
    };

    const handleSearchInput = (e: any) => {
        setSearchVal(e.target.value);
    };
    const updateCoupon = async (data: any) => {
        const updateData = {
            active: data?.active === 1 ? true : false,
            coupon_days: data?.coupon_days,
            coupon_days_start:
                data.coupon_activation_time.split(' ')[0] + ' ' + data?.coupon_days_start,
            coupon_days_end:
                data.coupon_expiration_time.split(' ')[0] + ' ' + data?.coupon_days_end,
            deduction_mode: data?.deduction_mode,
            deduction_type: data?.deduction_type,
            deduction_amount: data?.deduction_min_cart,
            deduction_min_cart: data?.deduction_min_cart,
            deduction_max_dollar: data?.deduction_max_dollar,
            coupon_type_id: data?.coupon_type_id,
            coupon_code: data?.coupon_code,
            coupon_name: data?.coupon_name,
            coupon_description: data?.coupon_description,
            coupon_code_uses: +data?.coupon_code_uses,
            coupon_budget: +data?.coupon_budget,
            coupon_location_id: data?.coupon_location_id,
            coupon_mode_id: data?.coupon_mode_id,
            coupon_ambassador_id: data?.coupon_ambassador_id,
            coupon_user_id: data?.coupon_user_id,
            coupon_activation_time: data?.coupon_activation_time_date
                ? moment(
                      data?.coupon_activation_time_date + ' ' + data?.coupon_activation_time_time
                  ).format('YYYY-MM-DD HH:mm:ss')
                : moment(data?.coupon_activation_time).format('YYYY-MM-DD HH:mm:ss'),
            coupon_expiration_time: data?.coupon_expiration_time_date
                ? moment(
                      data?.coupon_expiration_time_date + ' ' + data?.coupon_expiration_time_time
                  ).format('YYYY-MM-DD HH:mm:ss')
                : moment(data?.coupon_expiration_time).format('YYYY-MM-DD HH:mm:ss'),
        };

        const { success, message }: any = await axios.post(
            `v1/update-coupon-code/${data?.id}`,
            updateData
        );
        return { success, message };
    };

    const handleSubmit = async () => {
        updateCoupon(data)
            .then()
            .catch()
            .finally(() => closeDialog());
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = e.target;
        setData({ ...data, [name]: value });
    };

    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Edit code - {data?.coupon_code}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> schema={schema}>
                            {({ register, formState }: any) => (
                                <>
                                    <div
                                        className="info-header d-flex justify-content-between align-items-baseline"
                                        style={{ paddingTop: '0px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <h4>Details</h4>
                                        </div>
                                        <div className="flex gap-3">
                                            <label htmlFor="active">Active:</label>
                                            {data?.active ? 'Yes' : 'No'}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    checked={data?.active || false}
                                                    value={data?.active}
                                                    onChange={(e) => {
                                                        setData((prev) => ({
                                                            ...prev,
                                                            active: !prev?.active,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="coupon_type_id"
                                                className="control-label"
                                            >
                                                Type
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="coupon_type_id"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.coupon_type_id}
                                                    error={formState.errors['coupon_type_id']}
                                                    registration={register('coupon_type_id')}
                                                >
                                                    <option value="">
                                                        {data.coupon_code__type_name}
                                                    </option>
                                                    <option value="1">Global</option>
                                                    <option value="2">Location</option>
                                                    <option value="3">Mode</option>
                                                    <option value="5">User</option>
                                                    <option value="6">Budget</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="coupon_code" className="control-label">
                                                Coupon Code
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code"
                                                    placeholder="ebfXX45N"
                                                    value={data.coupon_code}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code']}
                                                    registration={register('coupon_code')}
                                                />
                                            </div>
                                        </div>

                                        {/* 2 -> Location */}
                                        {data.coupon_type_id == 2 && (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="coupon_location_id"
                                                    className="control-label"
                                                >
                                                    Location
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="coupon_location_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.location_id}
                                                        error={formState.errors['location_id']}
                                                        registration={register('location_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        {locations.map(({ id, location_name }) => (
                                                            <option value={id} key={id}>
                                                                {location_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {/* 3 -> Mode */}
                                        {data.coupon_type_id == 3 && (
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="coupon_mode_id"
                                                    className="control-label"
                                                >
                                                    Coupon Mode
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="coupon_mode_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.coupon_mode_id}
                                                        error={formState.errors['coupon_mode_id']}
                                                        registration={register('coupon_mode_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        <option value={1}>Web</option>
                                                        <option value={2}>Mobile</option>
                                                        <option value={5}>In Store</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}

                                        {/* 5 -> User */}
                                        {data.coupon_type_id == 5 && (
                                            <div style={{ padding: '0px !important' }}>
                                                <div
                                                    className="modal-body"
                                                    style={{ padding: '0px !important' }}
                                                >
                                                    <div
                                                        className="input-group search"
                                                        style={{ width: '100%', display: 'felx' }}
                                                    >
                                                        <label
                                                            htmlFor="user_id_coupon"
                                                            className="control-label"
                                                        >
                                                            Search User
                                                        </label>
                                                        <div className="w-full flex items-center">
                                                            <div className="w-full relative">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    style={{ marginBottom: 0 }}
                                                                    placeholder="Search User"
                                                                    value={searchVal}
                                                                    onChange={(e) =>
                                                                        handleSearchInput(e)
                                                                    }
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text">
                                                                        <i className="fas fa-search"></i>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    className="input-group-close cursor-pointer"
                                                                    onClick={() => {
                                                                        setSearchVal('');
                                                                        setIsShowList(false);
                                                                    }}
                                                                    style={{ zIndex: 10 }}
                                                                >
                                                                    <span className="input-group-text">
                                                                        <i className="fas fa-times"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <Button
                                                                variant="primary"
                                                                style={{ marginLeft: '10px' }}
                                                                onClick={() => handleSearch()}
                                                            >
                                                                Search
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {isShowList ? (
                                                        <ShowUsers
                                                            hideAdditionalDetails={true}
                                                            // getEmployees={()}
                                                            usersData={filteredUsers}
                                                            showList={isShowList ? true : false}
                                                            closeDialog={closeDialog}
                                                            getSelectedUser={(res: any) => {
                                                                setData({
                                                                    ...data,
                                                                    coupon_user_id: res?.id,
                                                                });
                                                            }}
                                                            // stores={stores}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_activation_time_date"
                                                className="control-label"
                                            >
                                                Activation Date
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_activation_time_date"
                                                    type="date"
                                                    value={
                                                        data.coupon_activation_time_date ||
                                                        data.coupon_activation_time.split('T')[0]
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_activation_time_time"
                                                className="control-label"
                                            >
                                                Activation Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_activation_time_time"
                                                    type="time"
                                                    value={
                                                        data.coupon_activation_time_time ||
                                                        moment
                                                            .utc(data.coupon_activation_time)
                                                            .format('HH:mm')
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_expiration_time_date"
                                                className="control-label"
                                            >
                                                Expiration Date
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_expiration_time_date"
                                                    type="date"
                                                    value={
                                                        data.coupon_expiration_time_date ||
                                                        data.coupon_expiration_time.split('T')[0]
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_expiration_time_time"
                                                className="control-label"
                                            >
                                                Expiration Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_expiration_time_time"
                                                    type="time"
                                                    value={
                                                        data.coupon_expiration_time_time ||
                                                        moment
                                                            .utc(data.coupon_expiration_time)
                                                            .format('HH:mm')
                                                    }
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="deduction_type"
                                                className="control-label"
                                            >
                                                Deduction Type
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="deduction_type"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.deduction_type}
                                                    error={formState.errors['deduction_type']}
                                                    registration={register('deduction_type')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="subtotal">Subtotal</option>
                                                    <option value="delivery">Delivery</option>
                                                    {/* <option value="percentage">Percentage</option> */}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <label
                                                htmlFor="deduction_min_cart"
                                                className="control-label"
                                            >
                                                Deduction Min Order
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="deduction_min_cart"
                                                    placeholder="$50"
                                                    value={data.deduction_min_cart}
                                                    onChange={handleChange}
                                                    error={formState.errors['deduction_min_cart']}
                                                    registration={register('deduction_min_cart')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="deduction_max_dollar"
                                                className="control-label"
                                            >
                                                Deduction Max Value
                                            </label>
                                            <div className="form-group  add-code-cpn">
                                                <InputField
                                                    className="form-control"
                                                    name="deduction_max_dollar"
                                                    placeholder="$100"
                                                    value={data.deduction_max_dollar}
                                                    onChange={handleChange}
                                                    error={formState.errors['deduction_max_dollar']}
                                                    registration={register('deduction_max_dollar')}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <label
                                                htmlFor="deduction_mode"
                                                className="control-label"
                                            >
                                                Deduction Mode
                                            </label>
                                            <div className="form-group">
                                                <select
                                                    className="form-control"
                                                    name="deduction_mode"
                                                    required
                                                    onChange={handleChange}
                                                    value={data.deduction_mode}
                                                    error={formState.errors['deduction_mode']}
                                                    registration={register('deduction_mode')}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="dollar">Dollar</option>
                                                    <option value="percent">Percentage</option>
                                                </select>
                                            </div>
                                        </div>

                                        {data.deduction_type == 'percentage' && (
                                            <div className="col-md-3">
                                                <label
                                                    htmlFor="deduction_max_dollar"
                                                    className="control-label"
                                                >
                                                    Deduction Percentage
                                                </label>
                                                <div className="form-group  add-code-cpn">
                                                    <InputField
                                                        className="form-control"
                                                        name="deduction_percentage"
                                                        placeholder="20%"
                                                        value={data.deduction_percentage}
                                                        onChange={handleChange}
                                                        error={
                                                            formState.errors['deduction_percentage']
                                                        }
                                                        registration={register(
                                                            'deduction_percentage'
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="info-header"
                                        style={{ paddingTop: '0px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <h4 className="pb-2">Advanced Options</h4>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_code_uses"
                                                className="control-label"
                                            >
                                                Uses
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code_uses"
                                                    placeholder="$100"
                                                    value={data.coupon_code_uses}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code_uses']}
                                                    registration={register('coupon_code_uses')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_code_used"
                                                className="control-label"
                                            >
                                                Used
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_code_used"
                                                    placeholder="$100"
                                                    value={data.coupon_code_used}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_code_used']}
                                                    registration={register('coupon_code_used')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                htmlFor="coupon_budget"
                                                className="control-label"
                                            >
                                                Budget
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="coupon_budget"
                                                    placeholder="$100"
                                                    value={data.coupon_budget}
                                                    onChange={handleChange}
                                                    error={formState.errors['coupon_budget']}
                                                    registration={register('coupon_budget')}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex gap-3">
                                            <label htmlFor="active">Always Available:</label>
                                            {data?.isAlwaysAvailable ? 'Yes' : 'No'}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    defaultChecked={
                                                        data?.isAlwaysAvailable || false
                                                    }
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="monday"
                                                        name="monday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 2)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Monday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="tuesday"
                                                        name="tuesday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 3)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Tuesday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="wednesday"
                                                        name="wednesday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 4)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Wednesday</span>
                                                </label>
                                            </div>
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="thursday"
                                                        name="thursday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 5)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Thursday</span>
                                                </label>

                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="friday"
                                                        name="friday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 6)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Friday</span>
                                                </label>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="saturday"
                                                        name="saturday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 7)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Saturday</span>
                                                </label>
                                            </div>
                                            <div className="flex gap-4">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="sunday"
                                                        name="sunday"
                                                        defaultChecked={
                                                            data?.coupon_days
                                                                .split('')
                                                                .find((e: any) => e == 1)
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <span>Sunday</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_days_start"
                                                className="control-label"
                                            >
                                                Start Active Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_days_start"
                                                    type="time"
                                                    value={data.coupon_days_start}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <label
                                                htmlFor="coupon_days_end"
                                                className="control-label"
                                            >
                                                End Active Time
                                            </label>
                                            <div className="form-group">
                                                <input
                                                    name="coupon_days_end"
                                                    type="time"
                                                    value={data.coupon_days_end}
                                                    className="form-control timePicker"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<img src={editCheck} alt="editCheck" />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCoupon;
