/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';

import moment from 'moment';
import NewOrder1 from './NewOrder1';
import ShowCorps from './ShowCorps';
import ShowUsers from './ShowUsers';
import { Button, Spinner } from '@/components/Elements';

import { getAllCorps } from '@/features/corporation/api/CRUD/getAllCorps';
import { Corporation, User } from '@/features/corporation/type';
import { getUsers } from '@/features/users/api/getUsers';

//import ShowUsers from './ShowUsers';

const CreateOrder = ({ closeDialog, selectedUserAction }: any) => {
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOrder, setIsOrder] = useState<any>({
        reward: true,
        order_type_id: '17',
        store: '',
        address: '',
        delivery_address: '',
        lat: '',
        long: '',
    });
    const [selectedSlot, setSelectedSlot] = useState({
        date: moment(Date.now()).format('YYYY-MM-DD'),
        time: '',
    });
    const [users, setUsers] = useState<User[] | Array<any>>([]);
    const [corps, setCorps] = useState<Corporation[]>([]);
    const [orderForUser, setOrderForUser] = useState<boolean>(false);
    const [selectedData, setSelected] = useState<any>(false);
    const [newOrder, setNewOrder] = useState<boolean>(false);
    const [isShowList, setIsShowList] = useState<boolean>(false);
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
    const [filteredCorps, setFilteredCorps] = useState<any[]>([]);

    const handleSearchInput = (e: any) => {
        setSearchVal(e.target.value);
    };

    const getRequeredData = () => {
        try {
            setIsLoading(true);
            getUsers().then((data) => setUsers(data));
            getAllCorps().then((data) => setCorps(data));
        } catch (err) {
            console.log('error from get required data->>', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getRequeredData();
    }, []);

    const handleSearch = () => {
        const tempFilteredUsers = users?.filter((user: any) => {
            return (
                user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.user_company?.toLowerCase()?.includes(searchVal.toLowerCase())
            );
        });

        const tempFilteredCorps = users?.filter((user: any) => {
            return (
                (user?.corporate_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    user?.corporate_email?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    user?.corporate_phone?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase())) &&
                user?.user_corp_id !== null
            );
        });
        setIsShowList(true);
        setFilteredUsers([...tempFilteredUsers]);
        setFilteredCorps([...tempFilteredCorps]);
    };

    const handleSelected = (val: any) => {
        setSelected(val);
    };

    const handleOrder = (data: any) => {
        const { name, value, type, checked } = data;
        switch (type) {
            case 'checkbox':
                setIsOrder({ ...isOrder, [name]: checked });
                break;
            default:
                setIsOrder({ ...isOrder, [name]: value });
                break;
        }
    };

    const showNewOrder = (show: any) => {
        setNewOrder(show);
    };

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    return (
        <div className="modal-dialog">
            {!newOrder ? (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Create an Order
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    {!selectedData && (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    width: 'fit-content',
                                    gap: '1rem',
                                    paddingTop: '10px',
                                }}
                            >
                                <input
                                    type="radio"
                                    name="orderForUser"
                                    id="corp"
                                    checked={!orderForUser}
                                    onChange={() => {
                                        setOrderForUser(false);
                                        setSearchVal('');
                                        setIsShowList(false);
                                    }}
                                />
                                <label htmlFor="corp">Corporation</label>

                                <input
                                    type="radio"
                                    name="orderForUser"
                                    id="user"
                                    checked={orderForUser}
                                    onChange={() => {
                                        setOrderForUser(true);
                                        setSearchVal('');
                                        setIsShowList(false);
                                    }}
                                />
                                <label htmlFor="user">User</label>
                            </div>
                            <div className="modal-body create-user">
                                <div className="input-group search">
                                    <div className="w-full flex items-center">
                                        <div className="w-full relative">
                                            <input
                                                style={{ marginBottom: 0 }}
                                                type="text"
                                                className="form-control"
                                                placeholder="Search Existing User"
                                                value={searchVal}
                                                onChange={(e) => handleSearchInput(e)}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="fas fa-search"></i>
                                                </span>
                                            </div>
                                            <div
                                                className="input-group-close cursor-pointer"
                                                onClick={() => {
                                                    setSearchVal('');
                                                    setIsShowList(false);
                                                }}
                                                style={{ zIndex: 10 }}
                                            >
                                                <span className="input-group-text">
                                                    <i className="fas fa-times"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <Button
                                            variant="primary"
                                            style={{ marginLeft: '10px' }}
                                            onClick={() => handleSearch()}
                                        >
                                            Search
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {orderForUser ? (
                        <ShowUsers
                            usersData={filteredUsers}
                            showList={isShowList ? true : false}
                            closeDialog={closeDialog}
                            handleSelected={handleSelected}
                            SelectedData={selectedData}
                            handleOrder={handleOrder}
                            orderData={isOrder}
                            setOrderData={setIsOrder}
                            showNewOrder={showNewOrder}
                            setSelectedSlot={setSelectedSlot}
                            selectedSlot={selectedSlot}
                        />
                    ) : (
                        <ShowCorps
                            usersData={filteredCorps}
                            showList={isShowList ? true : false}
                            closeDialog={closeDialog}
                            handleSelected={handleSelected}
                            SelectedData={selectedData}
                            handleOrder={handleOrder}
                            orderData={isOrder}
                            setOrderData={setIsOrder}
                            showNewOrder={showNewOrder}
                            setSelectedSlot={setSelectedSlot}
                            selectedSlot={selectedSlot}
                        />
                    )}
                </div>
            ) : (
                <NewOrder1
                    orderForUser={orderForUser}
                    setNewOrder={setNewOrder}
                    closeDialog={closeDialog}
                    isOrder={isOrder}
                    userData={selectedData}
                    selectedUserAction={selectedUserAction}
                    selectedSlot={selectedSlot}
                />
            )}
        </div>
    );
};

export default CreateOrder;
