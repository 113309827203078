export const HeadCells = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    {
        id: 'transaction_type',
        numeric: false,
        disablePadding: false,
        label: 'Transaction Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'order_type',
        numeric: false,
        disablePadding: false,
        label: 'Order Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'multiplier',
        numeric: true,
        disablePadding: false,
        label: 'Multiplier',
        sort: true,
        type: 'text',
    },
    {
        id: 'base',
        numeric: true,
        disablePadding: false,
        label: 'Base',
        sort: true,
        type: 'text',
    },
    {
        id: 'date_created',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    // 'id',
    'transaction_type',
    'order_type',
    'location_name',
    'multiplier',
    'base',
    'date_created',
    'active',
];
