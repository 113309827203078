/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-unknown-property */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import * as z from 'zod';

import { getAllMenu } from '../api/CRUD/getAllMenu';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({
    menu_name: z.string().nonempty('Please enter Menu name'),
    location_id: z.string(),
    activation: z.string().nonempty({ message: 'Please enter an activation date' }),
    expiration: z.string().nonempty({ message: 'Please enter an activation date' }),
});

type LoginValues = {
    menu_name: string;
    location_id: string;
    activation: string;
    expiration: string;
    status: boolean;
};

const CreateMenu = ({ menuData, action, closeDialog, selectedUserAction, value }: any) => {
    const { addNotification } = useNotificationStore();
    const [menuGroupData, setMenuGroupData] = useState<any>(null);
    const [selectedMenuData, setSelectedMenuData] = useState<any>(1);
    const intialMenuDataForLiveMenus = {
        // menu_name: menuData?.menu_group_name || '',
        location_id: '',
        activation: menuData?.activation_time
            ? menuData?.activation_time.split('T')[0]
            : '2023-06-22',
        expiration: menuData?.expiration_time
            ? menuData?.expiration_time.split('T')[0]
            : '2023-06-28',
        activation_time: menuData?.activation_time
            ? menuData?.activation_time.split('T')[1]
            : '00:00:00',
        expiration_time: menuData?.expiration_time
            ? menuData?.expiration_time.split('T')[1]
            : '00:00:00',
        // status: true,
        menu_goup_id: selectedMenuData,
    };
    const initialMenuDataForAllMenus = {
        menu_group_name: '',
    };
    const [data, setData] = useState<any>(
        value === 0 ? initialMenuDataForAllMenus : intialMenuDataForLiveMenus
    );
    // const [products, setProducts] = useState([]);
    const [locations, setLocations] = useState<any>();

    useEffect(() => {
        getMenuGroupData();
        getAllLocations();
    }, []);

    const getMenuGroupData = async () => {
        const data = await getAllMenu();
        setMenuGroupData(data);
    };
    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/get-locations/all`);
        const filteredActiveLocations = data?.locations?.filter((el: any) => !!el?.location_active);
        setLocations(filteredActiveLocations);
    };

    //////

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const createMenu = async (data: any) => {
        const { success, message }: any = await axios.post(
            value === 0 ? `v1/add-menu-group` : `v1/add-menu-live`,
            { ...data, ...(value === 1 && { menu_goup_id: selectedMenuData }) }
        );
        return { success, message };
    };

    const handleSubmit = async () => {
        let params = data;
        if (value === 1) {
            params = {
                location_id: data?.location_id,
                menu_goup_id: data?.menu_goup_id,
                activation: data?.activation + ' ' + data?.activation_time,
                expiration: data?.expiration + ' ' + data?.expiration_time,
            };
        }
        createMenu(params)
            .then((res) => {
                closeDialog();
                addNotification({
                    type: 'success',
                    title: res?.message,
                });
            })
            .catch();
    };

    return locations == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {action == 'create' ? 'New' : 'Copy'} Menu
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <Form<LoginValues, typeof schema> schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        {value === 0 ? (
                                            <div className="col-md-12">
                                                <label
                                                    htmlFor="menu_group_name"
                                                    className="control-label"
                                                >
                                                    Menu Name
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        name="menu_group_name"
                                                        placeholder="HotBox Special Menu"
                                                        value={data.menu_group_name}
                                                        error={formState.errors['menu_group_name']}
                                                        registration={register('menu_group_name')}
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                        {value ? (
                                            <>
                                                <div className="col-md-12">
                                                    <label
                                                        htmlFor="location_id"
                                                        className="control-label"
                                                    >
                                                        Assign Menu
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            name="location_id"
                                                            onChange={handleChange}
                                                            value={data.location_id}
                                                            error={formState.errors['location_id']}
                                                            registration={register('location_id')}
                                                        >
                                                            <option value="">Select</option>
                                                            {locations?.length > 0 &&
                                                                locations?.map(
                                                                    ({
                                                                        id,
                                                                        location_name,
                                                                    }: any) => (
                                                                        <option value={id} key={id}>
                                                                            {location_name}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <label
                                                        htmlFor="menu_group_name"
                                                        className="control-label"
                                                    >
                                                        Menu Name
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            name="menu_goup_id"
                                                            onChange={(e) =>
                                                                setSelectedMenuData(e.target.value)
                                                            }
                                                            value={selectedMenuData}
                                                            error={formState.errors['menu_goup_id']}
                                                            registration={register('menu_goup_id')}
                                                        >
                                                            <option value="">
                                                                Select Menu Group
                                                            </option>
                                                            {menuGroupData?.map(
                                                                ({ id, menu_group_name }: any) => (
                                                                    <option value={id} key={id}>
                                                                        {menu_group_name}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/* <div className="col-md-6">
                                                    <label
                                                        htmlFor="menu_group_name"
                                                        className="control-label"
                                                    >
                                                        Menu Group Name
                                                    </label>
                                                    <div className="form-group">
                                                        <InputField
                                                            name="menu_group_name"
                                                            type="text"
                                                            // value={data.activation}
                                                            placeHolder="Menu Group Name"
                                                            className="form-control timePicker"
                                                            onChange={handleChange}
                                                            error={formState.errors['activation']}
                                                            registration={register('activation')}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-6 ">
                                                        <label
                                                            htmlFor="activation"
                                                            className="control-label"
                                                        >
                                                            Activation Date
                                                        </label>
                                                        <div className="form-group w-full">
                                                            <input
                                                                name="activation"
                                                                type="date"
                                                                value={data.activation}
                                                                className="form-control timePicker w-full"
                                                                onChange={handleChange}
                                                                // error={
                                                                //     formState.errors['activation']
                                                                // }
                                                                // registration={register(
                                                                //     'activation'
                                                                // )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="activation_time"
                                                            className="control-label"
                                                        >
                                                            Activation Time
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="activation_time"
                                                                type="time"
                                                                value={data?.activation_time}
                                                                className="form-control"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="expiration"
                                                            className="control-label"
                                                        >
                                                            Expiration Date
                                                        </label>
                                                        <div className="form-group w-full">
                                                            <input
                                                                name="expiration"
                                                                type="date"
                                                                value={data.expiration}
                                                                className="form-control timePicker"
                                                                onChange={handleChange}
                                                                // error={
                                                                //     formState.errors['expiration']
                                                                // }
                                                                // registration={register(
                                                                //     'expiration'
                                                                // )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="expiration_time"
                                                            className="control-label"
                                                        >
                                                            Expiration Time
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="expiration_time"
                                                                type="time"
                                                                value={data?.expiration_time}
                                                                className="form-control timePicker"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="flex gap-3">
                                                    <label htmlFor="status">Status:</label>
                                                    {data?.status ? 'Live' : 'Draft'}
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckChecked"
                                                            name="status"
                                                            defaultChecked={data?.status || false}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div> */}
                                            </>
                                        ) : null}
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<CheckCircleOutlineIcon />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Add Menu
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateMenu;
