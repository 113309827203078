import { Product } from '../type';
import { axios } from '@/lib/axios';


export const addItemToCart = async (product: Product, cartId: number | null) => {
    const { success, data, message } = await axios.post(
        cartId ? `v1/add-to-cart?cart_group_id=${cartId}` : `v1/add-to-cart`,
        product
    );
    
    return data.cart.cart_group_id;
};
