import moment from 'moment';

import noImgFound from '../assets/placeholder.png';

export const handleDateFormat = (date) => {
    if (!date) {
        return '';
    }
    const reverseDateFormat = date?.split('T')[0].replaceAll('-', '/').split('/').reverse();
    [reverseDateFormat[0], reverseDateFormat[1]] = [reverseDateFormat[1], reverseDateFormat[0]];
    return reverseDateFormat.join('/');
};
export const handleDateTimeFormat = (date) => {
    if (!date) {
        return '';
    }
    return moment(date).format('MM/DD/YYYY, HH:mm A');
};

export const handleOnError = (e) => {
    e.target.src = noImgFound;
};
