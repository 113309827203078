import { axios } from '@/lib/axios';

export const getCategories = async () => {
    const { data } = await axios.get(`v1/get-product-categories`);
    return data.categories;
};
export const getLocationCategoriesWithProducts = async (id: string) => {
    const { data } = await axios.get(`v1/get-menus-products-by-location/${id}`);
    return data.menus;
};
