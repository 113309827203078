import './styles.css';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { UsersIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';

import avatar from '../../assets/Avatars.png';
import logo from '../../assets/logo.png';
import { svgPaths } from './SvgPaths';
import { getUsers } from '@/features/users/api/getUsers';
import { useAuth } from '@/lib/auth';
import { useAuthorization, ROLES } from '@/lib/authorization';

//images

type SideNavigationItem = {
    name: string;
    to: string;
    svgPath: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const SideNavigation = () => {
    const { checkAccess } = useAuthorization();
    const [navigation, setNavigation] = React.useState<any>([]);
    const getNavigationData = (userData: any) => {
        const navigationData: any = [
            userData?.role_name !== 'General Manager' && {
                name: 'Users',
                to: '/app/users',
                svgPath: svgPaths['Users'],
            },
            { name: 'Employees', to: '/app/employees', svgPath: svgPaths['Employees'] },
            userData?.role_name !== 'General Manager' && {
                name: 'Corporations',
                to: '/app/corporations',
                svgPath: svgPaths['Corporations'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Locations',
                to: '/app/locations',
                svgPath: svgPaths['Locations'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Products',
                to: '/app/products',
                svgPath: svgPaths['Products'],
            },
            userData?.role_name !== 'General Manager' && { name: 'Menus', to: '/app/menus', svgPath: svgPaths['Menus'] },
            { name: 'Delivery', to: '/app/delivery', svgPath: svgPaths['Delivery'] },
            { name: 'Orders', to: '/app/orders', svgPath: svgPaths['Orders'] },
            userData?.role_name !== 'General Manager' && {
                name: 'Loyalty',
                to: '/app/loyalty',
                svgPath: svgPaths['Loyalty'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Coupon Codes',
                to: '/app/coupon-codes',
                svgPath: svgPaths['Coupon Codes'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Gift Cards',
                to: '/app/gift-cards',
                svgPath: svgPaths['Gift Cards'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Inquiries',
                to: '/app/inquiries',
                svgPath: svgPaths['Inquiries'],
            },
            { name: 'Reports', to: '/app/reports', svgPath: svgPaths['Reports'] },
            userData?.role_name !== 'General Manager' && {
                name: 'Permissions',
                to: '/app/permissions',
                svgPath: svgPaths['Permissions'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Web Editor',
                to: '/app/web-editor',
                svgPath: svgPaths['Web Editor'],
            },
            { name: 'POS Devices', to: '/app/pos-devices', svgPath: svgPaths['Web Editor'] },
            userData?.role_name !== 'General Manager' && {
                name: 'Scheduled Activities',
                to: '/app/scheduled-activities',
                svgPath: svgPaths['Scheduled Activities'],
            },
            userData?.role_name !== 'General Manager' && {
                name: 'Alert',
                to: '/app/alert',
                svgPath: svgPaths['Web Editor'],
            },
            checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
                name: 'Users',
                to: './users',
                svgPath: UsersIcon,
            },
        ].filter(Boolean) as SideNavigationItem[];
        setNavigation([...navigationData]);
    };

    React.useEffect(() => {
        const ls_user_details: any = localStorage.getItem('userDetails');
        getNavigationData(JSON.parse(ls_user_details));
    }, []);

    return (
        <div className="sidebar-col">
            <ul
                className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start"
                id="menu"
            >
                {navigation.map((item: any, index: number) => (
                    <li className="nav-item" key={item.name}>
                        <NavLink
                            end={index === 0}
                            to={item.to}
                            className={clsx('nav-link align-middle')}
                        >
                            <span className="border-menu">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={item.svgPath.width}
                                    height={item.svgPath.height}
                                    viewBox={item.svgPath.viewBox}
                                    fill="none"
                                >
                                    <path d={item.svgPath.path} fill="#A3BED9" />
                                </svg>
                            </span>
                            <span className="ms-1 d-none d-sm-inline">{item.name}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

type UserNavigationItem = {
    name: string;
    to: string;
    onClick?: () => void;
};

const UserNavigation = () => {
    const { logout, user } = useAuth();
    const [noOfUsers, setNoOfUsers] = React.useState<number | null>(null);

    const userNavigation = [
        { name: 'Your Profile', to: './profile' },
        {
            name: 'Sign out',
            to: '',
            onClick: () => {
                logout();
            },
        },
    ].filter(Boolean) as UserNavigationItem[];
    async function getNoOfUsers() {
        const users = await getUsers();
        setNoOfUsers(users.length);
    }
    getNoOfUsers();
    return (
        <Menu as="div" className="ml-3 relative">
            {({ open }) => (
                <>
                    <div className="header">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <strong>Users</strong>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {noOfUsers} Users
                                </li>
                            </ol>
                        </nav>
                        <div className="dropdown">
                            <a
                                href="#"
                                className="d-flex align-items-center text-decoration-none dropdown-toggle"
                                id="dropdownUser1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    src={avatar}
                                    alt="hugenerd"
                                    width="30"
                                    height="30"
                                    className="rounded-circle"
                                />
                                <span className="d-sm-inline mx-1">
                                    {user?.first_name} {user?.last_name}
                                </span>
                            </a>
                            <ul
                                className="dropdown-menu dropdown-menu-dark text-small shadow"
                                aria-labelledby="dropdownUser1"
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        New project...
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Settings
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Profile
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Sign out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*<Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>*/}
                </>
            )}
        </Menu>
    );
};

type MobileSidebarProps = {
    sidebarOpen: boolean;
    setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen }: MobileSidebarProps) => {
    return (
        <Transition.Root show={sidebarOpen} as={React.Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 flex z-40 md:hidden"
                open={sidebarOpen}
                onClose={setSidebarOpen}
            >
                <Transition.Child
                    as={React.Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={React.Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 ">
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 flex items-center px-4">
                            <Logo />
                        </div>
                        <div className="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav className="px-2 space-y-1">
                                <SideNavigation />
                            </nav>
                        </div>
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
            </Dialog>
        </Transition.Root>
    );
};
const Sidebar = () => {
    return (
        <div className="hidden md:flex md:flex-shrink-0 text-dark">
            <div className="flex flex-col w-64">
                <div className="flex flex-col h-0 flex-1 main-sidecol">
                    <div className="flex items-center  flex-shrink-0 logo-main text-dark">
                        <Logo />
                    </div>
                    <div className="flex-1 flex flex-col overflow-y-auto main-sidebar">
                        <nav className="flex-1 space-y-1">
                            <SideNavigation />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Logo = () => {
    const ls_user_details: any = localStorage.getItem('userDetails');
    const parsedUserDetails: any = JSON.parse(ls_user_details);
    return (
        <Link
            className="d-flex align-items-center mb-md-0 text-decoration-none logo-col"
            to={parsedUserDetails?.role_name === 'General Manager' ? '/app/employees' : '/'}
        >
            <span className="fs-5 d-flex align-items-center">
                <img className="logo" src={logo} alt="logo" />
                <p className="logo-title">Admin Panel</p>
            </span>
        </Link>
    );
};

type MainLayoutProps = {
    children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
    const [sidebarOpen, setSidebarOpen] = React.useState(false);

    return (
        <div className="h-screen flex overflow-hidden">
            <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <Sidebar />
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                {/*<div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>*/}
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    {children}
                </main>
            </div>
        </div>
    );
};
