/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Dialog, Spinner } from '@/components/Elements';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { AddCircleOutline } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import * as dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Modal from 'react-modal';
import { getDeliveryReport } from '../api/CRUD/getDeliveryReport';
import { getDiscountReport } from '../api/CRUD/getDiscountReport';
import { getProductReport } from '../api/CRUD/getProductReport';
import { getSalesReport } from '../api/CRUD/getSalesReport';
import { getTaxReport } from '../api/CRUD/getTaxReport';
import {
    HeadCellsClockRequestCard,
    HeadCellsDelivery,
    HeadCellsDiscount,
    HeadCellsProduct,
    HeadCellsSale,
    HeadCellsTax,
    HeadCellsTimeCard,
    HeadCellsTipCard,
    dataFieldsClockRequestCard,
    dataFieldsDelivery,
    dataFieldsDiscount,
    dataFieldsProduct,
    dataFieldsSale,
    dataFieldsTax,
    dataFieldsTimeCard,
    dataFieldsTipCard,
} from '../constant';
import { Report } from '../type';
//import { HeadCells } from '@/features/users/constant';

import { axios } from '@/lib/axios';
import { getBasicReport } from '../api/CRUD/getBasicReport';
import AddTarget from '../components/addTarget';
import SendTarget from '../components/sendTarget';
import '../styles/styles.css';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { duration } from 'moment';
import { CSVLink } from 'react-csv';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useNotificationStore } from '@/stores/notifications';
import { ContentLayout } from '@/components/Layout';

export const ReportPage = () => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    const { close, open, isOpen } = useDisclosure();
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [taxReport, setTaxReport] = useState<Report[] | any>([]);
    const [salesReport, setSalesReport] = useState<Report[]>([]);
    const [productReport, setProductReport] = useState<Report[]>([]);
    const [discountReport, setDiscountReport] = useState<Report[]>([]);
    const [deliveryReport, setDeliveryReport] = useState<Report[]>([]);
    const [basicReport, setBasicReport] = useState<Report[]>([]);
    const [taxTotal, setTaxTotal] = useState();
    const [salesTotal, setSalesTotal] = useState<any>();
    const [productsTotal, setProductsTotal] = useState();
    const [discountTotal, setDiscountTotal] = useState();
    const [deliveryTotal, setDeliveryTotal] = useState<any>();
    const [selectedOption, setSelectedOption] = useState(0);
    const [timeCardReport, setTimeCardReport] = useState<Report[]>([]);
    // const [timeCardReport, setTimeCardReport] = useState(null);
    const [viewReport, setviewReport] = useState<Report | null>(null);
    const [addTarget, setaddTarget] = useState<Report | null>(null);
    const [sendTarget, setSendTarget] = useState<Report | null>(null);
    const [locationFilter, setLocationFilter] = useState<any>();
    const [confirmMsg, setConfirmMsg] = useState<User | null>(null);
    const [value, setValue] = useState<any>(0);
    const [reportStartDate, setReportStartDate] = useState(Date.now());
    const [reportEndDate, setReportEndDate] = useState(null);
    const [isTimeCardModalOpen, setIsTimeCardModalOpen] = useState(false);
    const [isTipModalOpen, setIsTipModalOpen] = useState(false);
    const [tipReport, setTipReport] = useState<Report[]>([]);
    const [clockRequestReport, setClockRequestReport] = useState<Report>([]);
    const [isClockRequestModalOpen, setIsClockRequestModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [durationPromiseDate, setDurationPromiseDate] = useState({
        startDate: null,
        endDate: null,
    });
    const [csvData, setCSVData] = useState([]);
    const [finalCSVData, setFinalCSVData] = useState([]);
    const ls_user_details: any = localStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(ls_user_details);
    useEffect(() => {
        if (csvData?.length > 0) {
            setFinalCSVData([...csvData]);
        }
    }, [csvData]);
    // const getTaxTotal = async () => {
    //     axios.get(`v1/get-tax-report?duration=today`).then((res) => {
    //         setTaxTotal(res.data.total);
    //     });
    // };

    // const getSalesTotal = async () => {
    //     axios.get(`v1/get-sales-report?duration=today`).then((res) => {
    //         setSalesTotal(res.data.total);
    //     });
    // };

    // const getProductsTotal = async () => {
    //     axios.get(`v1/get-product-report?duration=today`).then((res) => {
    //         setProductsTotal(res.data.total);
    //     });
    // };

    // const getDiscountTotal = async () => {
    //     axios.get(`v1/get-discount-report?duration=today`).then((res) => {
    //         setDiscountTotal(res.data.total);
    //     });
    // };

    // const getDeliveryTotal = async () => {
    //     axios.get(`v1/get-delivery-report?duration=today`).then((res) => {
    //         setDeliveryTotal(res.data.total);
    //     });
    // };
    const handleTodaySubmit = () => {
        // getReport({ callByCustomDate: false });
        setDurationPromiseDate({ startDate: null, endDate: null });
        const basicReportUrl =
            parsedUserDetails?.role_name === 'General Manager'
                ? `?location_id=${parsedUserDetails?.location_id}&durationSales=today`
                : '?durationSales=today';
        axios.get(`v1/get-basic-report${basicReportUrl}`).then((res) => {
            setBasicReport(res.data);
        });
    };

    const handleWeekSubmit = () => {
        // getReport({ callByCustomDate: false });
        setDurationPromiseDate({ startDate: null, endDate: null });
        const basicReportUrl =
            parsedUserDetails?.role_name === 'General Manager'
                ? `?location_id=${parsedUserDetails?.location_id}&durationSales=week`
                : '?durationSales=week';
        axios.get(`v1/get-basic-report${basicReportUrl}`).then((res) => {
            setBasicReport(res.data);
        });
    };

    const handleMonthSubmit = () => {
        // getReport({ callByCustomDate: false });
        setDurationPromiseDate({ startDate: null, endDate: null });
        const basicReportUrl =
            parsedUserDetails?.role_name === 'General Manager'
                ? `?location_id=${parsedUserDetails?.location_id}&durationSales=month`
                : '?durationSales=month';
        axios.get(`v1/get-basic-report${basicReportUrl}`).then((res) => {
            setBasicReport(res.data);
        });
    };

    const handleCustomSubmit = async () => {
        if (durationPromiseDate?.startDate || durationPromiseDate?.endDate) {
            getReport({ callByCustomDate: true });
        } else alert('Please select start and end date.');
    };

    const handleGetTimeCardReport = async () => {
        try {
            setIsLoading(true);
            if (!!reportStartDate && !!reportEndDate) {
                if (selectedLocation) {
                    const selectedLocationData = taxReport?.find(
                        (el) => !el?.location_name.localeCompare(selectedLocation)
                    );
                    await axios
                        .get(
                            `v1/get-time-card-report?location_id=${selectedLocationData?.id}&startDate=${reportStartDate}&endDate=${reportEndDate}`
                        )
                        .then((res) => {
                            const data = res?.data;
                            const reportDatesArray = Object.keys(data?.timeCardReport);
                            const arr = reportDatesArray.flatMap((item) => {
                                return { ...data.timeCardReport[item] };
                            });

                            // const result = arr.map((obj: any) => {
                            //     // Get the first (and only) property key
                            //     const indexKey = Object.keys(obj)[0];
                            //     // Get the object associated with the index key
                            //     const value = obj[indexKey];
                            //     return value;
                            // });
                            const result = arr.map((obj) => Object.values(obj)[0]);
                            const timeCardCsvData: any = result?.map((ele: any) => {
                                return [
                                    ele?.auto_clock_out,
                                    ele?.clock_in_time,
                                    ele?.clock_out_time,
                                    ele?.date_clock,
                                    ele?.first_name,
                                    ele?.hours,
                                    ele?.last_name,
                                    ele?.location_id,
                                    ele?.location_name,
                                    ele?.user_email,
                                    ele?.user_id,
                                ];
                            });
                            setCSVData(timeCardCsvData);

                            setTimeCardReport([...result]);
                        });
                } else alert('Please select location.');
            } else alert('Please select start date and end date.');
        } catch (e) {
            console.log('error from get time card report-->', e);
        } finally {
            setIsTimeCardModalOpen(false);
            setIsLoading(false);
        }
    };

    const handleGetTipReport = async () => {
        try {
            setIsLoading(true);
            if (!!reportStartDate && !!reportEndDate) {
                if (selectedLocation) {
                    const selectedLocationData = taxReport?.find(
                        (el: any) => !el?.location_name.localeCompare(selectedLocation)
                    );
                    await axios
                        .get(
                            `v1/get-tip-report?location_id=${selectedLocationData?.id}&startDate=${reportStartDate}&endDate=${reportEndDate}`
                        )
                        .then((res) => {
                            const resTipMod: any = res.data.map((item: any) => {
                                const report: any = {};
                                report.id = item.id;
                                report.first_name = item.first_name;
                                report.last_name = item.last_name;
                                report.user_email = item.user_email;
                                report.user_phone = item.user_phone;
                                report.totalOrders = item.orders?.length;
                                report.total_tip = `$ ${getDollarPrice(item.total_tip)}`;
                                report.total_fot_graph = getDollarPrice(item.total_tip);

                                return report;
                            });
                            const tipCsvData: any = res?.data?.map((ele: any) => {
                                return [
                                    ele?.first_name,
                                    ele?.last_name,
                                    ele?.total_tip,
                                    ele?.user_email,
                                    ele?.user_id,
                                    ele?.user_phone,
                                ];
                            });
                            setCSVData(tipCsvData);
                            setTipReport(resTipMod);
                        });
                } else alert('Please select location.');
            } else alert('Please select start date and end date.');
        } catch (e) {
            console.log('error from get tip report-->', e);
        } finally {
            setIsTipModalOpen(false);
            setIsLoading(false);
        }
    };

    const handleClockRequestReport = async () => {
        try {
            setIsLoading(true);
            if (!!reportStartDate) {
                if (selectedLocation) {
                    const selectedLocationData = taxReport?.find(
                        (el) => !el?.location_name.localeCompare(selectedLocation)
                    );
                    await axios
                        .get(
                            `v1/get-clock-request-report?location_id=${
                                selectedLocationData?.id
                            }&date=${dayjs().format('YYYY-MM-DD')}`
                        )
                        .then((res) => {
                            // setClockRequestReport(res?.data);
                            setClockRequestReport(
                                res?.data?.timeCardReport[dayjs().format('YYYY-MM-DD')]
                                    ? res?.data?.timeCardReport[dayjs().format('YYYY-MM-DD')]
                                    : []
                            );
                            const clockRequestCsvData: any = res?.data?.timeCardReport[
                                dayjs().format('YYYY-MM-DD')
                            ]?.map((ele: any) => {
                                return [
                                    ele?.user_id,
                                    ele?.date_clock,
                                    ele?.clock_in_time,
                                    ele?.clock_out_time,
                                    ele?.auto_clock_out,
                                    ele?.first_name,
                                    ele?.last_name,
                                    ele?.user_phone,
                                    ele?.user_email,
                                    ele?.location_id,
                                    ele?.location_name,
                                ];
                            });
                            setCSVData(clockRequestCsvData);
                        });
                } else alert('Please select location.');
            } else alert('Please select date.');
        } catch (e) {
            console.log('error from get tip report-->', e);
        } finally {
            setIsClockRequestModalOpen(false);
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    // getTaxTotal();
    // getSalesTotal();
    // getProductsTotal();
    // getDiscountTotal();
    // getDeliveryTotal();
    // }, []);
    const demoDataForGraph = {
        series: [
            {
                name:
                    value == 0
                        ? 'Total Sales'
                        : value == 1
                        ? 'Total Tax'
                        : value == 2
                        ? 'Total Products'
                        : value == 3
                        ? 'Total Discount'
                        : value == 4
                        ? 'Total Delivery'
                        : value == 5
                        ? 'Total Hours'
                        : value == 6
                        ? 'Total Tip'
                        : value == 7
                        ? 'Total Hours'
                        : 'Total Hours',
                data:
                    value == 0
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          salesReport?.length > 0
                            ? [
                                  salesReport
                                      ?.find((el: any) => el?.id === parsedUserDetails?.location_id)
                                      ?.totalSales?.split(' ')[1],
                              ]
                            : salesReport?.map((item) => Number(item?.totalSales?.split(' ')[1]))
                        : value == 1
                        ? parsedUserDetails?.role_name === 'General Manager'
                            ? [
                                  taxReport
                                      ?.find((el: any) => el?.id === parsedUserDetails?.location_id)
                                      ?.totalTax?.split(' ')[1],
                              ]
                            : taxReport?.map((item) => Number(item?.totalTax?.split(' ')[1]))
                        : value == 2
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          productReport?.length > 0
                            ? [
                                  productReport
                                      ?.find((el: any) => el?.id === parsedUserDetails?.location_id)
                                      ?.price?.split(' ')[1],
                              ]
                            : productReport?.map((item) => Number(item?.price?.split(' ')[1]))
                        : value == 3
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          discountReport?.length > 0
                            ? [
                                  discountReport
                                      ?.find((el: any) => el?.id === parsedUserDetails?.location_id)
                                      ?.totalDiscount?.split(' ')[1],
                              ]
                            : discountReport?.map((item) =>
                                  Number(item?.totalDiscount?.split(' ')[1])
                              )
                        : value == 4
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          deliveryReport?.length > 0
                            ? [
                                  deliveryReport
                                      ?.find((el: any) => el?.id === parsedUserDetails?.location_id)
                                      ?.totalDeliveryAmount?.split(' ')[1],
                              ]
                            : deliveryReport?.map((item) =>
                                  Number(item?.totalDeliveryAmount?.split(' ')[1])
                              )
                        : value == 5
                        ? timeCardReport?.map((item) => item?.hours)
                        : value == 6
                        ? tipReport?.map((item) => Number(item?.total_fot_graph))
                        : value == 7
                        ? clockRequestReport?.map((item: any) => {
                              if (item?.clock_out_time && item?.clock_in_time) {
                                  dayjs(item?.clock_out_time).diff(item?.clock_in_time, 'hours');
                              } else return 0;
                          })
                        : timeCardReport?.map((item) => item?.hours),
            },
        ],
        options: {
            chart: {
                height: 100,
                type: 'line',
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: [5, 7, 5],
                curve: 'straight',
                dashArray: [0, 8, 5],
            },
            title: {
                text:
                    value == 0
                        ? 'Sales Report'
                        : value == 1
                        ? 'Tax Report'
                        : value == 2
                        ? 'Products Report'
                        : value == 3
                        ? 'Discount Report'
                        : value == 4
                        ? 'Delivery Report'
                        : value == 5
                        ? 'Time Report'
                        : value == 6
                        ? 'Tip Report'
                        : value == 7
                        ? 'Clock Request Report'
                        : 'Time Report',
                align: 'left',
            },
            legend: {
                tooltipHoverFormatter: function (val: any, opts: any) {
                    return (
                        val +
                        ' - ' +
                        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                        ''
                    );
                },
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6,
                },
            },
            xaxis: {
                categories:
                    value == 0
                        ? parsedUserDetails?.role_name === 'General Manager'
                            ? [
                                  salesReport?.find(
                                      (el: any) => el?.id === parsedUserDetails?.location_id
                                  )?.location_name,
                              ]
                            : salesReport?.map((item) => item?.location_name)
                        : value == 1
                        ? parsedUserDetails?.role_name === 'General Manager'
                            ? [
                                  taxReport?.find(
                                      (el: any) => el?.id === parsedUserDetails?.location_id
                                  )?.location_name,
                              ]
                            : taxReport?.map((item) => item?.location_name)
                        : value == 2
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          productReport?.length > 0
                            ? [
                                  productReport?.find(
                                      (el: any) => el?.id === parsedUserDetails?.location_id
                                  )?.product_name,
                              ]
                            : productReport?.map((item) => item?.product_name)
                        : value == 3
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          discountReport?.length > 0
                            ? [
                                  discountReport?.find(
                                      (el: any) => el?.id === parsedUserDetails?.location_id
                                  )?.location_name,
                              ]
                            : discountReport?.map((item) => item?.location_name)
                        : value == 4
                        ? parsedUserDetails?.role_name === 'General Manager' &&
                          deliveryReport?.length > 0
                            ? [
                                  deliveryReport?.find(
                                      (el: any) => el?.id === parsedUserDetails?.location_id
                                  )?.location_name,
                              ]
                            : deliveryReport?.map((item) => item?.location_name)
                        : value == 5
                        ? timeCardReport?.map((item) => item?.first_name + ' ' + item?.last_name)
                        : value == 6
                        ? tipReport?.map((item) => item?.first_name + ' ' + item?.last_name)
                        : value == 7
                        ? clockRequestReport?.map(
                              (item: any) => item?.first_name + ' ' + item?.last_name
                          )
                        : timeCardReport?.map((item) => item?.first_name + ' ' + item?.last_name),
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val: any) {
                                return val + ` (in ${value !== 5 ? '$' : 'hours'})`;
                            },
                        },
                    },
                    {
                        title: {
                            formatter: function (val: any) {
                                return val + ' per session';
                            },
                        },
                    },
                    {
                        title: {
                            formatter: function (val: any) {
                                return val;
                            },
                        },
                    },
                ],
            },
            grid: {
                borderColor: '#f1f1f1',
            },
        },
    };

    // function for getting the dollar price
    const getDollarPrice = (priceInCents: any) => {
        if (priceInCents == 0) {
            return priceInCents;
        } else if (priceInCents == '-') {
            return '0.00';
        } else {
            return (priceInCents / 100).toFixed(2);
        }
    };
    //////////

    const handleChange = (newValue: any) => {
        const a = parseInt(newValue.target.id);
        setValue(a);
    };

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };
    const getDay = (day: number) => {
        if (day === 0) {
            return 'today';
        } else if (day === 1) {
            return 'week';
        } else if (day === 2) {
            return 'month';
        } else {
            return 'today';
        }

        // return day === 0 ? 'today' : day === 1 ? 'week' : day === 2 ? 'month' : 'today';
    };

    const findReportParams = (callByCustomDate: boolean) => {
        if ([0, 1, 2]?.includes(selectedOption)) {
            return {
                duration: getDay(selectedOption),
            };
        } else if (callByCustomDate) {
            return durationPromiseDate;
        } else return { duration: getDay(0) };
        // return [0, 1, 2]?.includes(selectedOption)
        //     ? { duration: getDay(selectedOption) }
        //     : callByCustomDate
        //     ? durationPromiseDate
        //     : { duration: getDay(0) };
    };

    async function getReport({ callByCustomDate = false }) {
        if (selectedOption === 3 && !callByCustomDate) return;
        setFinalCSVData([]);
        const selectedDuration = findReportParams(callByCustomDate);
        setIsLoading(true);
        let resTaxMod = [];
        let resSalesMod = [];
        let resProductMod = [];
        let resDiscountMod = [];
        let resDeliveryMod = [];

        if (callByCustomDate || locationFilter)
            await axios
                .get(
                    `v1/get-basic-report?${
                        'startDate=' +
                            durationPromiseDate?.startDate +
                            '&endDate=' +
                            durationPromiseDate?.endDate +
                            parsedUserDetails?.role_name ===
                        'General Manager'
                            ? 'location_id=' + parsedUserDetails?.location_id
                            : locationFilter
                            ? 'location_id=' +
                              taxReport?.filter(
                                  (el: any) => !el?.location_name?.localeCompare(locationFilter)
                              )?.id
                            : ''
                    }`
                )
                .then((res) => {
                    setBasicReport(res.data);
                });

        selectedOption === 0 ? duration : 'today';
        const basicReportUrlForCallByCustomDate =
            parsedUserDetails?.role_name === 'General Manager'
                ? `?location_id=${parsedUserDetails?.location_id}&startDate=${durationPromiseDate?.startDate}&endDate=${durationPromiseDate?.endDate}&durationSales=custom`
                : `?startDate=${durationPromiseDate?.startDate}&endDate=${durationPromiseDate?.endDate}&durationSales=custom`;

        const basicReportUrlForCallByDay =
            parsedUserDetails?.role_name === 'General Manager'
                ? `?location_id=${parsedUserDetails?.location_id}&durationSales=${getDay(
                      selectedOption
                  )}`
                : `?durationSales=${getDay(selectedOption)}`;
        callByCustomDate
            ? await axios
                  .get(`v1/get-basic-report${basicReportUrlForCallByCustomDate}`)
                  .then((res) => {
                      setBasicReport(res.data);
                  })
            : axios.get(`v1/get-basic-report${basicReportUrlForCallByDay}`).then((res) => {
                  setBasicReport(res.data);
              });
        const resTax: any = await getTaxReport(selectedDuration);
        setTaxTotal(resTax.total);
        resTaxMod = resTax?.taxReport.map((item: any) => {
            const report: any = {};
            report.id = item.id;
            report.location_name = item.location_name;
            report.totalOrders = item.totalOrders;
            report.totalTax = `$ ${getDollarPrice(item.totalTax)}`;

            return report;
        });

        if (value === 0) {
            let resSales: any;
            if (parsedUserDetails?.role_name === 'General Manager') {
                const data = {
                    location_id: parsedUserDetails?.location_id,
                    ...selectedDuration,
                };
                resSales = await getSalesReport(data);
            } else {
                resSales = await getSalesReport(selectedDuration);
            }
            // const resSales: any = await getSalesReport(selectedDuration);
            setSalesTotal(resSales?.total);
            resSalesMod = resSales?.salesReport?.map((item: any) => {
                const report: any = {};
                report.id = item.id;
                report.location_name = item.location_name;
                report.totalSubTotal = `$ ${getDollarPrice(item.totalSubTotal)}`;
                report.totalTax = `$ ${getDollarPrice(item.totalTax)}`;
                report.totalTip = `$ ${getDollarPrice(item.totalTip)}`;
                report.totalDeliveryFee = `$ ${getDollarPrice(item.totalDeliveryFee)}`;
                report.totalGiftCard = `$ ${getDollarPrice(item.totalGiftCard)}`;
                report.totalCouponCode = `$ ${getDollarPrice(item.totalCouponCode)}`;
                report.totalSales = `$ ${getDollarPrice(item.totalSales)}`;

                return report;
            });

            let tempRes = [];
            if ((searchVal || locationFilter) && parsedUserDetails?.role_name !== 'General Manager')
                tempRes = filteredSalesReport;
            else tempRes = resSales?.salesReport;

            let salesCsvData: any = [];
            if (parsedUserDetails?.role_name === 'General Manager') {
                salesCsvData = resSales?.salesReport[0]?.orderDetails?.map((el: any) => {
                    return [
                        el?.first_name,
                        el?.last_name,
                        el?.user_email,
                        el?.user_phone,
                        el?.id,
                        el?.order_creation_date,
                        (el?.order_subtotal / 100).toFixed(2),
                        (el?.order_tax / 100).toFixed(2),
                        (el?.order_tip / 100).toFixed(2),
                        (el?.order_delivery_fee / 100).toFixed(2),
                        (el?.order_gift_card_amount / 100).toFixed(2),
                        (el?.order_coupon_code_discount / 100).toFixed(2),
                        (el?.order_total / 100).toFixed(2),
                    ];
                });
            } else {
                salesCsvData = tempRes?.map((ele: any) => {
                    return [
                        ele?.location_name,
                        ele?.totalCouponCode,
                        ele?.totalDeliveryFee,
                        ele?.totalGiftCard,
                        ele?.totalSales,
                        ele?.totalSubTotal,
                        ele?.totalTax,
                        ele?.totalTip,
                    ];
                });
            }

            setCSVData(salesCsvData);
        }
        if (value === 1) {
            let tempRes = [];
            if ((searchVal || locationFilter) && parsedUserDetails?.role_name !== 'General Manager')
                tempRes = filteredTaxReport;
            else tempRes = resTax?.taxReport;
            const currentLocation = tempRes?.find(
                (el: any) => el?.id === parsedUserDetails?.location_id
            );
            const taxCsvData = tempRes
                ?.map((ele: any) => {
                    return [ele?.location_name, ele?.totalOrders, ele?.totalTax];
                })
                .filter((el: any) =>
                    parsedUserDetails?.role_name === 'General Manager'
                        ? el?.[0] === currentLocation?.location_name
                        : el
                );

            setCSVData(taxCsvData);
        }
        if (value === 2) {
            let resProduct: any;
            if (parsedUserDetails?.role_name === 'General Manager') {
                const data = {
                    location_id: parsedUserDetails?.location_id,
                    ...selectedDuration,
                };
                resProduct = await getProductReport(data);
            } else {
                resProduct = await getProductReport(selectedDuration);
            }
            // const resProduct: any = await getProductReport(selectedDuration),

            setProductsTotal(resProduct?.total);
            resProductMod = resProduct?.productReports?.map((item: any) => {
                const report: any = {};
                report.id = item.id;
                report.product_name = item.product_name;
                report.price = `$ ${getDollarPrice(item.price)}`;
                report.qyt = item.qyt;

                return report;
            });
            let tempRes = [];
            if ((searchVal || locationFilter) && parsedUserDetails?.role_name !== 'General Manager')
                tempRes = filteredProductReport;
            else tempRes = resProduct?.productReports;
            const productCsvData = tempRes?.map((ele: any) => {
                return [ele?.price, ele?.product_name, ele?.qyt];
            });

            setCSVData(productCsvData);
        }
        if (value === 3) {
            const resDiscount: any = await getDiscountReport(selectedDuration);
            setDiscountTotal(resDiscount?.total);
            resDiscountMod = resDiscount?.discountReports?.map((item: any) => {
                const report: any = {};
                report.id = item.id;
                report.location_name = item.location_name;
                report.totalDiscount = `$ ${getDollarPrice(item.totalDiscount)}`;
                report.totalUsed = item.totalUsed;

                return report;
            });
            let tempRes = [];
            if ((searchVal || locationFilter) && parsedUserDetails?.role_name !== 'General Manager')
                tempRes = filteredDiscountReport;
            else tempRes = resDiscount?.discountReports;
            const currentLocation = tempRes?.find(
                (el: any) => el?.id === parsedUserDetails?.location_id
            );
            const discountCsvData = tempRes
                ?.map((ele: any) => {
                    return [ele?.location_name, ele?.totalDiscount, ele?.totalUsed];
                })
                .filter((el: any) =>
                    parsedUserDetails?.role_name === 'General Manager'
                        ? el?.[0] === currentLocation?.location_name
                        : el
                );
            setCSVData(discountCsvData);
        }
        if (value === 4) {
            const resDelivery: any = await getDeliveryReport(selectedDuration);
            setDeliveryTotal(resDelivery?.total);
            resDeliveryMod = resDelivery?.deliveryReports?.map((item: any) => {
                const report: any = {};
                report.id = item.id;
                report.location_name = item.location_name;
                report.totalDeliveryAmount = `$ ${getDollarPrice(item.totalDeliveryAmount)}`;
                report.deliveries = item.deliveries;
                report.fuelCharge = item.fuelCharge;

                return report;
            });

            let tempRes = [];
            if ((searchVal || locationFilter) && parsedUserDetails?.role_name !== 'General Manager')
                tempRes = filteredDeliveryReport;
            else tempRes = resDelivery?.deliveryReports;
            const currentLocation = tempRes?.find(
                (el: any) => el?.id === parsedUserDetails?.location_id
            );
            const deliveryCsvData = tempRes
                ?.map((ele: any) => {
                    return [
                        ele?.deliveries,
                        ele?.fuelCharge,
                        ele?.location_name,
                        ele?.totalDeliveryAmount,
                    ];
                })
                .filter((el: any) =>
                    parsedUserDetails?.role_name === 'General Manager'
                        ? el?.[2] === currentLocation?.location_name
                        : el
                );
            setCSVData(deliveryCsvData);
        }

        // setBasicReport(resBasic);
        setTaxReport(resTaxMod);
        setSalesReport(resSalesMod);
        setProductReport(resProductMod);
        setDiscountReport(resDiscountMod);
        setDeliveryReport(resDeliveryMod);
        setIsLoading(false);
    }

    useEffect(() => {
        getReport({ callByCustomDate: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close, locationFilter, selectedOption, value]);

    useEffect(() => {
        if (parsedUserDetails?.role_name === 'General Manager' && !isLoading) {
            const adminLocation = taxReport?.find(
                (el: any) => el?.id === parsedUserDetails?.location_id
            );
            if (adminLocation?.location_name) {
                setLocationFilter(adminLocation?.location_name);
                setSelectedLocation(adminLocation?.location_name);
            }
        }
    }, [parsedUserDetails?.role_name, taxReport, isLoading]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    const filteredTaxReport = taxReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location_name === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const filteredSalesReport = salesReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location_name === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const filteredProductReport = productReport?.filter((report: Report) => {
        if (searchVal || (locationFilter && parsedUserDetails?.role_name !== 'General Manager')) {
            if (searchVal) {
                return report?.product_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const filteredDiscountReport = discountReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location_name === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const filteredDeliveryReport = deliveryReport?.filter((report: Report) => {
        if (searchVal || locationFilter) {
            if (report?.location_name === locationFilter) {
                return report;
            }
            if (searchVal) {
                return report?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return report;
        }
    });

    const handleDelete = async (reportData: Report) => {};

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'add-target':
                setaddTarget(data);
                setSendTarget(null);
                open();
                break;
            case 'send-target':
                setaddTarget(null);
                setSendTarget(data);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewReport(null);
        setaddTarget(null);
        setSendTarget(null);
        close();
    };

    const LocationOptions: any = new Set(taxReport?.map((item) => item.location_name));

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: any) {
        return {
            id: index,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <ContentLayout
                title=""
                topNavBarData={{
                    num:
                        value == 0
                            ? salesReport.length
                            : value == 1
                            ? taxReport.length
                            : value == 2
                            ? productReport.length
                            : value == 3
                            ? discountReport.length
                            : deliveryReport.length,
                    title: 'Reports',
                }}
            >
                {confirmMsg ? (
                    <Confirm
                        btnTitle={'Delete'}
                        onClose={() => setConfirmMsg(null)}
                        onSuccess={() => handleDelete(confirmMsg)}
                    />
                ) : null}
                <div className="table-header">
                    <form>
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="flex">
                                    <div className="input-group search">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={searchVal}
                                            onChange={(e) => handleSearch(e)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                    {parsedUserDetails?.role_name !== 'General Manager' ? (
                                        <div className="form-group pos-dev">
                                            <select
                                                className="form-control ml-1 w-auto pos-select"
                                                name="location"
                                                value={locationFilter}
                                                onChange={(e: any) => {
                                                    setLocationFilter(e.target.value);
                                                }}
                                            >
                                                <option value="">Select Location</option>
                                                {[...LocationOptions]?.map((location_name) => (
                                                    <option
                                                        key={location_name}
                                                        value={location_name}
                                                    >
                                                        {location_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="add-btn" style={{ float: 'right' }}>
                                    <Button
                                        startIcon={<AddCircleOutline />}
                                        onClick={() => {
                                            selectedUserAction({
                                                action: 'add-target',
                                                data: 'add-target',
                                            });
                                        }}
                                    >
                                        Targets
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {/* --------- Charts Section ----------- */}
                <div className="row promise-time mb-4">
                    <div className="col-12 col-md-8">
                        <div className="border-solid border p-3 rounded">
                            <div className="flex gap-3">
                                <div className="flex-1">
                                    <div className="pb-2 flex justify-between items-center">
                                        <h4 className="mb-0">
                                            <strong>Total Sales</strong>
                                            {/* <strong>Promise Time Efficiency</strong> */}
                                        </h4>
                                        <div className="text-sm">
                                            {basicReport?.totalSales?.totalOrders || 0} sales /{' '}
                                            <span
                                                style={{ color: '#FFAF60' }}
                                                className="text-xl font-bold"
                                            >
                                                $
                                                {getDollarPrice(
                                                    basicReport?.totalSales?.collection
                                                ) || 0}
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1 pt-3">
                                            <div
                                                className={
                                                    selectedOption == 0
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    setSelectedOption(0);
                                                    handleTodaySubmit();
                                                }}
                                            >
                                                Today
                                            </div>
                                            <div
                                                className={
                                                    selectedOption == 1
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    setSelectedOption(1);
                                                    handleWeekSubmit();
                                                }}
                                            >
                                                This Week
                                            </div>
                                            <div
                                                className={
                                                    selectedOption == 2
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    setSelectedOption(2);
                                                    handleMonthSubmit();
                                                }}
                                            >
                                                This Month
                                            </div>
                                            <div
                                                className={
                                                    selectedOption == 3
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={() => {
                                                    setSelectedOption(3);
                                                }}
                                            >
                                                Custom
                                            </div>
                                        </div>
                                        {/* <div className="pt-3 text-sm date-format">
                                        <span>11/30/2023</span>
                                    </div> */}
                                    </div>
                                    {selectedOption === 3 ? (
                                        <div
                                            className="w-full flex items-end justify-start"
                                            style={{ marginTop: '10px' }}
                                        >
                                            <div
                                                style={{ display: 'flex', flexDirection: 'column' }}
                                            >
                                                <div style={{ width: '100%' }}>
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DemoContainer
                                                            components={['DatePicker']}
                                                            sx={{ width: '100%' }}
                                                        >
                                                            <DatePicker
                                                                defaultValue={
                                                                    durationPromiseDate
                                                                        ? dayjs(
                                                                              durationPromiseDate.startDate
                                                                          )
                                                                        : null
                                                                }
                                                                format="DD-MM-YYYY"
                                                                label="Start date"
                                                                onChange={(e) =>
                                                                    setDurationPromiseDate(
                                                                        (prev) => ({
                                                                            ...prev,
                                                                            startDate:
                                                                                dayjs(e).format(
                                                                                    'YYYY-MM-DD'
                                                                                ),
                                                                        })
                                                                    )
                                                                }
                                                                sx={{
                                                                    width: '100%',
                                                                    marginBottom: '0px !important',
                                                                }}
                                                                slotProps={{
                                                                    textField: { size: 'small' },
                                                                }}
                                                                maxDate={dayjs(Date.now())}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                                <div
                                                    style={{
                                                        // marginLeft: '10px',
                                                        marginRight: '10px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDayjs}
                                                    >
                                                        <DemoContainer
                                                            components={['DatePicker']}
                                                            sx={{ width: '100%' }}
                                                        >
                                                            <DatePicker
                                                                defaultValue={
                                                                    durationPromiseDate
                                                                        ? dayjs(
                                                                              durationPromiseDate.endDate
                                                                          )
                                                                        : null
                                                                }
                                                                format="DD-MM-YYYY"
                                                                label="End date"
                                                                onChange={(e) =>
                                                                    setDurationPromiseDate(
                                                                        (prev) => ({
                                                                            ...prev,
                                                                            endDate:
                                                                                dayjs(e).format(
                                                                                    'YYYY-MM-DD'
                                                                                ),
                                                                        })
                                                                    )
                                                                }
                                                                sx={{ width: '100%' }}
                                                                slotProps={{
                                                                    textField: { size: 'small' },
                                                                }}
                                                                maxDate={dayjs(Date.now())}
                                                                // minDate={dayjs(reportStartDate).add(
                                                                //     1,
                                                                //     'day'
                                                                // )}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                            <Button
                                                onClick={() => handleCustomSubmit()}
                                                style={{
                                                    height: '50px',
                                                    width: '100%',
                                                    maxWidth: '100px',
                                                    fontSize: '12px',
                                                    marginTop: '8px',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="">
                                    <div style={{ width: 130, height: 100, color: '#fff' }}>
                                        <CircularProgressbar
                                            value={0}
                                            text={'0%'}
                                            circleRatio={0.5}
                                            styles={buildStyles({
                                                rotation: 0.75,
                                                strokeLinecap: 'round',
                                                textSize: '16px',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#5ACD66',
                                                textColor: '#5ACD66',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#5ACD66',
                                            })}
                                        />
                                        ;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 
                    <div className="col-12 col-md-6">
                        <div className="border-solid border p-3 rounded">
                            <div className="flex gap-3">
                                <div className="flex-1">
                                    <div className="pb-2 flex justify-between items-center">
                                        <h4 className="mb-0">
                                            <strong>Total Sales</strong>
                                        </h4>
                                        <div className="text-sm">
                                            {basicReport?.totalSales?.totalOrders || 0} sales /{' '}
                                            <span
                                                style={{ color: '#FFAF60' }}
                                                className="text-xl font-bold"
                                            >
                                                $
                                                {getDollarPrice(
                                                    basicReport?.totalSales?.collection
                                                ) || 0}
                                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex justify-between items-center">
                                        <div className="flex gap-1 pt-3">
                                            <div
                                                className={
                                                    selectedOption == 0
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    handleTodaySubmit();
                                                    setSelectedOption(0);
                                                }}
                                            >
                                                Today
                                            </div>
                                            <div
                                                className={
                                                    selectedOption == 1
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    handleWeekSubmit();
                                                    setSelectedOption(1);
                                                }}
                                            >
                                                This Week
                                            </div>
                                            <div
                                                className={
                                                    selectedOption == 2
                                                        ? 'chart-button-filled'
                                                        : 'chart-button'
                                                }
                                                onClick={(e) => {
                                                    handleCustomSubmit();
                                                    setSelectedOption(2);
                                                }}
                                            >
                                                This Month
                                            </div>
                                        </div>
                                        {/* <div className="pt-3 text-sm date-format">
                                        <span>01/25/2023</span>
                                    </div> 
                                    </div>
                                </div>
                                <div className="">
                                    <div style={{ width: 130, height: 100, color: '#fff' }}>
                                        <CircularProgressbar
                                            value={basicReport?.promiseTimeEfficiency?.ratio || 0}
                                            text={`${
                                                basicReport?.promiseTimeEfficiency?.ratio || 0
                                            }%`}
                                            circleRatio={0.5}
                                            styles={buildStyles({
                                                rotation: 0.75,
                                                strokeLinecap: 'round',
                                                textSize: '18px',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#FFAF60',
                                                textColor: '#FFAF60',
                                                trailColor: '#d6d6d6',
                                                backgroundColor: '#FFAF60',
                                            })}
                                        />
                                        ;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/* --------- Charts Section ----------- */}
                <Box sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            borderBottom: 0,
                            padding: '0',
                            justifyContent: 'center',
                            paddingLeft: '10px',
                            marginBottom: '50px',
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            className="prTabs report-dash-tabs"
                            style={{ paddingTop: '10px' }}
                        >
                            <Tab
                                label="Sales Report"
                                className="left-report"
                                value={0}
                                {...a11yProps(0)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                            />
                            <Tab
                                label="Tax Report"
                                className="right-report"
                                value={1}
                                {...a11yProps(1)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '6px 0px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                            />
                            <Tab
                                label="Product Report"
                                className="right-report"
                                value={2}
                                {...a11yProps(2)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                            />
                            <Tab
                                label="Discounts & Rebates"
                                className="right-report"
                                value={3}
                                {...a11yProps(3)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                            />

                            <Tab
                                label="Delivery Report"
                                className="right-report"
                                value={4}
                                {...a11yProps(4)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                            />
                            <Tab
                                label="Time Card Report"
                                className="right-report"
                                value={5}
                                {...a11yProps(5)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                                onClick={() => setIsTimeCardModalOpen(true)}
                            />
                            <Tab
                                label="Tip Report"
                                className="right-report"
                                value={6}
                                {...a11yProps(6)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                                onClick={() => setIsTipModalOpen(true)}
                            />
                            <Tab
                                label="Clock Request Report"
                                className="right-report"
                                value={7}
                                {...a11yProps(7)}
                                style={{
                                    border: '1px solid  #c8d4df',
                                    borderRadius: '0px 6px 0 0',
                                    width: '12.5%',
                                    maxWidth: '12.5%',
                                }}
                                onClick={() => setIsClockRequestModalOpen(true)}
                            />
                        </Tabs>
                    </Box>
                    {value !== 7 ? (
                        <ReactApexChart
                            options={demoDataForGraph.options}
                            series={demoDataForGraph.series}
                            type="line"
                            height={550}
                        />
                    ) : null}

                    <TabPanel className="report-tab-panel" value={value} index={0}>
                        <CustomTable
                            tableData={filteredSalesReport}
                            headCells={HeadCellsSale}
                            dataFields={dataFieldsSale}
                            selectedUserAction={selectedUserAction}
                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />
                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-5 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        <strong>Total</strong>
                                    </div>
                                    <div
                                        style={{ width: '12%' }}
                                        className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        <strong></strong>
                                    </div>
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.subTotal / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.totalTax / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.totalTip / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '12%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.deliveryFee / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.giftCard / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '14%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.couponCode / 100).toFixed(2) || 0.0}
                                    </div>
                                    <div
                                        style={{ width: '10%' }}
                                        className="col-md-3 px-1 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        ${(salesTotal?.totalSales / 100).toFixed(2) || 0.0}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className="report-tab-panel mt-[40px]" value={value} index={1}>
                        <CustomTable
                            tableData={filteredTaxReport}
                            headCells={HeadCellsTax}
                            dataFields={dataFieldsTax}
                            selectedUserAction={selectedUserAction}
                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />

                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-5 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {taxTotal?.totalOrders || 0}
                                    </div>
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>${taxTotal?.totalTax / 100 || 0}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>

                    {finalCSVData?.length > 0 ? (
                        <div className="col-3 pr-[7px] pt-3 w-full text-xs font-medium tracking-wider text-center flex text-white items-center justify-end  uppercase">
                            <Button>
                                <CSVLink
                                    className=" text-center font-[400] mb-4 cursor-pointer"
                                    data={finalCSVData}
                                    separator={';'}
                                    filename="Hot_Box_Generated_Reports.csv"
                                >
                                    Download Report
                                </CSVLink>
                            </Button>
                        </div>
                    ) : null}

                    <TabPanel className="report-tab-panel" value={value} index={2}>
                        <CustomTable
                            tableData={filteredProductReport}
                            headCells={HeadCellsProduct}
                            dataFields={dataFieldsProduct}
                            selectedUserAction={selectedUserAction}
                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />
                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-6 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {productsTotal?.totalQyt || 0}
                                    </div>
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>${productsTotal?.totalSales / 100 || 0}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="report-tab-panel" value={value} index={3}>
                        <CustomTable
                            tableData={filteredDiscountReport}
                            headCells={HeadCellsDiscount}
                            dataFields={dataFieldsDiscount}
                            selectedUserAction={selectedUserAction}
                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />
                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>
                                    <div
                                        style={{ paddingLeft: '140px' }}
                                        className="col-md-5 px-10 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        {discountTotal?.totalUsed || 0}
                                    </div>
                                    <div
                                        style={{ paddingLeft: '90px' }}
                                        className="col-md-1 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        <strong>${discountTotal?.totalDiscount / 100 || 0}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="report-tab-panel" value={value} index={4}>
                        <CustomTable
                            tableData={filteredDeliveryReport}
                            headCells={HeadCellsDelivery}
                            dataFields={dataFieldsDelivery}
                            selectedUserAction={selectedUserAction}
                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />
                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {deliveryTotal?.deliveries || 0}
                                    </div>
                                    <div
                                        style={{ paddingLeft: '48px' }}
                                        className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        {deliveryTotal?.fuelAmount || 0}
                                    </div>
                                    <div
                                        style={{ paddingLeft: '72px' }}
                                        className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        <strong>
                                            ${deliveryTotal?.deliveriesAmount / 100 || 0}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="report-tab-panel" value={value} index={5}>
                        <CustomTable
                            tableData={timeCardReport}
                            headCells={HeadCellsTimeCard}
                            dataFields={dataFieldsTimeCard}
                            selectedUserAction={selectedUserAction}

                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />

                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {deliveryTotal?.deliveries || 0}
                                    </div>
                                    <div
                                        style={{ paddingLeft: '48px' }}
                                        className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        {deliveryTotal?.fuelAmount || 0}
                                    </div>
                                    <div
                                        style={{ paddingLeft: '72px' }}
                                        className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                    >
                                        <strong>
                                            ${deliveryTotal?.deliveriesAmount / 100 || 0}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="report-tab-panel" value={value} index={6}>
                        <CustomTable
                            tableData={tipReport}
                            headCells={HeadCellsTipCard}
                            dataFields={dataFieldsTipCard}
                            selectedUserAction={selectedUserAction}

                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />

                        <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar justify-between">
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {tipReport
                                            ?.reduce(
                                                (acc, curr) =>
                                                    Number(curr?.total_tip?.split(' ')[1]) + acc,
                                                0
                                            )
                                            .toFixed(2) || 0}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="report-tab-panel" value={value} index={7}>
                        <CustomTable
                            tableData={clockRequestReport}
                            headCells={HeadCellsClockRequestCard}
                            dataFields={dataFieldsClockRequestCard}
                            selectedUserAction={selectedUserAction}

                            // rowOptions={true}
                            // optionMenu={['delete']}
                        />

                        {/* <div className="min-w-full divide-y divide-gray-200">
                            <div className="bg-gray-400">
                                <div className="row total-bar">
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>Total</strong>
                                    </div>

                                    <div className="col-md-2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {deliveryTotal?.deliveries || 0}
                                    </div>
                                    <div className="col-md-2 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        {deliveryTotal?.fuelAmount || 0}
                                    </div>
                                    <div className="col-md-3 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        <strong>
                                            ${deliveryTotal?.deliveriesAmount / 100 || 0}
                                        </strong>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </TabPanel>
                </Box>

                <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'45%'}>
                    <div className="modals-contents">
                        {addTarget && (
                            <AddTarget
                                closeDialog={closeDialog}
                                selectedUserAction={selectedUserAction}
                            />
                        )}
                        {sendTarget && (
                            <SendTarget
                                closeDialog={closeDialog}
                                selectedUserAction={selectedUserAction}
                                sendTargetData={sendTarget}
                            />
                        )}
                    </div>
                </Dialog>
            </ContentLayout>
            <Modal
                ariaHideApp={false}
                isOpen={isTimeCardModalOpen}
                // onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="" style={{ width: '100%' }}>
                    <div
                        className="w-full flex justify-end items-end text-[28px] font-semibold cursor-pointer"
                        onClick={() => setIsTimeCardModalOpen(false)}
                    >
                        x
                    </div>
                    <div className="w-full flex flex-col items-center justify-center px-[20px] ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    label="Start date"
                                    onChange={(e) =>
                                        setReportStartDate(dayjs(e).format('YYYY-MM-DD'))
                                    }
                                    sx={{ width: '100%', marginBottom: '0px !important' }}
                                    maxDate={dayjs(Date.now())}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <div
                            style={{
                                marginLeft: '20px',
                                marginRight: '20px',
                                width: '100%',
                                paddingTop: '4px',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="End date"
                                        onChange={(e) =>
                                            setReportEndDate(dayjs(e).format('YYYY-MM-DD'))
                                        }
                                        sx={{ width: '100%' }}
                                        minDate={dayjs(reportStartDate).add(1, 'day')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        {parsedUserDetails?.role_name !== 'General Manager' ? (
                            <select
                                className="form-control  w-full pos-select text-black"
                                style={{ marginTop: '12px' }}
                                name="location"
                                value={locationFilter}
                                onBlur={(e: any) => setSelectedLocation(e.target.value)}
                            >
                                <option value="">Select Location</option>
                                {[...LocationOptions]?.map((location_name) => (
                                    <option key={location_name} value={location_name}>
                                        {location_name}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                        <Button
                            onClick={handleGetTimeCardReport}
                            className="h-[50px]"
                            style={{ marginTop: '20px', width: '100%' }}
                        >
                            Get Time Card Report
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal ariaHideApp={false} isOpen={isTipModalOpen} style={customStyles}>
                <div className="" style={{ width: '100%' }}>
                    <div
                        className="w-full flex justify-end items-end text-[28px] font-semibold cursor-pointer"
                        onClick={() => setIsTipModalOpen(false)}
                    >
                        x
                    </div>
                    <div className="w-full flex flex-col items-center justify-center px-[20px] ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    label="Start date"
                                    onChange={(e) =>
                                        setReportStartDate(dayjs(e).format('YYYY-MM-DD'))
                                    }
                                    sx={{ width: '100%', marginBottom: '0px !important' }}
                                    maxDate={dayjs(Date.now())}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <div
                            style={{
                                marginLeft: '20px',
                                marginRight: '20px',
                                width: '100%',
                                paddingTop: '4px',
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="End date"
                                        onChange={(e) =>
                                            setReportEndDate(dayjs(e).format('YYYY-MM-DD'))
                                        }
                                        sx={{ width: '100%' }}
                                        minDate={dayjs(reportStartDate).add(1, 'day')}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        {parsedUserDetails?.role_name !== 'General Manager' ? (
                            <select
                                className="form-control  w-full pos-select text-black"
                                style={{ marginTop: '12px' }}
                                name="location"
                                value={locationFilter}
                                onBlur={(e: any) => setSelectedLocation(e.target.value)}
                            >
                                <option value="">Select Location</option>
                                {[...LocationOptions]?.map((location_name) => (
                                    <option key={location_name} value={location_name}>
                                        {location_name}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                        <Button
                            onClick={handleGetTipReport}
                            className="h-[50px]"
                            style={{ marginTop: '20px', width: '100%' }}
                        >
                            Get Tip Report
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal ariaHideApp={false} isOpen={isClockRequestModalOpen} style={customStyles}>
                <div className="" style={{ width: '100%' }}>
                    <div
                        className="w-full flex justify-end items-end text-[28px] font-semibold cursor-pointer"
                        onClick={() => setIsClockRequestModalOpen(false)}
                    >
                        x
                    </div>
                    <div className="w-full flex flex-col items-center justify-center px-[20px] ">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']} sx={{ width: '100%' }}>
                                <DatePicker
                                    format="DD-MM-YYYY"
                                    label="Date"
                                    disabled
                                    value={dayjs(Date.now())}
                                    // onChange={(e) =>
                                    //     setReportStartDate(dayjs(e).format('YYYY-MM-DD'))
                                    // }
                                    sx={{ width: '100%', marginBottom: '0px !important' }}
                                    maxDate={dayjs(Date.now())}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        {parsedUserDetails?.role_name !== 'General Manager' ? (
                            <select
                                className="form-control  w-full pos-select text-black"
                                style={{ marginTop: '12px' }}
                                name="location"
                                value={locationFilter}
                                onBlur={(e: any) => setSelectedLocation(e.target.value)}
                            >
                                <option value="">Select Location</option>
                                {[...LocationOptions]?.map((location_name) => (
                                    <option key={location_name} value={location_name}>
                                        {location_name}
                                    </option>
                                ))}
                            </select>
                        ) : null}
                        <Button
                            onClick={handleClockRequestReport}
                            className="h-[50px]"
                            style={{ marginTop: '20px', width: '100%' }}
                        >
                            Get Clock Request Report
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
