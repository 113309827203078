import { useQuery } from 'react-query';

import { MenuProducts } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllMenuProducts = async (menu_group_id, menu_id): Promise<MenuProducts[]> => {
    const { data } = await axios.get(`v1/get-products-by-menu/${menu_group_id}/${menu_id}`);
    return data.products;
};

type QueryFnType = typeof getAllMenuProducts;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useMenuProducts = (menu_group_id, menu_id, { config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['menuProducts'],
        queryFn: () => getAllMenuProducts(menu_group_id, menu_id),
        enabled: !!menu_group_id,
    });
};
