/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CorporateFare } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useCorp } from '../api/CRUD/getCorp';
import { Corporation } from '../type';

import ShowUsers from './ShowUsers';
import { useUsers } from '@/features/users/api/getUsers';
import { Button } from '@/components/Elements';

const AddUser = ({ corpId, closeDialog }) => {
    const usersQuery = useUsers();
    const corpQuery = useCorp(corpId);
    const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
    const [searchVal, setSearchVal] = useState<string>('');
    const [corpData, setCorpData] = useState<Corporation | null>(null);
    const [isShowList, setIsShowList] = useState<boolean>(false);
    const handleGetCorp = () => {
        if (!corpQuery.isLoading) setCorpData(corpQuery?.data);
    };

    const handleSearchInput = (e: any) => {
        setSearchVal(e.target.value);
    };

    useEffect(() => {
        handleGetCorp();
    }, [corpQuery.isLoading, usersQuery.isLoading]);

    if (corpQuery.isLoading || usersQuery.isLoading) {
        return (
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add user / Corporation
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </div>
            </div>
        );
    }

    const handleSearch = () => {
        const tempFilteredUsers = usersQuery?.data?.filter((user) => {
            return (
                user?.first_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.last_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                user?.user_company?.toLowerCase()?.includes(searchVal.toLowerCase())
            );
        });
        setIsShowList(true);
        setFilteredUsers([...tempFilteredUsers]);
    };

    return (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add user / Corporation
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>

                    <div className="modal-body">
                        <div className="add-user-head d-flex align-items-center">
                            <CorporateFare className="pColor" />
                            <h5 className="add">{corpData?.corporate_name}</h5>
                            <span className="vip-tx">VIP</span>
                        </div>
                        <div className="input-group search">
                            <div className="w-full flex items-center">
                                <div className="w-full relative">
                                    <input
                                        style={{ marginBottom: 0 }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Existing User"
                                        value={searchVal}
                                        onChange={(e) => handleSearchInput(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <div
                                        className="input-group-close cursor-pointer"
                                        onClick={() => {
                                            setSearchVal('');
                                            setIsShowList(false);
                                        }}
                                        style={{ zIndex: 10 }}
                                    >
                                        <span className="input-group-text">
                                            <i className="fas fa-times"></i>
                                        </span>
                                    </div>
                                </div>
                                <Button
                                    variant="primary"
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => handleSearch()}
                                >
                                    Search
                                </Button>
                            </div>
                        </div>

                        {usersQuery?.data && (
                            <ShowUsers
                                usersData={filteredUsers}
                                corpId={corpId}
                                showList={isShowList ? true : false}
                                closeDialog={closeDialog}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUser;
