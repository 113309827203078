import { axios } from '@/lib/axios';

import { updateUser } from './updateUser';

export const addUser = async (user_id: number, corporate_id: number, isPrimaryUser: boolean) => {
    const { success, message, data } = await axios.post(`v1/add-user-to-corporation`, {
        user_id,
        corporate_id,
    });

    if (isPrimaryUser && data?.id) {
        updateUser(data.id, {
            is_primary: true,
        });
    }

    return { success, message };
};
