import { useEffect, useState } from 'react';

import editCheck from '../../../assets/edit-check.png';
import { addCorporateAddress } from '../api/CRUD/addCorporateAddress';
import { createInvoice } from '../api/CRUD/createInvoice';
import { updateCorp } from '../api/CRUD/update';
import { useNotificationStore } from '@/stores/notifications';
import { axios } from '@/lib/axios';
import { Button, Spinner } from '@/components/Elements';

const EditCorporte = ({ corpData, closeDialog, corpsQuery }: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { addNotification } = useNotificationStore();
    const [data, setData] = useState<any>(false);
    const [corporationAddressData, setCorporationAddressData] = useState<Array<any>>([]);
    const [corporationPaymentData, setCorporationPaymentData] = useState<Array<any>>([]);
    const [updateNote, setUpdateNote] = useState('');
    const [isActive, setIsActive] = useState(corpData?.corporate_active === 1 ? true : false);

    useEffect(() => {
        setData(corpData);
    }, [corpData]);

    const getCorporationAddress = async () => {
        try {
            setIsLoading(true);
            await axios.get(`v1/get-corporation-addresses/${corpData.id}`)?.then((res) => {
                setCorporationAddressData(res?.data?.address);
            });
        } catch (err) {
            console.log('error from get corporation address-->', err);
        } finally {
            setIsLoading(false);
        }
    };

    const getCorporationPayment = async () => {
        try {
            setIsLoading(true);
            await axios.get(`v1/get-corporation-payments/${corpData.id}`)?.then((res) => {
                setCorporationPaymentData(res?.data?.payments);
            });
        } catch (err) {
            console.log('error from get corporation payment-->', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCorporationAddress();
        getCorporationPayment();
    }, []);

    const [addAddress, setAddAddress] = useState({
        address_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        country: '',
        description: '',
        zip: '',
    });

    const [invoiceDeatils, setInvoiceDeatils] = useState({
        corporate_id: '',
        account_name: '',
        corporate_account_no: '',
        corporate_ap_contact_name: '',
        corporate_ap_contact_email: '',
    });

    const handleSubmit = async () => {
        const { success, message } = await updateCorp(data.id, isActive, updateNote);
        addNotification({
            type: success ? 'success' : 'error',
            title: message,
        });

        closeDialog();
        corpsQuery.refetch();
    };

    const handleSubmitInvoice = async () => {
        const { success, message } = await createInvoice(data.id, invoiceDeatils);
        addNotification({
            type: success ? 'success' : 'error',
            title: message,
        });

        closeDialog();
        corpsQuery.refetch();
    };

    const handleSubmitAddress = async () => {
        const { success, message } = await addCorporateAddress(data.id, addAddress);
        addNotification({
            type: success ? 'success' : 'error',
            title: message,
        });

        closeDialog();
        corpsQuery.refetch();
    };
    if (isLoading) {
        return (
            <div className="w-48 h-48 flex justify-center items-center bg-[#fff]">
                <Spinner size="lg" />
            </div>
        );
    }
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="editModalLabel">
                        Edit corporation
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <form className="user-form" action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <label htmlFor="fname" className="control-label">
                                        Corporation Name
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="fname"
                                            placeholder="Mitchell - Bartell"
                                            value={data?.corporate_name || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="umail" className="control-label">
                                        Email
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="umail"
                                            placeholder="mitchellbartell@gmail.com"
                                            value={data?.corporate_email || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="phone" className="control-label">
                                        Phone
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phone"
                                            placeholder="642-280-6515"
                                            value={data?.corporate_phone || ''}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="date" className="control-label">
                                        Created
                                    </label>
                                    <div className="form-group">
                                        <input
                                            type="date"
                                            className="form-control"
                                            name="date"
                                            placeholder="mm-dd-yyyy"
                                            value={
                                                data?.corporate_creation_date?.split('T')[0] || ''
                                            }
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="notes" className="control-label">
                                        Notes
                                    </label>
                                    <div className="form-group">
                                        <textarea
                                            className="form-control"
                                            name="notes"
                                            placeholder="notes"
                                            value={data?.notes ? data?.notes : ''}
                                            onChange={(e) => {
                                                setUpdateNote(e.target.value);
                                                setData((prevState: any) => ({
                                                    ...prevState,
                                                    notes: e.target.value,
                                                }));
                                            }}
                                            // required
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className="add-user-check"
                                        style={{
                                            borderBottom: 'none',
                                            borderTop: '1px solid #dee2e6',
                                            paddingTop: '15px',
                                        }}
                                    >
                                        <div
                                            className="add-user-left"
                                            style={{ borderRight: 'none', display: 'flex' }}
                                        >
                                            <label style={{ marginRight: '10px' }}>Active</label>
                                            <div className="form-check form-switch">
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexSwitchCheckChecked"
                                                >
                                                    {isActive ? 'Yes' : 'No'}
                                                </label>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    name="corporate_active"
                                                    id="flexSwitchCheckChecked"
                                                    checked={isActive}
                                                    onChange={() => setIsActive(!isActive)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseOne"
                                aria-expanded="false"
                                aria-controls="flush-collapseOne"
                            >
                                <p className="box-cookie-tx">
                                    {corporationAddressData?.length > 0 ? 'Update' : 'Edit'} Address
                                    Details
                                </p>
                            </button>
                        </h2>
                        <div
                            id="flush-collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingOne"
                            data-bs-parent="#accordionFlushExample"
                        >
                            {corporationAddressData?.length < 1 ? (
                                <div className="accordion-body row">
                                    <div className="col-md-6">
                                        <label htmlFor="addname" className="control-label">
                                            Address Name
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="addname"
                                                placeholder="Address Name"
                                                value={addAddress?.address_name || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        address_name: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="address_1" className="control-label">
                                            Address
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address_1"
                                                placeholder="Address_1"
                                                value={addAddress?.address_1 || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        address_1: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="address_2" className="control-label">
                                            Address
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="address_2"
                                                placeholder="Address_2"
                                                value={addAddress?.address_2 || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        address_2: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="city" className="control-label">
                                            City
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="city"
                                                placeholder="City"
                                                value={addAddress?.city || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        city: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="state" className="control-label">
                                            State
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="state"
                                                placeholder="State"
                                                value={addAddress?.state || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        state: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="country" className="control-label">
                                            Country
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="country"
                                                placeholder="Country"
                                                value={addAddress?.country || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        country: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="zip" className="control-label">
                                            Zip Code
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="zip"
                                                placeholder="Zip Code"
                                                value={addAddress?.zip || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        zip: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="description" className="control-label">
                                            Description
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="description"
                                                placeholder="Description"
                                                value={addAddress?.description || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setAddAddress((prevState) => ({
                                                        ...prevState,
                                                        description: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Button
                                            onClick={handleSubmitAddress}
                                            startIcon={<img src={editCheck} alt="editCheck" />}
                                            style={{ width: 'fit-content' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                corporationAddressData?.map((addressData, addressIndex) => {
                                    return (
                                        <div key={addressIndex} className="accordion-body row">
                                            <div className="col-md-6">
                                                <label htmlFor="addname" className="control-label">
                                                    Address Name
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="addname"
                                                        placeholder="Address Name"
                                                        value={addressData?.address_name || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                address_name: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="address_1"
                                                    className="control-label"
                                                >
                                                    Address
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address_1"
                                                        placeholder="Address_1"
                                                        value={addressData?.address_1 || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                address_1: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="address_2"
                                                    className="control-label"
                                                >
                                                    Address
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="address_2"
                                                        placeholder="Address_2"
                                                        value={addressData?.address_2 || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                address_2: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="city" className="control-label">
                                                    City
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="city"
                                                        placeholder="City"
                                                        value={addressData?.city || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                city: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="state" className="control-label">
                                                    State
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="state"
                                                        placeholder="State"
                                                        value={addressData?.state || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                state: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="country" className="control-label">
                                                    Country
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="country"
                                                        placeholder="Country"
                                                        value={addressData?.country || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                country: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="zip" className="control-label">
                                                    Zip Code
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="zip"
                                                        placeholder="Zip Code"
                                                        value={addressData?.zip || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                zip: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="description"
                                                    className="control-label"
                                                >
                                                    Description
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="description"
                                                        placeholder="Description"
                                                        value={addressData?.description || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setAddAddress((prevState) => ({
                                                                ...prevState,
                                                                description: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Button
                                                    onClick={handleSubmitAddress}
                                                    startIcon={
                                                        <img src={editCheck} alt="editCheck" />
                                                    }
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                    {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseTwo"
                                aria-expanded="false"
                                aria-controls="flush-collapseTwo"
                            >
                                <p className="box-cookie-tx">Edit Payment Details</p>
                            </button>
                        </h2>
                        <div
                            id="flush-collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingTwo"
                            data-bs-parent="#accordionFlushExample"
                        >
                            <div className="accordion-body">hjfhj</div>
                        </div>
                    </div> */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingThree">
                            <button
                                className="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#flush-collapseThree"
                                aria-expanded="false"
                                aria-controls="flush-collapseThree"
                            >
                                <p className="box-cookie-tx">
                                    {corporationPaymentData?.length > 0 ? 'Update' : 'Create'}{' '}
                                    Invoice Account
                                </p>
                            </button>
                        </h2>
                        <div
                            id="flush-collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="flush-headingThree"
                            data-bs-parent="#accordionFlushExample"
                        >
                            {corporationPaymentData?.length < 1 ? (
                                <div className="accordion-body row">
                                    <div className="col-md-6">
                                        <label htmlFor="account_name" className="control-label">
                                            Account Name
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="account_name"
                                                placeholder="Account Name"
                                                value={invoiceDeatils?.account_name || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setInvoiceDeatils((prevState) => ({
                                                        ...prevState,
                                                        corporate_id: String(data.id),
                                                        account_name: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label
                                            htmlFor="corporate_account_no"
                                            className="control-label"
                                        >
                                            Corporate Account No
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="corporate_account_no"
                                                placeholder="Corporate Account No"
                                                value={invoiceDeatils?.corporate_account_no || ''}
                                                // disabled
                                                onChange={(e) => {
                                                    setInvoiceDeatils((prevState) => ({
                                                        ...prevState,
                                                        corporate_account_no: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="contactname" className="control-label">
                                            Contact Name
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="contactname"
                                                placeholder="Contact Name"
                                                value={
                                                    invoiceDeatils?.corporate_ap_contact_name || ''
                                                }
                                                // disabled
                                                onChange={(e) => {
                                                    setInvoiceDeatils((prevState) => ({
                                                        ...prevState,
                                                        corporate_ap_contact_name: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="contact_email" className="control-label">
                                            Contact Email
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="contact_email"
                                                placeholder="Contact Email"
                                                value={
                                                    invoiceDeatils?.corporate_ap_contact_email || ''
                                                }
                                                // disabled
                                                onChange={(e) => {
                                                    setInvoiceDeatils((prevState) => ({
                                                        ...prevState,
                                                        corporate_ap_contact_email: e.target.value,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <Button
                                            onClick={handleSubmitInvoice}
                                            startIcon={<img src={editCheck} alt="editCheck" />}
                                            style={{ width: 'fit-content' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                corporationPaymentData?.map((paymentItem, paymentIndex) => {
                                    return (
                                        <div key={paymentIndex} className="accordion-body row">
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="account_name"
                                                    className="control-label"
                                                >
                                                    Account Name
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="account_name"
                                                        placeholder="Account Name"
                                                        value={paymentItem?.account_name || ''}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setInvoiceDeatils((prevState) => ({
                                                                ...prevState,
                                                                corporate_id: String(data.id),
                                                                account_name: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="corporate_account_no"
                                                    className="control-label"
                                                >
                                                    Corporate Account No
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="corporate_account_no"
                                                        placeholder="Corporate Account No"
                                                        value={
                                                            paymentItem?.corporate_account_no || ''
                                                        }
                                                        // disabled
                                                        onChange={(e) => {
                                                            setInvoiceDeatils((prevState) => ({
                                                                ...prevState,
                                                                corporate_account_no:
                                                                    e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="contactname"
                                                    className="control-label"
                                                >
                                                    Contact Name
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="contactname"
                                                        placeholder="Contact Name"
                                                        value={
                                                            paymentItem?.corporate_ap_contact_name ||
                                                            ''
                                                        }
                                                        // disabled
                                                        onChange={(e) => {
                                                            setInvoiceDeatils((prevState) => ({
                                                                ...prevState,
                                                                corporate_ap_contact_name:
                                                                    e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label
                                                    htmlFor="contact_email"
                                                    className="control-label"
                                                >
                                                    Contact Email
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="contact_email"
                                                        placeholder="Contact Email"
                                                        value={
                                                            paymentItem?.corporate_ap_contact_email ||
                                                            ''
                                                        }
                                                        // disabled
                                                        onChange={(e) => {
                                                            setInvoiceDeatils((prevState) => ({
                                                                ...prevState,
                                                                corporate_ap_contact_email:
                                                                    e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <Button
                                                    onClick={handleSubmitInvoice}
                                                    startIcon={
                                                        <img src={editCheck} alt="editCheck" />
                                                    }
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>

                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={handleSubmit}
                        startIcon={<img src={editCheck} alt="editCheck" />}
                        style={{ width: 'fit-content' }}
                    >
                        Save Changes
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditCorporte;
