import { useQuery } from 'react-query';

import { DeliveryReport } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type TGetDeliveryReport = {
    startDate?: 'string' | null;
    endDate?: 'string' | null;
    duration?: string | null;
};

export const getDeliveryReport = async (params?: TGetDeliveryReport): Promise<DeliveryReport[]> => {
    const { data } = await axios.get(
        `v1/get-delivery-report${
            params?.duration
                ? '?duration=' + params?.duration
                : params
                ? '?startDate=' + params?.startDate + '&endDate=' + params?.endDate
                : ''
        }`
    );
    return data;
};

type QueryFnType = typeof getDeliveryReport;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useDeliveryReport = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['deliveryReport'],
        queryFn: () => getDeliveryReport(),
    });
};
