import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
    type?: 'text' | 'email' | 'password' | 'number' | 'decimal';
    registration: Partial<UseFormRegisterReturn>;
    placeholder?: string;
    className?: string;
    name?: string;
};

export const InputField = (props: InputFieldProps) => {
    const {
        type = 'text',
        label,
        registration,
        error,
        placeholder,
        className,
        name,
        ...remain
    } = props;
    return (
        <FieldWrapper label={label} error={error}>
            <input
                className={className}
                step=".01"
                type={type}
                name={name}
                placeholder={placeholder}
                {...registration}
                {...remain}
                style={{ marginBottom: '5px' }}
            />
        </FieldWrapper>
    );
};
