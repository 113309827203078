import { useQuery } from 'react-query';

import { DiscountReport } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type TGetDiscountReport = {
    startDate?: 'string' | null;
    endDate?: 'string' | null;
    duration?: string | null;
};
export const getDiscountReport = async (params?: TGetDiscountReport): Promise<DiscountReport[]> => {
    const { data } = await axios.get(
        `v1/get-discount-report${
            params?.duration
                ? '?duration=' + params?.duration
                : params
                ? '?startDate=' + params?.startDate + '&endDate=' + params?.endDate
                : ''
        }`
    );
    return data;
};

type QueryFnType = typeof getDiscountReport;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useDiscountReport = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['discountReport'],
        queryFn: () => getDiscountReport(),
    });
};
