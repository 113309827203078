/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { Menu } from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { handleDateFormat } from '@/utils/commonHelperFunc';

const ViewMenu = ({ value, menuData, closeDialog, selectedUserAction, callbackFn }: any) => {
    const { addNotification } = useNotificationStore();
    const [data, setData] = useState<Menu | null | any>(null);
    const [products, setProducts] = useState<any>([]);
    useEffect(() => {
        setData(menuData);
        if (value !== 0) {
            getProductsbyMenu(menuData);
        } else getAllMenusData();
    }, [menuData]);

    const getAllMenusData = async () => {
        axios
            .get(`v1/get-menus-products?menu_group_id=${menuData?.id}`)
            .then((res) => setProducts(res?.data?.menus));
    };

    const getProductsbyMenu = async (data: any) => {
        axios.get(`v1/get-products-by-menu/${data?.menu_group_id}/${data?.id}`).then((res) => {
            setProducts(res.data.products);
        });
    };
    const handleProductDelete = async (data: any) => {
        axios.post(`v1/update-product/${data?.id}`, { product_active: false }).then(() => {
            addNotification({
                type: 'success',
                title: 'Updated successfully',
                message: 'Product deleted successfully',
            });
            callbackFn();
            // closeDialog();
        });
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {value == 0
                            ? `Menu - ${data?.menu_group_name}`
                            : `Menu Live - ${data?.menu_group_name} (${data?.location_name})`}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <CorporateFare className="pColor" />
                                <h4>{data?.menu_group_name}</h4>
                            </div>
                        </div>
                        {value === 0 ? (
                            <ul
                                className="user-details-list menus-listn"
                                style={{ paddingBottom: '10px' }}
                            >
                                {data?.active == 0 || data?.active ? (
                                    <li>
                                        <strong>Active Status:</strong>{' '}
                                        <span>{data?.active === 1 ? 'Yes' : 'No'}</span>
                                    </li>
                                ) : null}
                                {/* {data?.activation ? (
                                    <li>
                                        <strong>Activation Date:</strong>{' '}
                                        <span>{handleDateFormat(data?.activation)}</span>
                                    </li>
                                ) : null}
                                {data?.location_name ? (
                                    <li>
                                        <strong>Location:</strong>{' '}
                                        <span>{data?.location_name}</span>
                                    </li>
                                ) : null}
                                {data?.expiration ? (
                                    <li>
                                        <strong>Expiration Date:</strong>{' '}
                                        <span>{handleDateFormat(data?.expiration)}</span>
                                    </li>
                                ) : null} */}
                                {data?.created_at ? (
                                    <li>
                                        <strong>Created:</strong>
                                        <span>
                                            {moment(data?.created_at).format('YYYY-MM-DD HH:mm')}
                                        </span>
                                    </li>
                                ) : null}
                                {data?.updated_at ? (
                                    <li>
                                        <strong>Last Update:</strong>{' '}
                                        <span>
                                            {moment(data?.updated_at).format('YYYY-MM-DD HH:mm')}
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                        ) : (
                            <ul
                                className="user-details-list menus-listn"
                                style={{ paddingBottom: '10px' }}
                            >
                                {data?.active == 0 || data?.active ? (
                                    <li>
                                        <strong>Active Status:</strong>{' '}
                                        <span>{data?.active === 1 ? 'Yes' : 'No'}</span>
                                    </li>
                                ) : null}
                                {data?.location_name ? (
                                    <li>
                                        <strong>Location:</strong>{' '}
                                        <span>{data?.location_name}</span>
                                    </li>
                                ) : null}
                                {data?.activation ? (
                                    <li>
                                        <strong>Activation Date:</strong>{' '}
                                        <span>{handleDateFormat(data?.activation)}</span>
                                    </li>
                                ) : null}
                                {data?.activation ? (
                                    <li>
                                        <strong>Activation Time:</strong>{' '}
                                        <span>
                                            {moment.utc(data?.activation).format('HH:mm:ss')}
                                        </span>
                                    </li>
                                ) : null}

                                {data?.expiration ? (
                                    <li>
                                        <strong>Expiration Date:</strong>{' '}
                                        <span>{handleDateFormat(data?.expiration)}</span>
                                    </li>
                                ) : null}
                                {data?.expiration ? (
                                    <li>
                                        <strong>Expiration Time:</strong>{' '}
                                        <span>
                                            {moment.utc(data?.expiration).format('HH:mm:ss')}
                                        </span>
                                    </li>
                                ) : null}
                                {data?.date_created ? (
                                    <li>
                                        <strong>Created:</strong>
                                        <span>{handleDateFormat(data?.date_created)}</span>
                                    </li>
                                ) : null}
                                {data?.last_updated ? (
                                    <li>
                                        <strong>Last Update:</strong>{' '}
                                        <span>{handleDateFormat(data?.last_updated)}</span>
                                    </li>
                                ) : null}
                            </ul>
                        )}
                    </div>
                    {value == 0 ? (
                        <>
                            <hr />
                            <div className="modal-body">
                                <div className="view-user-details">
                                    <p className="box-cookie-tx">
                                        <span>
                                            <strong>Assigned Products</strong>
                                        </span>
                                        <div
                                            className="dark-tx d-flex items-end asgn-button"
                                            onClick={() =>
                                                selectedUserAction({
                                                    action: 'assign-products',
                                                    data: data,
                                                })
                                            }
                                        >
                                            <AddCircleOutlineIcon /> Assign Product
                                        </div>
                                    </p>
                                </div>
                                <div className="modal-body">
                                    <hr />
                                    {products != '' && value === 0 ? (
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden border-b border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr className="noborder">
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Product Name
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Base Price
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Price Override
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Active
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        List Order
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Edit
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Delete
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            {products?.map(
                                                                (item: any, idx: number) => (
                                                                    <tbody
                                                                        key={idx}
                                                                        className="noborder"
                                                                    >
                                                                        {item?.products?.map(
                                                                            (
                                                                                product: any,
                                                                                index: number
                                                                            ) => {
                                                                                return (
                                                                                    <tr
                                                                                        key={index}
                                                                                        className="noborder"
                                                                                    >
                                                                                        <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                            {
                                                                                                product.product_name
                                                                                            }
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm text-blue-700 font-medium whitespace-nowrap">
                                                                                            <span className="!text-blue-700">
                                                                                                {product.product_base_price
                                                                                                    ? `$${(
                                                                                                          product.product_base_price /
                                                                                                          100
                                                                                                      ).toFixed(
                                                                                                          2
                                                                                                      )}`
                                                                                                    : '-'}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm text-blue-700 font-medium whitespace-nowrap">
                                                                                            <span className="!text-blue-700">
                                                                                                {product.price_override
                                                                                                    ? `$${(
                                                                                                          product.price_override /
                                                                                                          100
                                                                                                      ).toFixed(
                                                                                                          2
                                                                                                      )}`
                                                                                                    : '-'}
                                                                                                {/* $
                                                                                                {(
                                                                                                    product.price_override /
                                                                                                    100
                                                                                                ).toFixed(
                                                                                                    2
                                                                                                )} */}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                            {product.product_active ==
                                                                                            1
                                                                                                ? 'Yes'
                                                                                                : 'No'}
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                            {
                                                                                                product.menu_list_order
                                                                                            }
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                            <span className="dark-tx !cursor-pointer">
                                                                                                <EditOutlinedIcon
                                                                                                    onClick={() =>
                                                                                                        selectedUserAction(
                                                                                                            {
                                                                                                                action: 'edit-product',
                                                                                                                data: {
                                                                                                                    ...data,
                                                                                                                    ...product,
                                                                                                                    ...item,
                                                                                                                },
                                                                                                            }
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                            <span
                                                                                                className="dark-tx"
                                                                                                onClick={() =>
                                                                                                    handleProductDelete(
                                                                                                        product
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <DeleteOutline />
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tbody>
                                                                )
                                                            )}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : products != '' ? (
                                        <div className="flex flex-col">
                                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden border-b border-gray-200">
                                                        <table className="min-w-full divide-y divide-gray-200">
                                                            <thead className="bg-gray-50">
                                                                <tr className="noborder">
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Product Name
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Price
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Active
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Edit
                                                                    </th>
                                                                    <th
                                                                        key={1}
                                                                        scope="col"
                                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                                    >
                                                                        Delete
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            {products?.map(
                                                                (product: any, index: number) => {
                                                                    return (
                                                                        <tbody
                                                                            key={index}
                                                                            className="noborder"
                                                                        >
                                                                            <tr
                                                                                key={index}
                                                                                className="noborder"
                                                                            >
                                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                    {
                                                                                        product.product_name
                                                                                    }
                                                                                </td>
                                                                                <td className="px-6 py-2 text-sm text-blue-700 font-medium whitespace-nowrap">
                                                                                    <span className="!text-blue-700">
                                                                                        $
                                                                                        {(
                                                                                            product.price /
                                                                                            100
                                                                                        ).toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                    {product.product_active ==
                                                                                    1
                                                                                        ? 'Yes'
                                                                                        : 'No'}
                                                                                </td>
                                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                    <span className="dark-tx !cursor-pointer">
                                                                                        <EditOutlinedIcon
                                                                                            onClick={() =>
                                                                                                selectedUserAction(
                                                                                                    {
                                                                                                        action: 'edit-product',
                                                                                                        data: {
                                                                                                            ...data,
                                                                                                            ...product,
                                                                                                        },
                                                                                                    }
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                                <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                                                    <span
                                                                                        className="dark-tx"
                                                                                        onClick={() =>
                                                                                            handleProductDelete(
                                                                                                product
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <DeleteOutline />
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    );
                                                                }
                                                            )}
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                    {products == '' && (
                                        <p className="pt-2">There are no any product assigned.</p>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: {
                                    ...data,
                                    ...(value === 0 ? { isLiveMenu: false } : { isLiveMenu: true }),
                                },
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewMenu;
