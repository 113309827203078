import { useQuery } from 'react-query';

import { CorporateInquiry } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';


export const getCorporateInquiry = async (): Promise<CorporateInquiry[]> => {
    const { data } = await axios.get(`v1/get-corporate-request-inquiries`);
    return data.inquiries;
};

type QueryFnType = typeof getCorporateInquiry;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useCorporateInquiry = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['corporateInquiry'],
        queryFn: () => getCorporateInquiry(),
    });
};
