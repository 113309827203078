import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

import React, { useEffect, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

const schema = z.object({});

type LoginValues = {};

const CreateGiftCard = ({ closeDialog }: any) => {
    const { addNotification } = useNotificationStore();
    // const intialGiftCardData = {
    //     firstname: '',
    //     lastname: '',
    //     id: '',
    //     email: '',
    //     amount: '',
    //     // active: true,
    // };
    // const [data, setData] = useState(intialGiftCardData);

    const [giftCards, setGiftCards] = useState([
        {
            gift_card_purchaser_first_name: '',
            gift_card_purchaser_last_name: '',
            gift_card_purchaser_email: '',
            gift_card_recipient_first_name: '',
            gift_card_recipient_last_name: '',
            gift_card_recipient_email: '',
            gift_card_amout: '',
            gift_card_code: '',
            active: true,
            // order_mode_id: 11,
            // gift_card_type: 1,
        },
    ]);

    // useEffect(() => {}, []);

    const onAddRecipient = (index) => {
        setGiftCards([
            ...giftCards,
            {
                gift_card_purchaser_first_name: '',
                gift_card_purchaser_last_name: '',
                gift_card_purchaser_email: '',
                gift_card_recipient_first_name: '',
                gift_card_recipient_last_name: '',
                gift_card_recipient_email: '',
                gift_card_amout: '',
                active: true,
                // order_mode_id: 11,
            },
        ]);
        setRecipientList((current) => [...current, giftCardUi(current.length)]);
    };
    const onRemoveRecipient = (index) => {
        setRecipientList((current) => {
            return [...current.filter((_, i) => i !== index)];
        });
        setGiftCards((current) => {
            return [...current.filter((_, i) => i !== index)];
        });
        //  setRecipientList([
        // ...recipientList.slice(0, index),
        // ...recipientList.slice(index + 1, recipientList.length)])
    };

    const onSetFormByIndex = (data, i) => {
        const _giftCards = [...giftCards];
        _giftCards[i] = {
            ..._giftCards[i],
            ...data,
        };

        setGiftCards([..._giftCards]);
    };

    const giftCardUi = (i) => {
        return (
            <>
                {giftCards[i] && (
                    <div className="row">
                        {/* <div className="col-md-6"></div> */}
                        <div className="row flex justify-end w-full">
                            <div className="col-md-6 flex justify-end w-full items-center">
                                <label
                                    htmlFor="active"
                                    style={{ marginBottom: '12px', marginRight: '10px' }}
                                >
                                    Active: {giftCards[i].active ? 'Yes' : 'No'}
                                </label>

                                <div className="form-check form-switch ml-[5px]">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        id="flexSwitchCheckChecked"
                                        name="active"
                                        onChange={(e) =>
                                            onSetFormByIndex(
                                                {
                                                    active: !giftCards[i].active,
                                                },
                                                i
                                            )
                                        }
                                        checked={giftCards[i].active}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="firstname" className="control-label">
                                Recipient First Name
                            </label>
                            <div className="form-group">
                                <InputField
                                    onChange={(e) =>
                                        onSetFormByIndex(
                                            {
                                                gift_card_recipient_first_name: e.target.value,
                                                gift_card_purchaser_first_name: '-',
                                            },
                                            i
                                        )
                                    }
                                    className="form-control"
                                    name="gift_card_recipient_first_name"
                                    placeholder="First Name"
                                    value={giftCards[i].gift_card_recipient_first_name}
                                    // error={formState.errors['firstname']}
                                    // registration={register('firstname')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="lastname" className="control-label">
                                Recipient Last Name
                            </label>
                            <div className="form-group">
                                <InputField
                                    value={giftCards[i].gift_card_recipient_last_name}
                                    onChange={(e) =>
                                        onSetFormByIndex(
                                            {
                                                gift_card_recipient_last_name: e.target.value,
                                                gift_card_purchaser_last_name: '-',
                                            },
                                            i
                                        )
                                    }
                                    name="gift_card_recipient_last_name"
                                    className="form-control"
                                    placeholder="Last Name"
                                    // error={formState.errors['lastname']}
                                    // registration={register('lastname')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" className="control-label">
                                Recipient Email
                            </label>
                            <div className="form-group">
                                <InputField
                                    value={giftCards[i].gift_card_recipient_email}
                                    onChange={(e) =>
                                        onSetFormByIndex(
                                            {
                                                gift_card_recipient_email: e.target.value,
                                                gift_card_purchaser_email: '-',
                                            },
                                            i
                                        )
                                    }
                                    name="gift_card_recipient_email"
                                    className="form-control"
                                    placeholder="Email Address"
                                    // error={formState.errors['lastname']}
                                    // registration={register('lastname')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="id" className="control-label">
                                Card Id
                            </label>
                            <div className="form-group">
                                <InputField
                                    value={giftCards[i].gift_card_code}
                                    onChange={(e) =>
                                        onSetFormByIndex(
                                            {
                                                gift_card_code: e.target.value,
                                            },
                                            i
                                        )
                                    }
                                    name="gift_card_code"
                                    className="form-control"
                                    placeholder="GHJ7489K7897UIOH"
                                    // error={formState.errors['id']}
                                    // registration={register('id')}
                                />
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="amount" className="control-label">
                                Amount
                            </label>
                            <div className="form-group">
                                <InputField
                                    type="number"
                                    onChange={(e) => {
                                        if (!isNaN(e.target.value)) {
                                            onSetFormByIndex(
                                                {
                                                    gift_card_amout: e.target.value * 100,
                                                },
                                                i
                                            );
                                        }
                                    }}
                                    className="form-control"
                                    name="amount"
                                    placeholder="Select Amount $"
                                    value={
                                        giftCards[i].gift_card_amout != 0
                                            ? giftCards[i].gift_card_amout / 100
                                            : giftCards[i].gift_card_amout
                                    }
                                    // error={formState.errors['amount']}
                                    // registration={register('amount')}
                                />
                            </div>
                        </div>
                        <div
                            className="col-md-6 align-baseline justify-end "
                            style={{ display: i > 0 ? 'flex' : 'none' }}
                        >
                            <span
                                className="dark-tx d-flex align-items-center mt-2"
                                onClick={() => onRemoveRecipient(i)}
                            >
                                <RemoveCircleOutline /> &nbsp; Remove
                            </span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const [recipientList, setRecipientList] = useState([giftCardUi(0)]);

    const updateGiftCard = async (data: any) => {
        const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        for (let i = 0; i < giftCards.length; i++) {
            if (giftCards[i].gift_card_recipient_first_name == '') {
                addNotification({
                    type: 'error',
                    title: 'Please enter recipient first name',
                });
                return new Promise(() => true);
            } else if (giftCards[i].gift_card_recipient_last_name == '') {
                addNotification({
                    type: 'error',
                    title: 'Please enter recipient last name',
                });
                return new Promise(() => true);
            } else if (giftCards[i].gift_card_recipient_email == '') {
                addNotification({
                    type: 'error',
                    title: 'Please enter recipient  email',
                });
                return new Promise(() => true);
            } else if (
                Number(giftCards[i].gift_card_amout) <= 0 ||
                giftCards[i].gift_card_amout == ''
            ) {
                addNotification({
                    type: 'error',
                    title: 'Please  enter valid amount',
                });
                return new Promise(() => true);
            }

            if (
                i == giftCards.length - 1 &&
                giftCards[i].gift_card_recipient_first_name != '' &&
                giftCards[i].gift_card_recipient_last_name != '' &&
                giftCards[i].gift_card_amout != ''
            ) {
                const updateData = {
                    giftCards: data,
                };
                const { success, message }: any = await axios.post(
                    `v1/create-gift-card`,
                    updateData
                );
                return { success, message };
            }
        }
    };

    const handleSubmit = async () => {
        await updateGiftCard(giftCards)
            .then((response: any) => {
                addNotification({
                    type: 'success',
                    title: response.message,
                });
                closeDialog();
            })
            .catch((response) => {
                addNotification({
                    type: 'error',
                    title: response.message,
                });
            });
    };
    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value }: any = e.target;

    //     switch (name) {
    //         case 'active':
    //             setData({ ...data, [name]: e.target.checked });
    //             break;
    //         default:
    //             setData({ ...data, [name]: value });
    //             break;
    //     }

    //     // setData({ ...data, [name]: value });
    // };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Create GiftCard
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div
                                        className="info-header"
                                        style={{ paddingTop: '22px', paddingBottom: '5px' }}
                                    >
                                        <div className="left-info">
                                            <strong>New Card</strong>
                                        </div>
                                        <div className="flex gap-3">
                                            {/* <div className="all-bx">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id="all"
                                                        name="all"
                                                        value="All"
                                                        checked={true}
                                                        onChange={() => {}}
                                                    />
                                                    <span>Create As Gift &nbsp; | &nbsp;</span>
                                                </label>
                                            </div> */}
                                            {/* <label htmlFor="active">Active:</label>
                                            {data?.active ? 'Yes' : 'No'}
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    name="active"
                                                    onChange={() => {
                                                        setData((prevState) => ({
                                                            ...prevState,
                                                            active: !data.active,
                                                        }));
                                                    }}
                                                    checked={!!data?.active || false}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                    {recipientList.map((data, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    borderBottom:
                                                        recipientList.length > 1
                                                            ? '1px dotted black'
                                                            : '',
                                                }}
                                            >
                                                <div>{giftCardUi(index)}</div>
                                            </div>
                                        );
                                    })}

                                    {/* need to be a button for add more card on click */}
                                    <span
                                        className="dark-tx d-flex align-items-center"
                                        onClick={onAddRecipient}
                                    >
                                        <AddCircleOutline /> &nbsp; Add One More
                                    </span>

                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<AddCircleOutline />}
                                            style={{ width: 'fit-content' }}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Changes
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateGiftCard;
