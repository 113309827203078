import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllCoupon } from '../api/CRUD/getAllCoupon';
import CreateCoupon from '../components/create';
import EditCoupon from '../components/edit';
import ViewCoupon from '../components/view';
import { HeadCells, dataFields } from '../constant';
import { Coupon, User } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import { axios } from '@/lib/axios';
import '../styles/styles.css';

export const CouponPage = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [coupon, setCoupon] = useState<Coupon[]>([]);
    const [createCoupon, setCreateCoupon] = useState<boolean>(false);
    const [editCoupon, setEditCoupon] = useState<Coupon | null>(null);
    const [viewCoupon, setviewCoupon] = useState<Coupon | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [checkboxFilter, setCheckboxFilter] = useState<string>('all');
    const [confirmMsg, setConfirmMsg] = useState<User | any>(null);

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getCoupon() {
        setIsLoading(true);
        const res = await getAllCoupon();
        setCoupon(res);
        setIsLoading(false);
    }
    useEffect(() => {
        getCoupon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!coupon) return null;
    const filteredEmployees = coupon?.filter((coupon: Coupon) => {
        if (searchVal || locationFilter) {
            if (searchVal) {
                return (
                    coupon?.coupon_code?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    coupon?.coupon_code__type_name?.toLowerCase()?.includes(searchVal.toLowerCase())
                );
            }
        } else {
            if (coupon?.coupon_code__type_name.toLowerCase() === checkboxFilter.toLowerCase()) {
                return coupon;
            } else if (checkboxFilter.toLowerCase() === 'all') {
                return coupon;
            }
        }
    });

    const deleteCoupon = async (id: any) => {
        const updateData = {
            active: 0,
        };

        const { success, message }: any = await axios.post(
            `v1/update-coupon-code/${id}`,
            updateData
        );
        return { success, message };
    };

    const handleDelete = async (couponData: Coupon) => {
        await deleteCoupon(couponData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Coupon Deleted',
            });
        });
        getCoupon();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditCoupon(null);
                setviewCoupon(data);
                setCreateCoupon(false);
                open();
                break;
            case 'edit':
                setviewCoupon(null);
                setEditCoupon(data);
                setCreateCoupon(false);
                open();
                break;
            case 'add-user':
                setviewCoupon(null);
                setEditCoupon(null);
                setCreateCoupon(false);
                open();
                break;
            case 'add-invoice':
                setviewCoupon(null);
                setEditCoupon(null);
                setCreateCoupon(false);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewCoupon(null);
        setEditCoupon(null);
        setCreateCoupon(false);
        close();
        getCoupon();
    };

    return (
        <ContentLayout title="" topNavBarData={{ num: coupon.length, title: 'Coupon Codes' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="checkboxes flex items-center justify-center ml-3 gap-3 pt-2">
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="all"
                                                name="all"
                                                value="All"
                                                checked={checkboxFilter === 'all'}
                                                onChange={() => {
                                                    checkboxFilter !== 'all' &&
                                                        setCheckboxFilter('all');
                                                }}
                                            />
                                            <span>All</span>
                                        </label>
                                    </div>
                                    <div className="all-bx live">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Global"
                                                name="Global"
                                                value="Global"
                                                checked={checkboxFilter === 'Global'}
                                                onChange={() => {
                                                    checkboxFilter === 'Global'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Global');
                                                }}
                                            />
                                            <span>Global</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Location"
                                                name="Location"
                                                value="Location"
                                                checked={checkboxFilter === 'Location'}
                                                onChange={() => {
                                                    checkboxFilter === 'Location'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Location');
                                                }}
                                            />
                                            <span>Location</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Mode"
                                                name="Mode"
                                                value="Mode"
                                                checked={checkboxFilter === 'Mode'}
                                                onChange={() => {
                                                    checkboxFilter === 'Mode'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Mode');
                                                }}
                                            />
                                            <span>Mode</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="User"
                                                name="User"
                                                value="User"
                                                checked={checkboxFilter === 'User'}
                                                onChange={() => {
                                                    checkboxFilter === 'User'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('User');
                                                }}
                                            />
                                            <span>User</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Ambassador"
                                                name="Ambassador"
                                                value="Ambassador"
                                                checked={checkboxFilter === 'Ambassador'}
                                                onChange={() => {
                                                    checkboxFilter === 'Ambassador'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Ambassador');
                                                }}
                                            />
                                            <span>Ambassador</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="add-btn coupons" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => (setCreateCoupon(!createCoupon), open())}
                                >
                                    Add Code
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={true}
                optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {createCoupon && <CreateCoupon closeDialog={closeDialog} />}
                    {viewCoupon && (
                        <ViewCoupon
                            couponData={viewCoupon}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editCoupon && <EditCoupon couponData={editCoupon} closeDialog={closeDialog} />}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
