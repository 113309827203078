export const HeadCells = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: 'Request Id',
        sort: true,
        type: 'text',
    },
    {
        id: 'customer',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
        sort: true,
        type: 'text',
    },
    {
        id: 'req_type',
        numeric: false,
        disablePadding: false,
        label: 'Request Type',
        sort: true,
        type: 'text',
    },
    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'Date',
        sort: true,
        type: 'dateTime',
    },
];
export const dataFields = ['id', 'customer', 'req_type', 'created_at'];
