import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
    label: React.ReactNode;
    value: string | number | string[];
};

type SelectFieldProps = FieldWrapperPassThroughProps & {
    options: Option[];
    defaultValue?: string;
    placeholder?: string;
    registration: Partial<UseFormRegisterReturn>;
    className: string;
    name: string;
};

export const SelectField = (props: SelectFieldProps) => {
    const { label, options, error, defaultValue, registration, ...remain } = props;
    return (
        <FieldWrapper label={label} error={error}>
            <select defaultValue={defaultValue} {...registration} {...remain}>
                {options.map(({ label, value }) => (
                    <option key={label?.toString()} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </FieldWrapper>
    );
};
