import { useQuery } from 'react-query';

import { Menu } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getAllMenu = async (): Promise<Menu[]> => {
    const { data } = await axios.get(`v1/get-menu-group`);
    return data.groups;
};

type QueryFnType = typeof getAllMenu;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useMenu = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['menu'],
        queryFn: () => getAllMenu(),
    });
};

export const getMenusProductsByMenuGroupId = async () => {
    const { data } = await axios.get(`v1/get-menus-products?menu_group_id=1`);
    return data?.menus;
};
