/* eslint-disable jsx-a11y/no-onchange */
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { useEffect, useState } from 'react';
import { Button } from '@/components/Elements';
import { getAssignedEmployees } from '@/features/employees/api/employees';

type TAssignedEmployeeModal = {
    onClose: () => void;
    onSuccess: (e: number) => void;
    orderData: any;
};

export const AssignedEmployeeModal = ({
    onClose,
    onSuccess,
    orderData,
}: TAssignedEmployeeModal) => {
    const [assignedEmployees, setAssignedEmployees] = useState([]);
    const [selectedDriver, setSelectedDriver] = useState('');
    const getAssignedEmployeesData = async () => {
        try {
            await getAssignedEmployees({
                params: {
                    location_id: orderData?.order_location_id,
                    role: 'Delivery Driver',
                },
            }).then((res) => setAssignedEmployees(res));
        } catch (err) {
            console.log('error in get assigned employees data-->', err);
        }
    };
    useEffect(() => {
        getAssignedEmployeesData();
    }, []);
    return (
        <Dialog
            open={true}
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
        >
            <DialogTitle>Select Driver to assign</DialogTitle>
            <DialogContent dividers>
                <label htmlFor="select">Select Driver</label>
                <div className="form-group">
                    <select
                        className="form-control"
                        name="driver"
                        required
                        onChange={(e: any) => setSelectedDriver(e?.target?.value)}
                        value={selectedDriver}
                        // error={formState.errors['deduction_mode']}
                        // registration={register('deduction_mode')}
                    >
                        <option value="">Select</option>
                        {assignedEmployees?.map((item: any, index: number) => {
                            return (
                                <option key={index} value={item?.user_id}>
                                    {item?.first_name + ' ' + item?.last_name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </DialogContent>
            <DialogActions>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: '10px',
                    }}
                >
                    <Button
                        disabled={!selectedDriver}
                        // startIcon={<Delete color={'white'} />}
                        type="button"
                        onClick={() => onSuccess(selectedDriver)}
                        style={{ width: 'fit-content' }}
                    >
                        Assign Driver
                    </Button>
                    <Button
                        type="button"
                        onClick={onClose}
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                    >
                        Cancel
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};
