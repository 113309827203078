import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType } from '@/lib/react-query';

export const getCorp = async (id: number) => {
    const { data } = await axios.get(`v1/get-corporation/${id}`);
    return data;
};

type QueryFnType = typeof getCorp;

export const useCorp = (id: number) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        queryKey: ['corp'],
        queryFn: () => getCorp(id),
    });
};
