import { useEffect, useState } from 'react';

import { useOrders } from '../api/CRUD/getAllOrders';
import AddInvoice from '../components/AddInvoice';
import CreateOrder from '../components/create';
import EditOrder from '../components/edit';
import Refund from '../components/Refund';
import { TableHeader } from '../components/TableHeader';
import { BodyCells, dataFields, HeadCells } from '../constant';
import { Order } from '../type';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Dialog, Spinner } from '@/components/Elements';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const Delivery = () => {
    const ordersQuery: any = useOrders();
    const { close, open, isOpen } = useDisclosure();

    const [searchVal, setSearchVal] = useState<string>('');
    //const [NoOfCorps, setNoOfCorps] = useState<number>(0);
    const [checkboxFilter, setCheckboxFilter] = useState<string>('Delivery');
    const [statusFilter, setStatusFilter] = useState<string>('');
    const [createOrder, setCreateOrder] = useState<boolean>(true);
    const [editOrder, setEditOrder] = useState<Order | null>(null);
    const [refundOrder, setRefundOrder] = useState<Order | null>(null);
    //const [OrderId, setOrderId] = useState<null | number>(null);
    const [invoice, setInvoice] = useState<null | number>(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>('');
    const [userDetails, setUserDetails] = useState<any>({});
    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/get-all-locations`);
        setLocations(data.locations);
    };
    //async function getNoOfCorps() {
    //    ordersQuery?.data?.length > 0 && setNoOfCorps(ordersQuery?.data?.length);
    //}
    useEffect(() => {
        const ls_user_details: any = localStorage.getItem('userDetails');
        setUserDetails(JSON.parse(ls_user_details));
    }, []);
    useEffect(() => {
        getAllLocations();
    }, []);
    useEffect(() => {
        //getNoOfCorps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ordersQuery.data, ordersQuery.isLoading, close]);

    if (ordersQuery.isLoading || ordersQuery.isRefetching) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!ordersQuery.data) return null;
    const filteredEmployees = ordersQuery?.data
        ?.filter((order: Order) => {
            if (statusFilter.length !== 0 || searchVal || selectedLocation) {
                if (
                    order?.order_type === checkboxFilter &&
                    order?.order_status === statusFilter &&
                    searchVal &&
                    order?.first_name?.toLowerCase()?.includes(searchVal?.toLowerCase())
                ) {
                    return order;
                } else if (
                    order?.order_type === checkboxFilter &&
                    order?.order_status === statusFilter
                ) {
                    return order;
                } else if (
                    order?.order_type === 'Delivery' &&
                    order?.order_status === statusFilter
                ) {
                    return order;
                } else if (
                    searchVal &&
                    (order?.first_name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
                        order?.order_status?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
                        order?.order_status?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
                        order?.location_name?.toLowerCase()?.includes(searchVal?.toLowerCase()) ||
                        order?.order_type?.toLowerCase()?.includes(searchVal?.toLowerCase()))
                ) {
                    return order;
                } else if (
                    selectedLocation &&
                    !order?.location_name
                        ?.toLowerCase()
                        ?.localeCompare(selectedLocation?.toLowerCase()) &&
                    order?.order_type === 'Delivery'
                ) {
                    return order;
                }
            } else {
                if (order?.order_type === checkboxFilter) {
                    return order;
                } else if (order?.order_type === 'Delivery') {
                    return order;
                } else if (
                    !!searchVal &&
                    order?.first_name?.toLowerCase()?.includes(searchVal?.toLowerCase())
                ) {
                    return order;
                } else if (
                    selectedLocation &&
                    !order?.location_name
                        ?.toLowerCase()
                        ?.localeCompare(selectedLocation?.toLowerCase())
                ) {
                    if (order?.order_type === 'Delivery') return order;
                }
            }
        })
        .filter((el: any) =>
            userDetails?.role_name === 'General Manager'
                ? el?.order_location_id === userDetails?.location_id
                : el
        );

    const handleDelete = (corpData: Order) => {
        //editUser({
        //    data: {
        //        ...userData,
        //        user_active: false,
        //    },
        //});
        //const newUsersList = data.filter((e) => e.id !== userData.id);
        //setUsersList(newUsersList);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'create':
                setRefundOrder(null);
                setCreateOrder(true);
                setEditOrder(null);
                setInvoice(null);
                open();
                break;
            case 'view':
                setRefundOrder(null);
                setCreateOrder(false);
                setEditOrder(data);
                setInvoice(null);
                open();
                break;
            case 'refund':
                setEditOrder(null);
                setCreateOrder(false);
                setRefundOrder(data);
                setInvoice(null);
                open();
                break;
            case 'add-invoice':
                setEditOrder(null);
                setRefundOrder(null);
                setCreateOrder(false);
                setInvoice(data);
                open();
                break;
            case 'remove':
                handleDelete(data);
                break;
            default:
                return null;
        }
    };
    const closeDialog = () => {
        setEditOrder(null);
        setRefundOrder(null);
        setCreateOrder(false);
        setInvoice(null);
        close();
        ordersQuery.refetch();
    };
    return (
        <ContentLayout
            title=""
            topNavBarData={{ num: filteredEmployees?.length, title: 'Deliveries' }}
        >
            {TableHeader(
                statusFilter,
                setStatusFilter,
                selectedUserAction,
                handleSearch,
                searchVal,
                locations,
                setSelectedLocation,
                selectedLocation
            )}
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                bodyCells={BodyCells}
                rowOptions={true}
                optionMenu={['refund', 'remove']}
            />
            <Dialog isOpen={isOpen} onClose={close} paperWidth={'fit-content'}>
                <div>
                    {createOrder && (
                        <CreateOrder
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editOrder && (
                        <EditOrder
                            ordersQuery={ordersQuery}
                            orderData={editOrder}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {refundOrder && <Refund orderData={refundOrder} closeDialog={closeDialog} />}
                    {invoice && <AddInvoice data={invoice} closeDialog={closeDialog} />}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
