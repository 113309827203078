import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Order } from '../../type';

export const getAllOrders = async (): Promise<Order[]> => {
    const { data } = await axios.get(`v1/get-all-orders`);
    return data.orders;
};

type QueryFnType = typeof getAllOrders;

type UseOrdersOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useOrders = ({ config }: UseOrdersOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['orders'],
        queryFn: () => getAllOrders(),
    });
};
