//import { useMutation } from 'react-query';

//import { useAuth } from '@/lib/auth';
import { axios } from '@/lib/axios';
//import { MutationConfig } from '@/lib/react-query';
//import { useNotificationStore } from '@/stores/notifications';

export type AssignAsEmployeeDTO = {
    user_id: number;
    location_id: any;
    role_id: number | boolean;
};

export const assignAsEmployee = async ({ user_id, location_id, role_id }: AssignAsEmployeeDTO) => {
    await axios
        .post(`v1/assign-remove-user-as-employee`, {
            user_id,
        })
        .then((res) => console.log(res));
    for (const id of location_id) {
        await axios
            .post(`v1/assign-store-to-employee`, {
                user_id,
                location_id: id.store,
                role_id,
            })
            .then((res) => console.log(res));
    }
    return true;
};
