import { CheckCircle, Person } from '@mui/icons-material';
import React, { useState } from 'react';

import closeIcn from '../../../assets/close.png';
import refundIcn from '../../../assets/edit-check.png';
import cardSrc from '../../../assets/user-card.png';
import { axios } from '@/lib/axios';
import { Button } from '@/components/Elements';
import { useNotificationStore } from '@/stores/notifications';

const Refund = ({ orderData, closeDialog }) => {
    const [confirmRefund, setconfirmRefund] = useState<boolean>(false);
    const [confirmMsg, setConfirmMsg] = useState<boolean>(false);
    const [refundAmount, setRefundAmount] = useState<any>(orderData.order_total / 100);
    const [refundAmountFlag, setRefundAmountFlag] = useState<boolean>(true);
    const { addNotification } = useNotificationStore();
    const handleRefund = async () => {
        await refundOrder().then((data) => {
            addNotification({
                type: 'success',
                title: data,
            });
        });
        setConfirmMsg(true);
    };
    const refundOrder = async () => {
        await axios.post('v1/refund-order', {
            id: orderData.id,
            amount: refundAmount * 100,
        });
    };

    const handleCheck = async () => {
        if (refundAmount == 0) {
            alert('Please enter valid amount');
        } else if (refundAmount * 100 > orderData.order_total) {
            alert(`Max amount is $ ${orderData.order_total / 100}`);
        } else {
            setconfirmRefund(true);
        }
    };

    return (
        <div className="modal-dialog">
            {confirmMsg ? (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="msgrefundModalLabel">
                            Message
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="confirm-refund">
                            <p className="text-center">
                                Your refund has been successfully completed
                            </p>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                        <Button
                            style={{ width: 'fit-content' }}
                            startIcon={<CheckCircle />}
                            onClick={() => closeDialog()}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="orderrefundModalLabel">
                            {!confirmRefund ? 'Order Refund' : 'Refund Confirmation'}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    {!confirmRefund ? (
                        <div className="modal-body">
                            <div className="view-user-details refund">
                                <div
                                    className="info-header location-edit"
                                    style={{ paddingTop: '0' }}
                                >
                                    <div className="left-info">
                                        <h4>
                                            <i className="far  fa-user"></i>{' '}
                                            {orderData.first_name + ' ' + orderData.last_name}
                                        </h4>
                                    </div>
                                    <div className="right-info">
                                        <div className="order-right">
                                            <span>
                                                <strong>Order: </strong>
                                                <span className="dark-tx">#{orderData.id}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="row"
                                    style={{
                                        padding: '12px 0 10px 0',
                                        borderBottom: '1px solid #c8d4df',
                                    }}
                                >
                                    <div className="col-12 col-md-8">
                                        <div className="order-left-head">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    checked={refundAmountFlag}
                                                    onClick={() => {
                                                        const amount: any = (
                                                            orderData.order_total / 100
                                                        ).toFixed(2);
                                                        setRefundAmount(amount);
                                                        setRefundAmountFlag(true);
                                                    }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault1"
                                                >
                                                    Full Refund
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    checked={!refundAmountFlag}
                                                    onClick={() => {
                                                        setRefundAmount(0);
                                                        setRefundAmountFlag(false);
                                                    }}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault2"
                                                >
                                                    Partial Refund
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <label
                                            htmlFor="amount"
                                            className="control-label"
                                            style={{
                                                fontSize: '13px',
                                                color: 'rgba(17, 17, 17, 0.64)',
                                            }}
                                        >
                                            Amount of refund
                                        </label>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="amount"
                                                placeholder="$0"
                                                value={refundAmount}
                                                required
                                                onChange={(e) => {
                                                    setRefundAmount(e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-body">
                            <div
                                className="confirm-refund"
                                style={{ borderBottom: '1px solid #dee2e6', paddingBottom: '14px' }}
                            >
                                <p className="text-center" style={{ paddingBottom: '10px' }}>
                                    Are you sure you want to partial refund?
                                </p>
                                <ul className="order-listing refund">
                                    <li>
                                        <i
                                            className="far  fa-user"
                                            style={{ color: '#526eff', marginRight: '8px' }}
                                        ></i>{' '}
                                        {orderData.first_name + ' ' + orderData.last_name}
                                    </li>
                                    <li>
                                        <svg
                                            width="19"
                                            style={{ marginRight: '8px' }}
                                            height="22"
                                            viewBox="0 0 19 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.5 22H17.5C17.7652 22 18.0196 21.8946 18.2071 21.7071C18.3946 21.5196 18.5 21.2652 18.5 21V5C18.5 4.73478 18.3946 4.48043 18.2071 4.29289C18.0196 4.10536 17.7652 4 17.5 4H1.5C1.23478 4 0.98043 4.10536 0.792893 4.29289C0.605357 4.48043 0.5 4.73478 0.5 5V21C0.5 21.2652 0.605357 21.5196 0.792893 21.7071C0.98043 21.8946 1.23478 22 1.5 22ZM2.5 6H16.5V20H2.5V6ZM15.5 0C15.7652 0 16.0196 0.105357 16.2071 0.292893C16.3946 0.48043 16.5 0.734784 16.5 1C16.5 1.26522 16.3946 1.51957 16.2071 1.70711C16.0196 1.89464 15.7652 2 15.5 2H3.5C3.23478 2 2.98043 1.89464 2.79289 1.70711C2.60536 1.51957 2.5 1.26522 2.5 1C2.5 0.734784 2.60536 0.48043 2.79289 0.292893C2.98043 0.105357 3.23478 0 3.5 0H15.5ZM13.5 9V11C13.5 11.2652 13.3946 11.5196 13.2071 11.7071C13.0196 11.8946 12.7652 12 12.5 12H6.5C6.23478 12 5.98043 11.8946 5.79289 11.7071C5.60536 11.5196 5.5 11.2652 5.5 11V9C5.5 8.73478 5.60536 8.48043 5.79289 8.29289C5.98043 8.10536 6.23478 8 6.5 8C6.76522 8 7.01957 8.10536 7.20711 8.29289C7.39464 8.48043 7.5 8.73478 7.5 9V10H11.5V9C11.5 8.73478 11.6054 8.48043 11.7929 8.29289C11.9804 8.10536 12.2348 8 12.5 8C12.7652 8 13.0196 8.10536 13.2071 8.29289C13.3946 8.48043 13.5 8.73478 13.5 9Z"
                                                fill="#526EFF"
                                            />
                                        </svg>
                                        Order #{orderData.id}
                                    </li>
                                    <li>
                                        <img
                                            src={cardSrc}
                                            alt="amount"
                                            style={{ marginRight: '8px' }}
                                        />
                                        $ {refundAmount}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '1rem',
                            justifyContent: 'space-between',
                            padding: '10px 0rem 0 0',
                        }}
                    >
                        <Button
                            onClick={() => (!confirmRefund ? handleCheck() : handleRefund())}
                            startIcon={<img src={refundIcn} />}
                            style={{ width: 'fit-content' }}
                        >
                            {!confirmRefund ? 'Confirm Refund' : 'Refund'}
                        </Button>
                        <Button
                            variant="secondary"
                            style={{ width: 'fit-content' }}
                            onClick={() => closeDialog()}
                        >
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img src={closeIcn} style={{ marginRight: '8px' }} alt="" /> Cancel
                            </span>
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Refund;
