/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getStores } from '../api/getStores';
import { getPromiseTime } from '../api/getPromiseTime';
import { Spinner } from '@/components/Elements';

const OrderType = ({
    handleOrder,
    orderData,
    setOrderData,
    setSelectedSlot,
    selectedSlot,
}: any) => {
    const locAPI = process.env.REACT_APP_LOCATION_API_URL;
    const [delivery, setDelivery] = useState<boolean>(false);
    const [stores, setStores] = useState<any[]>([]);
    const [searchval, setSearchval] = useState<any>();
    const [searchloc, setSearchloc] = useState<any[]>([]);
    const [sresvisible, setSresvisible] = useState<boolean>(false);
    const [latlong, setLatlong] = useState<any>();
    const [userlocation, setUserlocation] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [slots, setSlots] = useState<Array<any>>([]);
    const getStoresData = async () => {
        try {
            setIsLoading(true);
            await getStores().then((stores) => setStores(stores));
            // getLocation();
        } catch (err) {
            console.log('error from get stores data->>', err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getStoresData();
    }, []);
    useEffect(() => {
        if (searchloc) {
            setOrderData({
                reward: true,
                order_type_id: orderData?.order_type_id,
                store: orderData.store,
                address: searchloc[0]?.address_line1 + ' ' + searchloc[0]?.address_line2,
                delivery_address: searchloc[0]?.address_line1 + ' ' + searchloc[0]?.address_line2,
                lat: searchloc[0]?.lat,
                long: searchloc[0]?.lon,
            });
        }
    }, [searchloc]);

    const handleOnChange = (e: any) => {
        handleOrder(e.target);
    };

    // for automatic location with lat and long
    const getLocation = async () => {
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(showPosition);
        }
    };

    const showPosition = async (position: any) => {
        setLatlong({
            lat: position.coords.latitude,
            long: position.coords.longitude,
        });
        await fetch(
            `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json&apiKey=${locAPI}`
        )
            .then((response) => response.json())
            .then((result) => {
                setUserlocation(result.results[0]);
            })
            .catch((error) => {});
    };

    ///////////

    // for searching locations
    const handleSearch = async (e: any) => {
        setSearchval(e.target.value);
        await fetch(
            `https://api.geoapify.com/v1/geocode/autocomplete?text=${searchval}&format=json&apiKey=${locAPI}`
        )
            .then((response) => response.json())
            .then((result) => {
                setSearchloc(result.results);
            })
            .catch((error) => {});
    };

    const handlePromiseTimeData = async (date: any) => {
        try {
            const locationId = orderData?.store;
            const orderTypeId = orderData?.order_type_id;
            await getPromiseTime(locationId, orderTypeId, date)
                .then((res) => {
                    const filteredTimeSlots = res?.filter((el: string) =>
                        moment(el)?.isAfter(Date.now())
                    );
                    setSlots([...filteredTimeSlots]);
                })
                .catch((error) => console.log('error from getPromiseTime->>', error));
        } catch (err) {
            console.log('error from handle promise time data-->', err);
        }
    };

    useEffect(() => {
        if (orderData?.store) handlePromiseTimeData(selectedSlot?.date);
    }, [orderData?.store]);

    return isLoading ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div className="collect-rewards mt-4">
                <div className="rewards-right d-flex">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="order_type_id"
                            id="Pickup-order"
                            defaultChecked
                            value={17}
                            // onChange={handleOnChange}
                            onClick={(e) => {
                                handleOnChange(e);
                                setDelivery(false);
                            }}
                        />
                        <label className="form-check-label" htmlFor="Pickup-order">
                            Pickup
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="order_type_id"
                            id="Delivery-order"
                            value={18}
                            // onChange={handleOnChange}
                            onClick={(e) => {
                                handleOnChange(e);
                                setDelivery(true);
                            }}
                            // onClick={() => setDelivery(true)}
                        />
                        <label className="form-check-label" htmlFor="Delivery-order">
                            Delivery
                        </label>
                    </div>
                </div>
            </div>
            <div></div>
            <div className="col-md-12">
                <div className="form-group pt-3 pb-1">
                    <label htmlFor="store">Store</label>
                    <select
                        className="form-control"
                        name="store"
                        defaultValue={orderData.store}
                        onChange={handleOnChange}
                    >
                        <option value="">Select Store</option>
                        {stores.map(({ id, location_name }) => (
                            <option key={id} value={id}>
                                {location_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {delivery && (
                <div className="col-md-12">
                    <div className="form-group pt-3 pb-3">
                        <label htmlFor="address">Delivery Address</label>
                        <input
                            className="form-control"
                            name="address"
                            onChange={(e) => {
                                handleSearch(e);
                                handleOnChange(e);
                            }}
                            value={searchval}
                            onClick={() => {
                                setSresvisible(true);
                            }}
                        />
                    </div>
                    <ul
                        className={
                            sresvisible ? 'showuser-list location_list visible' : 'invisible'
                        }
                        style={{}}
                    >
                        {searchloc ? (
                            searchloc.length > 0 ? (
                                searchloc.map((location: any) => {
                                    const { place_id } = location;
                                    return (
                                        <li
                                            key={place_id}
                                            onClick={() => {
                                                setSearchloc([location]);
                                                setSearchval(
                                                    `${location.address_line1},${location.address_line2}`
                                                );
                                                setSresvisible(false);
                                                orderData.address = `${location.address_line1}, ${location.address_line2}`;
                                            }}
                                        >
                                            {location.address_line1}, {location.address_line2}
                                        </li>
                                    );
                                })
                            ) : (
                                <li style={{ background: '#fff' }}>Please type something!</li>
                            )
                        ) : null}
                    </ul>
                </div>
            )}
            {orderData?.store ? (
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="date" className="control-label">
                            Date
                        </label>
                        <div className="form-group">
                            <input
                                type="date"
                                className="form-control"
                                name="date"
                                placeholder="mm-dd-yyyy"
                                onChange={(e) => {
                                    handlePromiseTimeData(e?.target.value);
                                    setSelectedSlot({
                                        time: '',
                                        date: e.target.value,
                                    });
                                }}
                                value={selectedSlot?.date}
                                // value={data?.corporate_creation_date?.split('T')[0] || ''}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="activation_time" className="control-label">
                            Time
                        </label>
                        <select
                            className="form-control"
                            name="time"
                            value={selectedSlot?.time}
                            onChange={(e) => {
                                setSelectedSlot((prev: any) => ({
                                    ...prev,
                                    time: e.target.value,
                                }));
                            }}
                        >
                            <option value="">Select Time</option>
                            {slots.map((item: string, index: number) => (
                                <option key={index} value={item?.split(' ')[1]}>
                                    {item?.split(' ')[1]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : null}
            {/* <div className="pb-4 pt-4">
                <p>
                Your Current Location:{' '}
                <b>
                {userlocation.address_line1}, {userlocation.address_line2}
                </b>
                </p>
            </div> */}
        </>
    );
};

export default OrderType;
