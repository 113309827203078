/* eslint-disable jsx-a11y/no-onchange */
import { AddCircleOutline } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { getAllMenu } from '../api/CRUD/getAllMenu';
import { getLiveMenu } from '../api/CRUD/getLiveMenu';
import CreateMenu from '../components/create';
import ViewMenu from '../components/view';
import { HeadCells, HeadCellsForAllMenus, dataFields, dataFieldsForAllMenus } from '../constant';
import { Menu } from '../type';
//import { HeadCells } from '@/features/users/constant';

import AssignProducts from '../components/assignProducts';
import EditProduct from '../components/editProduct';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
import '../styles/styles.css';
import EditMenu from '../components/editMenu';

export const Menus = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();

    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [menu, setMenu] = useState<Menu[]>([]);
    const [livemenu, setLiveMenu] = useState<Menu[]>([]);
    const [createMenu, setCreateMenu] = useState<boolean>(false);
    const [copyMenu, setCopyMenu] = useState<Menu | null>(null);
    const [editMenu, setEditMenu] = useState<Menu | null>(null);
    const [editProduct, setEditProduct] = useState<Menu | null>(null);
    const [viewMenu, setviewMenu] = useState<Menu | null>(null);
    const [assignProducts, setAssignProducts] = useState<Menu | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    // const [checkboxFilter, setCheckboxFilter] = useState<string>('all');
    const [confirmMsg, setConfirmMsg] = useState<any>(null);
    const [value, setValue] = useState(0);
    const [userDetails, setUserDetails] = useState<any>({});
    const handleChange = (newValue: any) => {
        const a = parseInt(newValue.target.id);
        setValue(a);
    };

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getMenu() {
        setIsLoading(true);
        const res = await getAllMenu();
        const reslive = await getLiveMenu();
        setMenu(res);
        setLiveMenu(reslive);
        setIsLoading(false);
    }

    useEffect(() => {
        const ls_user_details: any = localStorage.getItem('userDetails');
        setUserDetails(JSON.parse(ls_user_details));
    }, []);

    useEffect(() => {
        getMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!menu) return null;
    const filteredMenu = menu?.filter((menu: any) => {
        if (searchVal || locationFilter) {
            if (!!menu?.location_name && menu?.location_name === locationFilter) {
                return menu;
            }
            if (searchVal) {
                return menu?.menu_group_name?.toLowerCase()?.includes(searchVal.toLowerCase());
            }
        } else {
            return menu;
        }
    });

    const filteredLiveMenu = livemenu?.filter((livemenu: any) => {
        if (searchVal || locationFilter) {
            if (livemenu?.location_name === locationFilter) {
                return menu;
            }
            if (searchVal) {
                return (
                    livemenu?.menu_group_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                    livemenu?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase())
                );
            }
        } else {
            return livemenu;
        }
    });

    const deleteMenu = async (id: any) => {
        const { success, message }: any = await axios.post(`v1/update-menu-group/${id}`, {
            active: false,
        });
        return { success, message };
    };
    const deleteLiveMenu = async (id: any) => {
        const { success, message }: any = await axios.post(`v1/update-live-menu/${id}`, {
            active: false,
        });
        return { success, message };
    };

    const handleDelete = async (menuData: Menu) => {
        if (menuData?.isLiveMenu) {
            await deleteLiveMenu(menuData.id).then(() => {
                addNotification({
                    type: 'success',
                    message: 'Deleted Successfully',
                    title: 'Live Menu Deleted Successfully',
                });
                closeDialog();
            });
            getMenu();
            setConfirmMsg(null);
        } else {
            await deleteMenu(menuData.id).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Menu Deleted',
                    message: 'Menu Deleted Successfully',
                });
                closeDialog();
            });
            getMenu();
            setConfirmMsg(null);
        }
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditMenu(null);
                setviewMenu(data);
                setCreateMenu(false);
                open();
                break;
            case 'edit':
                setviewMenu(null);
                setEditMenu(data);
                setCreateMenu(false);
                open();
                break;
            case 'copy':
                setviewMenu(null);
                setEditMenu(null);
                setCreateMenu(false);
                setCopyMenu(data);
                open();
                break;
            case 'edit-product':
                setviewMenu(null);
                setEditMenu(null);
                setCreateMenu(false);
                setEditProduct(data);
                setCopyMenu(null);
                open();
                break;
            case 'add-user':
                setviewMenu(null);
                setEditMenu(null);
                setCreateMenu(false);
                open();
                break;
            case 'add-invoice':
                setviewMenu(null);
                setEditMenu(null);
                setCreateMenu(false);
                open();
                break;
            case 'assign-products':
                setviewMenu(null);
                setEditMenu(null);
                setCreateMenu(false);
                setAssignProducts(data);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewMenu(null);
        setEditMenu(null);
        setCreateMenu(false);
        setAssignProducts(null);
        setCopyMenu(null);
        setEditProduct(null);
        getMenu();
        close();
    };

    const LocationOptions: any = new Set(livemenu?.map((item: any) => item.location_name));

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: any) {
        return {
            id: index,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <ContentLayout title="" topNavBarData={{ num: menu.length, title: 'Menus' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <div className="flex">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                                {userDetails?.role_name !== 'General Manager' ? (
                                    <div className="form-group">
                                        <select
                                            className="form-control search-top-menu ml-1 w-auto"
                                            name="location"
                                            value={locationFilter}
                                            onChange={(e: any) => setLocationFilter(e.target.value)}
                                        >
                                            <option value="">Select Location</option>
                                            {[...LocationOptions]?.map((location_name) => (
                                                <option key={location_name} value={location_name}>
                                                    {location_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                                {/* <div className="checkboxes flex items-center justify-center ml-4 gap-4 pt-2">
                                    <div className="all-bx">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="all"
                                                name="all"
                                                value="All"
                                                checked={checkboxFilter === 'all'}
                                                onChange={() => {
                                                    checkboxFilter !== 'all' &&
                                                        setCheckboxFilter('all');
                                                }}
                                            />
                                            <span>All</span>
                                        </label>
                                    </div>
                                    <div className="all-bx live">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="live"
                                                name="live"
                                                value="Live"
                                                checked={checkboxFilter === 'Live'}
                                                onChange={() => {
                                                    checkboxFilter === 'Live'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Live');
                                                }}
                                            />
                                            <span>Live</span>
                                        </label>
                                    </div>
                                    <div className="all-bx Draft">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="Draft"
                                                name="Draft"
                                                value="Draft"
                                                checked={checkboxFilter === 'Draft'}
                                                onChange={() => {
                                                    checkboxFilter === 'Draft'
                                                        ? setCheckboxFilter('all')
                                                        : setCheckboxFilter('Draft');
                                                }}
                                            />
                                            <span>Draft</span>
                                        </label>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="add-btn" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => (setCreateMenu(!createMenu), open())}
                                >
                                    Add Menu
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 0 }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className="prTabs menu-dash-tabs"
                        style={{ paddingTop: '10px' }}
                    >
                        <Tab
                            label="All Menus"
                            className="left-menu"
                            value={0}
                            {...a11yProps(0)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '6px 0px 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            }}
                        />
                        <Tab
                            label="Live Menus"
                            className="right-menu"
                            value={1}
                            {...a11yProps(1)}
                            style={{
                                border: '1px solid  #c8d4df',
                                borderRadius: '0px 6px 0 0',
                                width: '50%',
                                maxWidth: '50%',
                            }}
                        />
                    </Tabs>
                </Box>

                <TabPanel className="menu-tab-panel" value={value} index={0}>
                    <CustomTable
                        tableData={filteredMenu}
                        headCells={HeadCellsForAllMenus}
                        dataFields={dataFieldsForAllMenus}
                        selectedUserAction={selectedUserAction}
                        rowOptions={true}
                        optionMenu={['edit', 'copy', 'delete']}
                    />
                </TabPanel>
                <TabPanel className="menu-tab-panel" value={value} index={1}>
                    <CustomTable
                        tableData={filteredLiveMenu}
                        headCells={HeadCells}
                        dataFields={dataFields}
                        selectedUserAction={selectedUserAction}
                        rowOptions={false}
                        // optionMenu={['edit', 'copy', 'delete']}
                    />
                </TabPanel>
            </Box>

            <Dialog
                isOpen={isOpen}
                onClose={closeDialog}
                paperWidth={assignProducts ? '70%' : '45%'}
            >
                <div className="modals-contents">
                    {createMenu && (
                        <CreateMenu
                            value={value}
                            menuData={createMenu}
                            action={'create'}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {copyMenu && (
                        <CreateMenu
                            menuData={copyMenu}
                            action={'copy'}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editMenu && (
                        <EditProduct
                            value={value}
                            menuData={editMenu}
                            action={'edit'}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {viewMenu && (
                        <ViewMenu
                            value={value}
                            menuData={viewMenu}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                            callbackFn={() => getMenu()}
                        />
                    )}
                    {assignProducts && (
                        <AssignProducts
                            value={value}
                            menuData={assignProducts}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editProduct && (
                        <EditMenu
                            closeDialog={closeDialog}
                            posData={editProduct}
                            callbackFn={(menu_group_id: number) => {
                                const currentIndex: number = menu?.findIndex(
                                    (el: any) => el?.id === menu_group_id
                                );
                                closeDialog();
                                selectedUserAction({
                                    action: 'view',
                                    data: menu[currentIndex],
                                });
                            }}
                        />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
