export const HeadCells = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: true,
        disablePadding: true,
        label: 'Last Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'text',
    },
    {
        id: 'user_birthday',
        numeric: true,
        disablePadding: false,
        label: 'Birthday',
        sort: true,
        type: 'date',
    },
    {
        id: 'location_name',
        numeric: true,
        disablePadding: false,
        label: 'Store',
        sort: true,
        type: 'text',
    },
    {
        id: 'role_name',
        numeric: true,
        disablePadding: false,
        label: 'Role',
        sort: true,
        type: 'text',
    },
    {
        id: 'assigned',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'date',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];

export const dataFields = [
    'first_name',
    'last_name',
    'user_email',
    'user_phone',
    'user_birthday',
    'location_name',
    'role_name',
    'assigned',
    'active',
];

export const Employee_roles = [
    {
        id: 1,
        role_name: 'Administrator',
    },
    {
        id: 2,
        role_name: 'General Manager',
    },
    {
        id: 3,
        role_name: 'Shift Manager',
    },
    {
        id: 4,
        role_name: 'Delivery Driver',
    },
    {
        id: 5,
        role_name: 'Crew Member',
    },
];
