export const HeadCells = [
    // {
    //     id: 'id',
    //     numeric: true,
    //     disablePadding: true,
    //     label: '#',
    //     sort: true,
    //     type: 'text',
    // },
    {
        id: 'menu_group_name',
        numeric: false,
        disablePadding: false,
        label: 'Menu Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location Name',
        sort: false,
        type: 'text',
    },
    {
        id: 'date_created',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'activation',
        numeric: true,
        disablePadding: false,
        label: 'Activation Date',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'expiration',
        numeric: true,
        disablePadding: false,
        label: 'Expiration Date',
        sort: true,
        type: 'dateTime',
    },
    // {
    //     id: 'date_updated',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Last Update',
    //     sort: true,
    //     type: 'dateTime',
    // },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    // 'id',
    'menu_group_name',
    'location_name',
    'date_created',
    'activation',
    'expiration',
    // 'date_updated',
    'active',
];

export const HeadCellsForAllMenus = [
    {
        id: 'menu_group_name',
        numeric: false,
        disablePadding: false,
        label: 'Menu Name',
        sort: true,
        type: 'text',
    },

    {
        id: 'created_at',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'updated_at',
        numeric: true,
        disablePadding: false,
        label: 'Last Update',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];

export const dataFieldsForAllMenus = [
    // 'id',
    'menu_group_name',
    'created_at',
    'updated_at',
    'active',
];
