export const HeadCellsTax = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'totalOrders',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Orders',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalTax',
        numeric: true,
        disablePadding: false,
        label: 'Taxes Amount',
        sort: true,
        type: 'number',
    },
];
export const HeadCellsTimeCard = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_email',
        numeric: false,
        disablePadding: true,
        label: 'User Email',
        sort: true,
        type: 'text',
    },

    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'clock_in_time',
        numeric: false,
        disablePadding: false,
        label: 'Clock in Time',
        sort: true,
        type: 'number',
    },
    {
        id: 'clock_out_time',
        numeric: true,
        disablePadding: false,
        label: 'Clock Out Time',
        sort: true,
        type: 'number',
    },
    {
        id: 'hours',
        numeric: false,
        disablePadding: false,
        label: 'Total Hours',
        sort: true,
        type: 'text',
    },
];
export const HeadCellsTipCard = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_email',
        numeric: false,
        disablePadding: true,
        label: 'User Email',
        sort: true,
        type: 'text',
    },
    {
        id: 'total_tip',
        numeric: true,
        disablePadding: false,
        label: 'Total Tip',
        sort: true,
        type: 'text',
    },
];
export const HeadCellsClockRequestCard = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'First Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: false,
        label: 'Last Name',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'number',
    },
    {
        id: 'user_email',
        numeric: false,
        disablePadding: true,
        label: 'User Email',
        sort: true,
        type: 'text',
    },
    {
        id: 'date_clock',
        numeric: false,
        disablePadding: false,
        label: 'Date',
        sort: true,
        type: 'text',
    },
    {
        id: 'clock_in_time',
        numeric: false,
        disablePadding: false,
        label: 'Clock In Time',
        sort: true,
        type: 'text',
    },
    // {
    //     id: 'clock_out_time',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'ClockOut Time',
    //     sort: false,
    //     type: 'text',
    // },
    // {
    //     id: 'auto_clock_out',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Total Tip',
    //     sort: false,
    //     type: 'text',
    // },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
];

export const HeadCellsSale = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'totalSubTotal',
        numeric: false,
        disablePadding: false,
        label: 'Subtotal',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalTax',
        numeric: false,
        disablePadding: false,
        label: 'Tax',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalTip',
        numeric: false,
        disablePadding: false,
        label: 'Tip',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalDeliveryFee',
        numeric: false,
        disablePadding: false,
        label: 'Delivery Fee',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalGiftCard',
        numeric: false,
        disablePadding: false,
        label: 'GiftCard',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalCouponCode',
        numeric: false,
        disablePadding: false,
        label: 'Coupon Code',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalSales',
        numeric: true,
        disablePadding: false,
        label: 'Total',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsProduct = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'product_name',
        numeric: false,
        disablePadding: false,
        label: 'Product',
        sort: true,
        type: 'text',
    },
    {
        id: 'qyt',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Sales',
        sort: true,
        type: 'number',
    },
    {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: 'Sales Total',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsDiscount = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'totalUsed',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Discounts Used',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalDiscount',
        numeric: true,
        disablePadding: false,
        label: 'Total Amount',
        sort: true,
        type: 'number',
    },
];

export const HeadCellsDelivery = [
    {
        id: 'id',
        numeric: true,
        disablePadding: true,
        label: '#',
        sort: true,
        type: 'text',
    },
    {
        id: 'location_name',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        sort: true,
        type: 'text',
    },
    {
        id: 'deliveries',
        numeric: false,
        disablePadding: false,
        label: 'Qty of Deliveries',
        sort: true,
        type: 'number',
    },
    {
        id: 'fuelCharge',
        numeric: true,
        disablePadding: false,
        label: 'Fuel Expenses, gallons',
        sort: true,
        type: 'number',
    },
    {
        id: 'totalDeliveryAmount',
        numeric: true,
        disablePadding: false,
        label: 'Total Expenses Amount',
        sort: true,
        type: 'number',
    },
];

export const dataFieldsTax = ['id', 'location_name', 'totalOrders', 'totalTax'];

export const dataFieldsSale = [
    'id',
    'location_name',
    'totalSubTotal',
    'totalTax',
    'totalTip',
    'totalDeliveryFee',
    'totalGiftCard',
    'totalCouponCode',
    'totalSales',
];

export const dataFieldsProduct = ['id', 'product_name', 'qyt', 'price'];

export const dataFieldsDiscount = ['id', 'location_name', 'totalUsed', 'totalDiscount'];

export const dataFieldsDelivery = [
    'id',
    'location_name',
    'deliveries',
    'fuelCharge',
    'totalDeliveryAmount',
];

export const dataFieldsTimeCard = [
    'first_name',
    'last_name',
    'user_phone',
    'user_email',
    'location_name',
    'clock_in_time',
    'clock_out_time',
    'hours',
];
export const dataFieldsTipCard = [
    'first_name',
    'last_name',
    'user_phone',
    'user_email',
    'total_tip',
];
export const dataFieldsClockRequestCard = [
    'first_name',
    'last_name',
    'user_phone',
    'user_email',
    'date_clock',
    'clock_in_time',
    'location_name',
];
