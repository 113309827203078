/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';

import OrderType from './OrderType';
import { Button } from '@/components/Elements';
import { handleDateFormat } from '@/utils/commonHelperFunc';

function ShowUsers({
    usersData,
    showList,
    closeDialog,
    handleSelected,
    SelectedData,
    handleOrder,
    orderData,
    showNewOrder,
    setOrderData,
    setSelectedSlot,
    selectedSlot,
}: any) {
    const [list, setList] = useState<any>(usersData);

    useEffect(() => {
        setList(usersData);
    }, [usersData]);
    return (
        <>
            {!SelectedData ? (
                <>
                    <ul
                        className="showuser-list"
                        style={{
                            width: '100%',
                            maxHeight: '40vh',
                            padding: '4px',
                            margin: '0 auto',
                            border: '1px solid #A3BED9',
                            overflow: 'auto',
                            marginBottom: '10px',
                            borderRadius: '6px',
                            boxShadow:
                                '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.06)',
                        }}
                    >
                        {showList ? (
                            list.length > 0 ? (
                                list.map((user: any, index: number) => {
                                    const { first_name, last_name, user_creation_date } = user;
                                    return (
                                        <li key={index} onClick={() => handleSelected(user)}>
                                            {`${first_name} ${last_name} - ${handleDateFormat(
                                                user_creation_date
                                            )}`}
                                        </li>
                                    );
                                })
                            ) : (
                                <li style={{ background: '#fff' }}>not found</li>
                            )
                        ) : null}
                    </ul>
                </>
            ) : (
                <div className="view-user-details">
                    <div className="info-header">
                        <div className="left-info">
                            <i className="far  fa-user" style={{ marginRight: '8px' }}></i>
                            <h4>
                                {SelectedData.first_name} {SelectedData.last_name}
                            </h4>
                        </div>
                    </div>
                    <ul className="user-details-list">
                        <li>
                            <strong>Birthday:</strong>
                            <span>{handleDateFormat(SelectedData.user_birthday)}</span>
                        </li>
                        <li>
                            <strong>Phone:</strong> <span>{SelectedData.user_phone}</span>
                        </li>
                        <li>
                            <strong>Email:</strong> <span>{SelectedData.user_email}</span>
                        </li>
                        <li>
                            <strong>Created:</strong>
                            <span>{handleDateFormat(SelectedData.user_creation_date)}</span>
                        </li>
                    </ul>
                    <hr />
                    <OrderType
                        handleOrder={handleOrder}
                        orderData={orderData}
                        setOrderData={setOrderData}
                        setSelectedSlot={setSelectedSlot}
                        selectedSlot={selectedSlot}
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1rem 0',
                        }}
                    >
                        <Button
                            style={{ width: 'fit-content' }}
                            data-bs-dismiss="modal"
                            onClick={() => showNewOrder(true)}
                            disabled={
                                !selectedSlot?.time
                                // && !(orderData.order_type_id === '18'
                                //     ? !!orderData.store && !!orderData.address
                                //     : true)
                            }
                        >
                            Continue
                        </Button>
                        <Button
                            onClick={() => handleSelected(false)}
                            style={{ width: 'fit-content' }}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShowUsers;
