import { useQuery } from 'react-query';

import { Permission } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';


export const getAllPermission = async (): Promise<Permission[]> => {
    const { data } = await axios.get(`v1/get-admins`);
    return data.admins;
};

type QueryFnType = typeof getAllPermission;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const usePermission = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['permission'],
        queryFn: () => getAllPermission(),
    });
};
