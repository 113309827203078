import { Suspense, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { Corporations } from '@/features/corporation/routes';
import { PosDevices } from '@/features/pos_devices/routes';
import { LoyaltyPage } from '@/features/loyalty/routes';
import { CouponPage } from '@/features/coupon/routes';
import { Orders } from '@/features/orders/routes';
import { lazyImport } from '@/utils/lazyImport';
import { Menus } from '@/features/menus/routes';
import { Delivery } from '@/features/delivery/routes';
import { GiftCardPage } from '@/features/giftcards/routes';
import { PermissionPage } from '@/features/permissions/routes';
import { WebEditorPage } from '@/features/webeditor/routes';
import { Inquiries } from '@/features/inquiries/routes';
import { ReportPage } from '@/features/reports/routes';
import Alert from '@/features/alert/routes';

const { DiscussionsRoutes } = lazyImport(
    () => import('@/features/discussions'),
    'DiscussionsRoutes'
);
//const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { Locations } = lazyImport(() => import('@/features/locations'), 'Locations');
const { Employees } = lazyImport(() => import('@/features/employees'), 'Employees');
const { Products } = lazyImport(() => import('@/features/products'), 'Products');
// const { Delivery } = lazyImport(() => import('@/features/elivery'), 'Delivery');

const App = () => {
    const ls_user_details: any = localStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(ls_user_details);

    const location = window.location.pathname;
    useEffect(() => {
        if (parsedUserDetails?.role_name === 'General Manager' && location === '/app/users') {
            window.location.href = '/app/employees';
        }
    }, [location, parsedUserDetails?.role_name]);
    return (
        <MainLayout>
            <Suspense
                fallback={
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                }
            >
                <Outlet />
            </Suspense>
        </MainLayout>
    );
};

const ls_user_details: any = localStorage.getItem('userDetails');
const parsedUserDetails = JSON.parse(ls_user_details);
export const protectedRoutes = [
    {
        path: '/app',
        element: <App />,
        children: [
            parsedUserDetails?.role_name !== 'General Manager' && {
                path: '/app/users',
                element: <Users />,
            },
            //{ path: '/app/profile', element: <Profile /> },
            {
                path: '/app',
                element: (
                    <Navigate
                        to={
                            parsedUserDetails?.role_name === 'General Manager'
                                ? '/app/employees'
                                : '/app/users'
                        }
                    />
                ),
            },
            {
                path: '/app/*',
                element: (
                    <Navigate
                        to={
                            parsedUserDetails?.role_name === 'General Manager'
                                ? '/app/employees'
                                : '/app/users'
                        }
                    />
                ),
            },
            { path: '/app/employees', element: <Employees /> },
            { path: '/app/products', element: <Products /> },
            { path: '/app/corporations', element: <Corporations /> },
            { path: '/app/locations', element: <Locations /> },
            { path: '/app/menus', element: <Menus /> },
            { path: '/app/delivery', element: <Delivery /> },
            { path: '/app/orders', element: <Orders /> },
            { path: '/app/loyalty', element: <LoyaltyPage /> },
            { path: '/app/coupon-codes', element: <CouponPage /> },
            { path: '/app/gift-cards', element: <GiftCardPage /> },
            { path: '/app/inquiries', element: <Inquiries /> },
            { path: '/app/reports', element: <ReportPage /> },
            { path: '/app/permissions', element: <PermissionPage /> },
            { path: '/app/web-editor', element: <WebEditorPage /> },
            { path: '/app/scheduled-activities', element: './scheduled-activities' },
            { path: '/app/pos-devices', element: <PosDevices /> },
            { path: '/app/alert', element: <Alert /> },
        ],
    },
];
