import { axios } from '@/lib/axios';

export const getProductsByCategory = async (id: number) => {
    const { data } = await axios.get(`v1/get-products-by-category/${id}`);
    return data.products;
};

export const getLocaionProductsByCategory = async (id: number) => {
    const { data } = await axios.get(`v1/get-products-by-menu/${id}`);
    return data.products;
};

export const getProductsByType = async (id: number) => {
    const { data } = await axios.get(`v1/get-products-by-type/${id}`);
    return data?.products;
};

export const getProductType = async () => {
    const { data } = await axios.get(`v1/get-product-type`);
    return data?.categories;
};

export const getProducts = async () => {
    const { data } = await axios.get(`v1/get-products`);
    return data?.products;
};
