import { AddCircleOutline } from '@mui/icons-material';
import React, { useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { createCorp } from '../api/CRUD/create';

const schema = z.object({
    corporate_name: z.string().nonempty('Please enter first name'),
    corporate_email: z.string().email({ message: 'Please enter a valid email' }),
    corporate_phone: z.string().nonempty('Please enter last name'),
});

type LoginValues = {
    corporate_name: string;
    corporate_email: string;
    corporate_phone: string;
};

const CreateCorporation = ({ closeDialog }) => {
    const intialCorpData = {
        corporate_name: '',
        corporate_email: '',
        corporate_phone: '',
    };
    const [data, setData] = useState(intialCorpData);

    const handleSubmit = async () => {
        createCorp(data).then().catch();
        closeDialog();
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="invoiceModalLabel">
                        Create Corporation
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="add-user-form">
                        <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label htmlFor="cname" className="control-label">
                                                Corporation Name
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    name="corporate_name"
                                                    placeholder="Richard Brenson"
                                                    value={data.corporate_name}
                                                    error={formState.errors['corporate_name']}
                                                    registration={register('corporate_name')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="umail" className="control-label">
                                                Corporation Email
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="corporate_email"
                                                    placeholder="richbrenson@gmail.com"
                                                    value={data.corporate_email}
                                                    onChange={handleChange}
                                                    error={formState.errors['corporate_email']}
                                                    registration={register('corporate_email')}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="account" className="control-label">
                                                Corporation Phone
                                            </label>
                                            <div className="form-group">
                                                <InputField
                                                    className="form-control"
                                                    name="corporate_phone"
                                                    placeholder="33679936"
                                                    value={data.corporate_phone}
                                                    onChange={handleChange}
                                                    error={formState.errors['corporate_phone']}
                                                    registration={register('corporate_phone')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            startIcon={<AddCircleOutline />}
                                            style={{ width: 'fit-content' }}
                                        >
                                            Add Corporation
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCorporation;
