import { useQuery } from 'react-query';

import { SalesReport } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type TGetSalesReport = {
    startDate?: string | null;
    endDate?: string | null;
    duration?: string | null;
    location_id?: number | null;
};
export const getSalesReport = async (params?: TGetSalesReport): Promise<SalesReport[]> => {
    const { data } = await axios.get(
        `v1/get-sales-report${
            params?.location_id?.toString() && params?.duration
                ? '?duration=' + params?.duration + '&location_id=' + params?.location_id
                : params?.duration
                ? '?duration=' + params?.duration
                : params
                ? '?startDate=' + params?.startDate + '&endDate=' + params?.endDate
                : ''
        }`
    );
    return data;
};

type QueryFnType = typeof getSalesReport;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useSalesReport = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['salesReport'],
        queryFn: () => getSalesReport(),
    });
};
