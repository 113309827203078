import { useQuery } from 'react-query';

import { corporation } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';


export const getAllCorps = async (): Promise<corporation[]> => {
    const { data } = await axios.get(`v1/get-corporations/all`);
    return data.corporations;
};

type QueryFnType = typeof getAllCorps;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useCorps = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['corps'],
        queryFn: () => getAllCorps(),
    });
};
