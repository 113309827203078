import React, { useEffect, useState } from 'react';
import { boolean } from 'zod';

import editCheck from '../../../assets/edit-check.png';
import { Button, Spinner } from '@/components/Elements';
import { getStores } from '@/features/orders/api/getStores';
import { useUsers } from '@/features/users/api/getUsers';
import { axios } from '@/lib/axios';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';

const AddCredit = ({ corpData, setAddCredit, closeDialog }) => {
    const { user } = useAuth();

    const [creditAmount, setCreditAmount] = useState('');

    // function to get cent price
    const toCent = (amount) => {
        const cents = amount * 100;
        return Number(cents);
    };

    ////////////

    const handleSubmit = async () => {
        const updateData = {
            corporate_id: corpData?.id,
            admin_id: user?.data?.id,
            applied_credits: toCent(creditAmount),
        };

        await axios.post(`v1/add-credit-points/`, updateData);

        showSuccess();
        closeDialog();
        setAddCredit(null);
    };

    const showSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Corporation Credited Successfully!',
        });
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Add Credit
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => {
                            closeDialog();
                            setAddCredit(null);
                        }}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="col-md-12">
                        <label htmlFor="credit" className="control-label">
                            Enter The Credit Amount
                        </label>
                        <div className="form-group">
                            <input
                                type="text"
                                name="credit"
                                className="form-control"
                                placeholder="$100"
                                value={creditAmount}
                                onChange={(e) => {
                                    setCreditAmount(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        type="submit"
                        startIcon={<img src={editCheck} alt="editCheck" />}
                        style={{ width: 'fit-content' }}
                        onClick={() => handleSubmit()}
                    >
                        Add Credits
                    </Button>
                    <Button
                        variant="secondary"
                        style={{ width: 'fit-content' }}
                        onClick={() => {
                            closeDialog();
                            setAddCredit(null);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddCredit;
