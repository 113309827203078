import { AddCircleOutlineOutlined, Person, PersonAddAltOutlined } from '@mui/icons-material';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import React, { useEffect, useState } from 'react';

import { assignAsEmployee } from '../api/assignAsEmployee';
import { Employee_roles } from '../constant';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { handleDateFormat } from '@/utils/commonHelperFunc';
import { useNotificationStore } from '@/stores/notifications';

function ShowUsers({
    getEmployees,
    usersData,
    showList,
    closeDialog,
    stores,
    getSelectedUser,
    hideAdditionalDetails = false,
}: any) {
    const ls_user_details: any = localStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(ls_user_details);
    const [list, setList] = useState<string>(usersData);
    const [selectedUser, setSelectedUser] = useState<any>(false);
    const [userRoleId, setUserRoleId] = useState<number | boolean>(false);
    const [assignedStores, setAssignedStores] = useState<any>([{ store: '' }]);
    useEffect(() => {
        setList(usersData);
    }, [usersData]);
    const handleStoreValue = (index, value) => {
        const changeArray = assignedStores;
        changeArray[index].store = value;
        setAssignedStores(changeArray);
    };
    //const handleAddMore = () => {
    //assignedStores?.pop()?.store === '';
    //return false;
    //};
    const validation = () => {
        if (!userRoleId) {
            useNotificationStore.getState().addNotification({
                type: 'error',
                title: 'Error',
                message: 'Please select role.',
            });
            return false;
        } else if (!assignedStores[0]?.store) {
            useNotificationStore.getState().addNotification({
                type: 'error',
                title: 'Error',
                message: 'Please select store.',
            });
            return false;
        } else return true;
    };
    const handleSubmit = () => {
        const isValid = validation();
        if (isValid) {
            assignAsEmployee({
                user_id: selectedUser.id,
                location_id: assignedStores,
                role_id: userRoleId,
            }).then((res) => {
                getEmployees && getEmployees();
            });
            closeDialog();
        }
    };
    return (
        <>
            {!selectedUser ? (
                <>
                    <ul
                        className="showuser-list"
                        style={{
                            width: '100%',
                            maxHeight: '50vh',
                            boxShadow:
                                '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.06)',
                            padding: '4px',
                            margin: '0 auto',
                            border: '1px solid #A3BED9',
                            overflow: 'auto',
                            marginBottom: '10px',
                            borderRadius: '6px',
                        }}
                    >
                        {showList ? (
                            list.length > 0 ? (
                                list.map((user) => {
                                    const { id, first_name, last_name, user_creation_date } = user;
                                    return (
                                        <li
                                            key={id}
                                            onClick={() => {
                                                setSelectedUser(user);
                                                getSelectedUser(user);
                                            }}
                                        >
                                            {`${first_name} ${last_name} - ${handleDateFormat(
                                                user_creation_date
                                            )}`}
                                        </li>
                                    );
                                })
                            ) : (
                                <li style={{ background: '#fff' }}>not found</li>
                            )
                        ) : null}
                    </ul>
                    {showList && list.length == 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '1rem 0',
                            }}
                        >
                            <Button
                                startIcon={<PersonAddAltOutlined />}
                                style={{ width: 'fit-content' }}
                            >
                                Add new user
                            </Button>
                            <Button
                                style={{ width: 'fit-content' }}
                                variant="secondary"
                                onClick={() => closeDialog()}
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className="view-user-details" style={{ padding: '0px' }}>
                    <div className="info-header" style={{ paddingTop: '10px' }}>
                        <div className="left-info">
                            <i className="far fa-user"></i>
                            <h4 style={{ marginLeft: '10px', fontWeight: '500' }}>
                                {selectedUser.first_name} {selectedUser.last_name}
                            </h4>
                        </div>
                    </div>
                    {hideAdditionalDetails ? null : (
                        <>
                            <ul className="user-details-list" style={{ paddingBottom: '10px' }}>
                                <li>
                                    <strong>Birthday:</strong>
                                    <span>{handleDateFormat(selectedUser.user_birthday)}</span>
                                </li>
                                <li>
                                    <strong>Corporation:</strong>
                                    <span className="dark-tx" style={{ fontWeight: '500' }}>
                                        {selectedUser.user_company}
                                    </span>
                                </li>
                                <li>
                                    <strong>Email:</strong> <span>{selectedUser.user_email}</span>
                                </li>
                                <li>
                                    <strong>Created:</strong>
                                    <span>{handleDateFormat(selectedUser.user_creation_date)}</span>
                                </li>
                                <li>
                                    <strong>Phone:</strong> <span>{selectedUser.user_phone}</span>
                                </li>
                                <li>
                                    <strong>Active:</strong>
                                    <span>{selectedUser.user_active ? 'Yes' : 'No'}</span>
                                </li>
                            </ul>
                            <div className="form-group border-bottom">
                                <label
                                    htmlFor="role"
                                    style={{
                                        color: 'rgba(17, 17, 17, 0.64)',
                                        fontSize: '13px',
                                        fontWeight: '500',
                                    }}
                                >
                                    Role
                                </label>
                                <select
                                    className="form-control"
                                    name="role"
                                    required
                                    onChange={(e) => setUserRoleId(parseInt(e.target.value))}
                                >
                                    <option value="">Select Role</option>
                                    {Employee_roles.map(({ id, role_name }) => {
                                        if (
                                            parsedUserDetails?.role_name == 'General Manager' &&
                                            role_name === 'Administrator'
                                        )
                                            return;
                                        else {
                                            return (
                                                <option value={id} key={id}>
                                                    {role_name}
                                                </option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            {assignedStores.map((store, index) => (
                                <div
                                    className="form-group"
                                    key={index}
                                    style={{ paddingTop: '0px' }}
                                >
                                    <label
                                        htmlFor="store"
                                        style={{
                                            color: 'rgba(17, 17, 17, 0.64)',
                                            fontSize: '13px',
                                            fontWeight: '500',
                                        }}
                                    >
                                        Store Assigned
                                    </label>
                                    <div style={{ display: 'flex' }}>
                                        <select
                                            className="form-control"
                                            name="store"
                                            required
                                            onChange={(e) =>
                                                handleStoreValue(index, e.target.value)
                                            }
                                            disabled={assignedStores[index].store !== ''}
                                        >
                                            <option value="">Select Store</option>
                                            {stores &&
                                                stores?.map(({ id, location_name }) => (
                                                    <option
                                                        key={id}
                                                        value={id}
                                                        style={{
                                                            display:
                                                                parsedUserDetails?.role_name ==
                                                                'General Manager'
                                                                    ? parsedUserDetails.location_id ==
                                                                      id
                                                                        ? 'block'
                                                                        : 'none'
                                                                    : 'block',
                                                        }}
                                                    >
                                                        {location_name}
                                                    </option>
                                                ))}
                                        </select>
                                        {assignedStores.length > 1 && (
                                            <span
                                                className="add-delicon"
                                                onClick={() =>
                                                    setAssignedStores(assignedStores.splice(index))
                                                }
                                            >
                                                <Delete className="pColor" />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '20px 0 0 0',
                                    borderTop: '1px solid #dee2e6',
                                    marginTop: '25px',
                                }}
                            >
                                <Button
                                    startIcon={<AccountBoxOutlinedIcon />}
                                    style={{ width: 'fit-content' }}
                                    data-bs-dismiss="modal"
                                    onClick={handleSubmit}
                                >
                                    Assign Employee
                                </Button>
                                <Button
                                    onClick={() => setSelectedUser(false)}
                                    style={{ width: 'fit-content' }}
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
}

export default ShowUsers;
