import { axios } from '@/lib/axios';

export const updateCorp = async (id: number, isActive: boolean, notes: string) => {
    if (notes != '') {
        const { success, message } = await axios.post(`v1/update-corporation/${id}`, {
            corporate_active: isActive,
            notes: notes,
        });
        return { success, message };
    } else {
        const { success, message } = await axios.post(`v1/update-corporation/${id}`, {
            corporate_active: isActive,
        });

        return { success, message };
    }
};
