import { useQuery } from 'react-query';

import { ProductReport } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type TGetProductReport = {
    startDate?: string | null;
    endDate?: string | null;
    duration?: string | null;
    location_id?: number | null;
};

export const getProductReport = async (params?: TGetProductReport): Promise<ProductReport[]> => {
    const { data } = await axios.get(
        `v1/get-product-report${
            params?.location_id?.toString() && params?.duration
                ? '?location_id=' + params?.location_id + '&duration=' + params?.duration
                : params?.location_id?.toString() && params
                ? '?location_id=' +
                  params?.location_id +
                  '&startDate=' +
                  params?.startDate +
                  '&endDate=' +
                  params?.endDate
                : params?.duration
                ? '?duration=' + params?.duration
                : params
                ? '?startDate=' + params?.startDate + '&endDate=' + params?.endDate
                : ''
        }`
    );
    return data;
};

type QueryFnType = typeof getProductReport;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useProductReport = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['productReport'],
        queryFn: () => getProductReport(),
    });
};
