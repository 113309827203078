import { axios } from '@/lib/axios';

type CreateCorp = {
    corporate_name: string;
    corporate_email: string;
    corporate_phone: string;
};

export const createCorp = async (createCorp: CreateCorp) => {
    const { success, message } = await axios.post(`v1/create-corporation`, createCorp);
    return { success, message };
};
