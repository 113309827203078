/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { updateAlertApi } from '../api/alert';
import { ContentLayout } from '@/components/Layout';
import { Spinner } from '@/components/Elements';

function Alert() {
    const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState<string | undefined>();
    const handleUpdateAlert = async () => {
        try {
            setIsLoading(true);
            await updateAlertApi({ active: isSwitchOn, message });
        } catch (e) {
            console.log('error from update alert', e);
        } finally {
            setIsLoading(false);
        }
    };
    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }
    return (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                background: isSwitchOn ? 'rgb(255 137 127)' : '#fff',
            }}
        >
            <ContentLayout
                title=""
                topNavBarData={{
                    num: 1,
                    title: 'Alert',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '450px',
                            border: '1px solid #DCE7F9',
                            borderRadius: '10px',
                            padding: '20px',
                        }}
                    >
                        <input
                            defaultValue={message}
                            onChange={(e) => setMessage(e?.target?.value)}
                            type="text"
                            style={{
                                height: '50px',
                                width: '100%',
                                outline: 'none',
                                borderRadius: '10px',
                            }}
                            placeholder="Alert Text"
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                onClick={() => setIsSwitchOn((prev) => !prev)}
                                style={{
                                    cursor: 'pointer',
                                    width: '60px',
                                    height: '28px',
                                    background: isSwitchOn ? 'lightGreen' : 'grey',
                                    borderRadius: '15px',
                                    position: 'relative',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '24px',
                                        width: '24px',
                                        borderRadius: '50%',
                                        background: '#fff',
                                        top: '50%',
                                        left: isSwitchOn ? '' : '2px',
                                        right: isSwitchOn ? '2px' : '',
                                        transform: 'translateY(-50% )',
                                        transition: 'right 2s',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        transform: 'translateY(-50% )',
                                        left: isSwitchOn ? 3 : '',
                                        right: isSwitchOn ? '' : 3,
                                        color: '#fff',
                                        fontSize: '14px',
                                    }}
                                >
                                    {isSwitchOn ? 'ON' : 'OFF'}
                                </div>
                            </div>
                            <button
                                style={{
                                    maxWidth: '130px',
                                    cursor: 'pointer',
                                    background: '#018749',
                                }}
                                onClick={() => handleUpdateAlert()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '15px',
                        fontWeight: '600',
                    }}
                >
                    PLEASE NOTE: WHEN YOU TURN THIS ALERT ON, IT WILL SHOW ON BOTH THE WEBSITE AND
                    THE MOBILE APP. PLEASE CHECK THE TEXT BEFORE SAVING ALERT
                </div>
            </ContentLayout>
        </div>
    );
}

export default Alert;
