import { AddCircleOutline } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { axios } from '@/lib/axios';

const schema = z.object({
    location_id: z.string().nonempty('Please enter location'),
    serial_number: z.string().nonempty({ message: 'Please enter a valid serial number' }),
    name: z.string().nonempty('Please enter device name'),
    app_version: z.string().nonempty('Please enter app version'),
});

type LoginValues = {
    location_id: string;
    serial_number: string;
    name: string;
    app_version: string;
};


const CreatePos = ({ closeDialog, posQuery }) => {
    const intialPosData = {
        location_id: '',
        serial_number: '',
        name: '',
        app_version: '1',
    };
    const [data, setData] = useState(intialPosData);
    const [locations, setLocations] = useState();



    useEffect(() => {
        getAllLocations();

    }, [])



    //get all locations
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/get-all-locations`);
        setLocations(data.locations);
    };

    //////


    const createPos = async (data) => {
        const { success, message } = await axios.post(`v1/add-pos-devices`, data);
        posQuery.refetch();
        return { success, message };
    }

    const handleSubmit = async () => {
        createPos(data).then(() => closeDialog()).catch();

    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    return (
        locations == null ?
            <>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="h-full w-full flex items-center justify-center">
                            <Spinner size="xl" />
                        </div>
                    </div>
                </div>
            </>
            :
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="invoiceModalLabel">
                            New POS Device
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeDialog()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="add-user-form">
                            <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
                                {({ register, formState }) => (
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="serial_number" className="control-label">
                                                    Serial Number
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        onChange={handleChange}
                                                        className="form-control"
                                                        name="serial_number"
                                                        placeholder="GHJ7489K"
                                                        value={data.serial_number}
                                                        error={formState.errors['serial_number']}
                                                        registration={register('serial_number')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="name" className="control-label">
                                                    Device Name
                                                </label>
                                                <div className="form-group">
                                                    <InputField
                                                        className="form-control"
                                                        name="name"
                                                        placeholder="richbrenson@gmail.com"
                                                        value={data.name}
                                                        onChange={handleChange}
                                                        error={formState.errors['name']}
                                                        registration={register('name')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <label htmlFor="location_id" className="control-label">
                                                    Location
                                                </label>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="location_id"
                                                        required
                                                        onChange={handleChange}
                                                        value={data.location_id}
                                                        error={formState.errors['location_id']}
                                                        registration={register('location_id')}
                                                    >
                                                        <option value="">Select or Type</option>
                                                        {locations.map(({ id, location_name }) => (
                                                            <option value={id} key={id}>
                                                                {location_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                        <div
                                            className="modal-footer"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '1rem',
                                            }}
                                        >
                                            <Button
                                                type="submit"
                                                startIcon={<AddCircleOutline />}
                                                style={{ width: 'fit-content' }}
                                                onClick={() => handleSubmit()}
                                            >
                                                Add Device
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                style={{ width: 'fit-content' }}
                                                onClick={() => closeDialog()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default CreatePos;
