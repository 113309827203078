/* eslint-disable jsx-a11y/no-onchange */
import { AddCircleOutline } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { getAllPos, usePos } from '../api/CRUD/getAllPos';
import CreatePos from '../components/create';
import EditPos from '../components/edit';
import ViewPosDevice from '../components/view';
import { dataFields, HeadCells } from '../constant';
import { Pos, User } from '../type';
import { useNotificationStore } from '@/stores/notifications';
import { useDisclosure } from '@/hooks/useDisclosure';
import { ContentLayout } from '@/components/Layout';
import TableWithSort from '@/components/Elements/Table/TableWithSort';
import CustomTable from '@/components/Elements/Table/CustomTable';
import { Confirm } from '@/components/Elements/Dialog/Confirm';
import { Button, Dialog, Spinner } from '@/components/Elements';
//import { HeadCells } from '@/features/users/constant';

import '../styles/styles.css';
import { axios } from '@/lib/axios';

export const PosDevices = () => {
    const { close, open, isOpen } = useDisclosure();
    const { addNotification } = useNotificationStore();
    const posQuery = usePos();
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pos, setPos] = useState<Pos[]>([]);
    const [createPos, setCreatePos] = useState<boolean>(false);
    const [editPos, setEditPos] = useState<Pos | null>(null);
    const [viewPosDevice, setviewPosDevice] = useState<Pos | null>(null);
    const [locationFilter, setLocationFilter] = useState();
    const [confirmMsg, setConfirmMsg] = useState<User | any>(null);
    const [userDetails, setUserDetails] = useState<any>({});

    const handleSearch = (e: any) => {
        setSearchVal(e.target.value);
    };

    async function getPos() {
        setIsLoading(true);
        const res = await getAllPos();
        setPos(res);
        setIsLoading(false);
    }
    useEffect(() => {
        const ls_user_details: any = localStorage.getItem('userDetails');
        setUserDetails(JSON.parse(ls_user_details));
    }, []);
    useEffect(() => {
        getPos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posQuery.data, close]);

    if (isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!pos) return null;
    const filteredEmployees = pos
        ?.filter((pos: any) => {
            if (searchVal || locationFilter) {
                if (pos?.location_name === locationFilter) {
                    return pos;
                }
                if (searchVal) {
                    return (
                        pos?.name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                        pos?.location_name?.toLowerCase()?.includes(searchVal.toLowerCase()) ||
                        pos?.serial_number?.toLowerCase()?.includes(searchVal.toLowerCase())
                    );
                }
            } else {
                return pos;
            }
        })
        .filter((el: any) =>
            userDetails?.role_name === 'General Manager' && !!el?.location_id
                ? el?.location_id === userDetails?.location_id
                : el
        );

    const deletePos = async (id: any) => {
        const updateData = {
            device_id: id,
            status: 0,
        };

        const { success, message }: any = await axios.post(`v1/update-pos-devices`, updateData);

        return { success, message };
    };

    const handleDelete = async (posData: Pos) => {
        await deletePos(posData.id).then(() => {
            addNotification({
                type: 'success',
                title: 'Device Deleted',
            });
        });
        posQuery.refetch();
        getPos();
        setConfirmMsg(null);
    };

    const selectedUserAction = ({ action, data }: any) => {
        switch (action) {
            case 'view':
                setEditPos(null);
                setviewPosDevice(data);
                setCreatePos(false);
                open();
                break;
            case 'edit':
                setviewPosDevice(null);
                setEditPos(data);
                setCreatePos(false);
                open();
                break;
            case 'add-user':
                setviewPosDevice(null);
                setEditPos(null);
                setCreatePos(false);
                open();
                break;
            case 'add-invoice':
                setviewPosDevice(null);
                setEditPos(null);
                setCreatePos(false);
                open();
                break;
            case 'delete':
                setConfirmMsg(data);
                break;
            default:
                return null;
        }
    };

    const closeDialog = () => {
        setviewPosDevice(null);
        setEditPos(null);
        setCreatePos(false);
        close();
    };

    const LocationOptions: any = new Set(pos?.map((item) => item.location_name));

    return (
        <ContentLayout title="" topNavBarData={{ num: pos.length, title: 'POS Devices' }}>
            {confirmMsg ? (
                <Confirm
                    btnTitle={'Delete'}
                    onClose={() => setConfirmMsg(null)}
                    onSuccess={() => handleDelete(confirmMsg)}
                />
            ) : null}
            <div className="table-header">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="flex pos-devces">
                                <div className="input-group search">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        value={searchVal}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                                {userDetails?.role_name !== 'General Manager' ? (
                                    <div className="form-group pos-dev">
                                        <select
                                            className="form-control ml-1 w-auto pos-select"
                                            name="location"
                                            value={locationFilter}
                                            onChange={(e: any) => setLocationFilter(e.target.value)}
                                        >
                                            <option value="">Select Location</option>
                                            {[...LocationOptions]?.map((location_name) => (
                                                <option key={location_name} value={location_name}>
                                                    {location_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="add-btn" style={{ float: 'right' }}>
                                <Button
                                    startIcon={<AddCircleOutline />}
                                    onClick={() => (setCreatePos(!createPos), open())}
                                >
                                    Add Device
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <CustomTable
                tableData={filteredEmployees}
                headCells={HeadCells}
                dataFields={dataFields}
                selectedUserAction={selectedUserAction}
                rowOptions={true}
                optionMenu={['edit', 'delete']}
            />
            <Dialog isOpen={isOpen} onClose={closeDialog} paperWidth={'60%'}>
                <div className="modals-contents">
                    {createPos && <CreatePos posQuery={posQuery} closeDialog={closeDialog} />}
                    {viewPosDevice && (
                        <ViewPosDevice
                            posData={viewPosDevice}
                            closeDialog={closeDialog}
                            selectedUserAction={selectedUserAction}
                        />
                    )}
                    {editPos && (
                        <EditPos posQuery={posQuery} posData={editPos} closeDialog={closeDialog} />
                    )}
                </div>
            </Dialog>
        </ContentLayout>
    );
};
