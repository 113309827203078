import React from 'react';

type PropsType = {
    color?: string;
};

const Delete = ({ color = '#526EFF' }: PropsType) => {
    return (
        <>
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M20.1667 4.58335H15.5834V1.83335C15.5834 1.59024 15.4868 1.35708 15.3149 1.18517C15.143 1.01326 14.9099 0.916687 14.6667 0.916687H7.33342C7.0903 0.916687 6.85714 1.01326 6.68523 1.18517C6.51333 1.35708 6.41675 1.59024 6.41675 1.83335V4.58335H1.83341C1.5903 4.58335 1.35714 4.67993 1.18523 4.85184C1.01333 5.02375 0.916748 5.25691 0.916748 5.50002C0.916748 5.74314 1.01333 5.97629 1.18523 6.1482C1.35714 6.32011 1.5903 6.41669 1.83341 6.41669H2.85733L4.59075 20.2804C4.61845 20.502 4.72614 20.7059 4.89358 20.8538C5.06102 21.0017 5.2767 21.0833 5.50008 21.0834H16.5001C16.7235 21.0833 16.9391 21.0017 17.1066 20.8538C17.274 20.7059 17.3817 20.502 17.4094 20.2804L19.1428 6.41669H20.1667C20.4099 6.41669 20.643 6.32011 20.8149 6.1482C20.9868 5.97629 21.0834 5.74314 21.0834 5.50002C21.0834 5.25691 20.9868 5.02375 20.8149 4.85184C20.643 4.67993 20.4099 4.58335 20.1667 4.58335ZM8.25008 2.75002H13.7501V4.58335H8.25008V2.75002ZM15.6907 19.25H6.3095L4.70533 6.41669H17.2948L15.6907 19.25Z"
                    fill={color}
                />
            </svg>
        </>
    );
};

export default Delete;
