import { useQuery } from 'react-query';

import { TaxReport } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

type TGetTaxReport = {
    startDate?: string | null;
    endDate?: string | null;
    duration?: string | null;
};
export const getTaxReport = async (params?: TGetTaxReport): Promise<TaxReport[]> => {
    const { data } = await axios.get(
        `v1/get-tax-report${
            params?.duration
                ? '?duration=' + params?.duration
                : params
                ? '?startDate=' + params?.startDate + '&endDate=' + params?.endDate
                : ''
        }`
    );
    return data;
};

type QueryFnType = typeof getTaxReport;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useTaxReport = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['taxReport'],
        queryFn: () => getTaxReport(),
    });
};
