import { EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import moment from 'moment';
import { Coupon } from '../type';
import Delete from '@/components/Icons/Delete';
import { Button } from '@/components/Elements';

const ViewCoupon = ({ couponData, closeDialog, selectedUserAction }: any) => {
    const [data, setData] = useState<Coupon | any>(null);

    useEffect(() => {
        setData(couponData);
    }, [couponData]);

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Coupon - {data?.coupon_code}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '5px', paddingBottom: '22px' }}
                        >
                            <div className="left-info">
                                <LocalOfferOutlinedIcon
                                    className="pColor"
                                    style={{ transform: 'rotate(87deg)' }}
                                />
                                <h4>{data?.coupon_code}</h4>
                            </div>
                        </div>
                        <div
                            className="info-header "
                            style={{ paddingTop: '15px', paddingBottom: '15px' }}
                        >
                            <div className="left-info">
                                <h4>Details</h4>
                            </div>
                            <div className="left-info">
                                <LanguageIcon className="pColor" />
                                <strong>{data?.coupon_code__type_name}</strong>
                            </div>
                        </div>
                        <ul
                            className="user-details-list coupon-view"
                            style={{ paddingBottom: '1px' }}
                        >
                            <li>
                                <strong>Coupon Type:</strong>{' '}
                                <span>
                                    {data?.coupon_code__type_name}&nbsp;
                                    {data?.coupon_code__type_name === 'Location' &&
                                    data?.location_name
                                        ? `(${data?.location_name})`
                                        : ''}
                                    {data?.coupon_code__type_name === 'User' && data?.first_name
                                        ? `(${data?.first_name} ${
                                              data?.last_name ? data?.last_name : ''
                                          })`
                                        : ''}
                                    {data?.coupon_code__type_name === 'Mode' && data?.mode_name
                                        ? `(${data?.mode_name})`
                                        : ''}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction Type:</strong> <span>{data?.deduction_type}</span>
                            </li>
                            <li>
                                <strong>Activation Date:</strong>{' '}
                                <span>
                                    {moment(data?.coupon_activation_time).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction&nbsp;Min&nbsp;Order:</strong>

                                <span>&nbsp; ${(data?.deduction_min_cart / 100).toFixed(2)}</span>
                            </li>
                            <li>
                                <strong>Expiration Date:</strong>
                                <span>
                                    {moment(data?.coupon_expiration_time).format(
                                        'MM/DD/YYYY, HH:mm A'
                                    )}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction&nbsp;Max&nbsp;Value:</strong>

                                <span>&nbsp; ${(data?.deduction_max_dollar / 100).toFixed(2)}</span>
                            </li>
                            <li>
                                <strong>Deduction Amount:</strong>

                                <span>
                                    {data?.deduction_type === 'percent'
                                        ? `${data?.deduction_amount}%`
                                        : `$${(data?.deduction_amount / 100).toFixed(2)}`}
                                </span>
                            </li>
                            <li>
                                <strong>Deduction Mode:</strong>
                                <span>{data?.deduction_mode}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="modal-body">
                    <div className="view-user-details">
                        <div
                            className="info-header"
                            style={{ paddingTop: '0px', paddingBottom: '5px' }}
                        >
                            <div className="left-info">
                                <h4 className="pb-2"> Advanced Options</h4>
                            </div>
                        </div>
                        <ul
                            className="user-details-list  coupon-view"
                            style={{ paddingBottom: '10px' }}
                        >
                            <li>
                                <strong>Uses:</strong> <span>{data?.coupon_code_uses || 0}</span>
                            </li>
                            <li>
                                <strong>Budget:</strong> <span>$ {data?.coupon_budget || 0}</span>
                            </li>
                            <li>
                                <strong>Used:</strong> <span>{data?.coupon_code_used || 0}</span>
                            </li>
                            <li>
                                <strong>Budget Remaining:</strong>

                                <span>$ {data?.coupon_budget - data?.coupon_budget_used}</span>
                            </li>
                            <li>
                                <strong>Always Available:</strong>
                                <span>
                                    {data?.coupon_days.split('').length == 7 ? 'Yes' : 'No'}
                                </span>
                            </li>
                        </ul>
                    </div>

                    {data?.coupon_days.split('').length != 7 && (
                        <div className="flex gap-3 pt-2">
                            <div>
                                <strong>Active Time:</strong>
                            </div>
                            <div>
                                <p>
                                    {data?.coupon_days_start} - {data?.coupon_days_end}
                                </p>
                                <ul className="flex gap-3">
                                    {data?.coupon_days.split('').map((i: any, index: number) => (
                                        <li key={index}>
                                            <DoneOutlinedIcon className="pColor" />
                                            <span>
                                                {i == 1
                                                    ? 'Sunday'
                                                    : i == 2
                                                    ? 'Monday'
                                                    : i == 3
                                                    ? 'Tuesday'
                                                    : i == 4
                                                    ? 'Wednesday'
                                                    : i == 5
                                                    ? 'Thursday'
                                                    : i == 6
                                                    ? 'Friday'
                                                    : 'Saturday'}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() =>
                            selectedUserAction({
                                action: 'edit',
                                data: data,
                            })
                        }
                        startIcon={<EditOutlined />}
                        style={{ width: 'fit-content' }}
                    >
                        Edit information
                    </Button>
                    <Button
                        startIcon={<Delete />}
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() =>
                            selectedUserAction({
                                action: 'delete',
                                data: data,
                            })
                        }
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewCoupon;
