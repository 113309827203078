/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DeleteOutline } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';

import { getCategories } from '../api/getCategories';
import { useMenuProducts } from '../api/getMenuProducts';
import { getProductsByCategory, getProductsWithCategory } from '../api/getProducts';
import { Menu } from '../type';
import { Button, Spinner } from '@/components/Elements';

import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { getProducts } from '@/features/delivery/api/getProducts';

const AssignProducts = ({ value, menuData, closeDialog, selectedUserAction }: any) => {
    const [data, setData] = useState<Menu | any>(null);
    const [products, setProducts] = useState<any>([]);
    const [searchVal, setSearchVal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<string>('');
    const [categories, setCategories] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState<Array<any>>([]);
    const menuProductsQuery = useMenuProducts(menuData?.menu_group_id, menuData?.id);

    const addProductToMenu = async (product_id: number) => {
        await axios
            .post('v1/add-menu', {
                product_id: product_id,
                menu_group_id: value === 0 ? menuData?.id : menuData?.menu_group_id,
            })
            .then((res) => {
                if (value !== 0) menuProductsQuery.refetch();
            });
    };

    useEffect(() => {
        setData(menuData);
    }, [menuData]);

    useEffect(() => {
        getCategories().then((list) => {
            setCategories(list);
            setIsActive(list[2].category_name);
            if (value === 0) {
                getProducts().then((product) => {
                    setProducts(product);
                });
            } else {
                getProductsByCategory(list[2].id).then((list) => {
                    setProducts(list);
                });
            }
        });
    }, []);

    const handleCategoryChange = (category: any) => {
        setIsLoading(true);
        setIsActive(category.category_name);
        getProductsWithCategory(category.id).then((list) => {
            setProducts(list);
        });
        setIsLoading(false);
    };

    const showAssignSuccess = () => {
        useNotificationStore.getState().addNotification({
            type: 'success',
            title: 'Success',
            message: 'Products Assigned to Menu!',
        });
    };

    const filteredProducts = products?.filter((product: any) => {
        if (searchVal) {
            return product?.product_name?.toLowerCase()?.includes(searchVal.toLowerCase());
        } else if (isActive) {
            const selectedCategory: any = categories?.find(
                (el: any) => el?.category_name === isActive
            );
            return product?.product_category_id === selectedCategory?.id;
        }
    });

    const filteredCategories = categories?.filter((category: any) =>
        category.category_name === 'Hero' || category.category_name === 'Recommended'
            ? null
            : category
    );

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {data?.menu_group_name} / Assign Products
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12 col-md-8 border-r">
                            <div className="order-new-search pb-4 pt-4 d-flex justify-content-between align-items-center">
                                <p className="mb-0">
                                    <strong>Products</strong>
                                </p>
                                <div
                                    className="input-group search make-search"
                                    style={{ width: 'max-content' }}
                                >
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        style={{ margin: '0 !important' }}
                                        value={searchVal}
                                        onChange={(e) => setSearchVal(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="order-list-tabs">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    {filteredCategories?.map((category) => {
                                        const { id, category_name } = category;
                                        return (
                                            <li key={id} className="nav-item" role="presentation">
                                                <button
                                                    className={`nav-link ${
                                                        isActive === category_name && 'active'
                                                    }`}
                                                    type="button"
                                                    role="tab"
                                                    onClick={() =>
                                                        isActive !== category_name
                                                            ? handleCategoryChange(category)
                                                            : null
                                                    }
                                                >
                                                    {category_name}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <div className="row">
                                    {products.length === 0 ? (
                                        <h2>No products avialable</h2>
                                    ) : (
                                        filteredProducts?.map((product: any) => (
                                            <div
                                                className="col-12 col-md-6"
                                                key={product?.id}
                                                onClick={(e) => {}}
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="flex justify-between items-center">
                                                            <div className="flex gap-2">
                                                                <div>
                                                                    <img
                                                                        className="w-10"
                                                                        alt="cookie"
                                                                        src={product.product_image}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <h5
                                                                        className="card-title"
                                                                        style={{
                                                                            color: '#111111',
                                                                            fontWeight: '600',
                                                                            fontSize: '16px',
                                                                        }}
                                                                    >
                                                                        {product?.product_name}
                                                                    </h5>

                                                                    <p className="">
                                                                        {value === 0
                                                                            ? `$${(product?.product_base_price
                                                                                  ? product?.product_base_price /
                                                                                    100
                                                                                  : product?.price /
                                                                                    100
                                                                              ).toFixed(2)}`
                                                                            : `$${product?.price}`}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="all-bx mt-2">
                                                                <label className="checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="selected_product"
                                                                        name="selected_product"
                                                                        value={product?.id}
                                                                        onChange={() => {
                                                                            addProductToMenu(
                                                                                product?.id
                                                                            ).then(() => {
                                                                                const temp: any = [
                                                                                    ...selectedProducts,
                                                                                ];
                                                                                temp.push(product);
                                                                                setSelectedProducts(
                                                                                    [...temp]
                                                                                );
                                                                            });
                                                                        }}
                                                                    />
                                                                    <span></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="pb-2">
                                <strong>Selected Products</strong>
                            </div>
                            <hr />
                            <div>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <tbody>
                                        {menuProductsQuery.isFetching ? (
                                            <div className="w-full h-48 flex justify-center items-center">
                                                <Spinner size="sm" />
                                            </div>
                                        ) : (
                                            selectedProducts?.map((product) => (
                                                <tr key={product?.id} className="noborder">
                                                    <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                        {product.product_name} /{' '}
                                                        <span className="text-blue-600">
                                                            $
                                                            {(
                                                                (product.product_base_price
                                                                    ? product.product_base_price
                                                                    : product?.price) / 100
                                                            ).toFixed(2)}
                                                        </span>
                                                    </td>

                                                    <td className="px-6 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                                        <span
                                                            className="dark-tx"
                                                            //     onClick={async () => {
                                                            //      await axios.post(
                                                            //          `v1/update-menu/${product?.id}`,
                                                            //          {
                                                            //              active: false,
                                                            //          }
                                                            //      );
                                                            // }}
                                                        >
                                                            <DeleteOutline />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() => {
                            showAssignSuccess();
                            closeDialog();
                        }}
                        startIcon={<CheckCircleOutlineIcon />}
                        style={{ width: 'fit-content' }}
                    >
                        Assign Products
                    </Button>
                    <Button
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AssignProducts;
