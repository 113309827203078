import React, { useEffect, useState } from 'react';
import { getStores } from '../api/getStores';
import { axios } from '@/lib/axios';
import { Spinner } from '@/components/Elements';

const OrderType = ({ handleOrder, orderData, setOrderData }) => {
    const locAPI = process.env.REACT_APP_LOCATION_API_URL;
    const [delivery, setDelivery] = useState<boolean>(false);
    const [stores, setStores] = useState<any[]>([]);
    const [searchval, setSearchval] = useState();
    const [searchloc, setSearchloc] = useState<any[]>([]);
    const [sresvisible, setSresvisible] = useState(false);
    const [latlong, setLatlong] = useState();
    const [userlocation, setUserlocation] = useState();

    useEffect(() => {
        getStores().then((stores) => setStores(stores));
        getLocation();
    }, []);

    useEffect(() => {
        if (userlocation?.address_line2 != null) {
            setOrderData({
                reward: true,
                order_type_id: '17',
                store: orderData.store,
                address: orderData.address,
                delivery_address: userlocation.address_line2,
                lat: latlong.lat,
                long: latlong.long,
            });
        }
    }, [userlocation]);

    const handleOnChange = (e) => {
        handleOrder(e.target);
    };

    // for automatic location with lat and long
    const getLocation = async () => {
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(showPosition);
        }
    };

    const showPosition = async (position) => {
        setLatlong({
            lat: position.coords.latitude,
            long: position.coords.longitude,
        });
        await fetch(
            `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json&apiKey=${locAPI}`
        )
            .then((response) => response.json())
            .then((result) => {
                setUserlocation(result.results[0]);
            })
            .catch((error) => {});
    };

    ///////////

    // for searching locations
    const handleSearch = async (e) => {
        setSearchval(e.target.value);
        await fetch(
            `https://api.geoapify.com/v1/geocode/autocomplete?text=${searchval}&format=json&apiKey=${locAPI}`
        )
            .then((response) => response.json())
            .then((result) => {
                setSearchloc(result.results);
            })
            .catch((error) => {});
    };

    return userlocation == null ? (
        <>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="h-full w-full flex items-center justify-center">
                        <Spinner size="xl" />
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>
            <div className="collect-rewards mt-4">
                <div className="rewards-right d-flex">
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="order_type_id"
                            id="Pickup-order"
                            defaultChecked
                            value={17}
                            onChange={handleOnChange}
                            onClick={() => setDelivery(false)}
                        />
                        <label className="form-check-label" htmlFor="Pickup-order">
                            Pickup
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="order_type_id"
                            id="Delivery-order"
                            value={18}
                            onChange={handleOnChange}
                            onClick={() => setDelivery(true)}
                        />
                        <label className="form-check-label" htmlFor="Delivery-order">
                            Delivery
                        </label>
                    </div>
                </div>
            </div>
            <div></div>
            <div className="col-md-12">
                <div className="form-group pt-3 pb-1">
                    <label htmlFor="store">Store</label>
                    <select
                        className="form-control"
                        name="store"
                        defaultValue={orderData.store}
                        onChange={handleOnChange}
                    >
                        <option value="">Select Store</option>
                        {stores.map(({ id, location_name }) => (
                            <option key={id} value={id}>
                                {location_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {delivery && (
                <div className="col-md-12">
                    <div className="form-group pt-3 pb-3">
                        <label htmlFor="address">Delivery Address</label>
                        <input
                            className="form-control"
                            name="address"
                            onChange={(e) => {
                                handleSearch(e);
                                handleOnChange(e);
                            }}
                            value={searchval}
                            onClick={() => {
                                setSresvisible(true);
                            }}
                        />
                    </div>
                    <ul
                        className={
                            sresvisible ? 'showuser-list location_list visible' : 'invisible'
                        }
                        style={{}}
                    >
                        {searchloc ? (
                            searchloc.length > 0 ? (
                                searchloc.map((location) => {
                                    const { place_id } = location;
                                    return (
                                        <li
                                            key={place_id}
                                            onClick={() => {
                                                setSearchloc([location]);
                                                setSearchval(
                                                    `${location.address_line1},${location.address_line2}`
                                                );
                                                setSresvisible(false);
                                                orderData.address = location.address_line1;
                                            }}
                                        >
                                            {location.address_line1}, {location.address_line2}
                                        </li>
                                    );
                                })
                            ) : (
                                <li style={{ background: '#fff' }}>Please type something!</li>
                            )
                        ) : null}
                    </ul>
                </div>
            )}
            <div className="pb-4 pt-4">
                <p>
                    Your Current Location:{' '}
                    <b>
                        {userlocation.address_line1}, {userlocation.address_line2}
                    </b>
                </p>
            </div>
        </>
    );
};

export default OrderType;
