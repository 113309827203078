export const HeadCells = [
    {
        id: 'corporate_name',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        sort: true,
        type: 'text',
    },
    {
        id: 'corporate_email',
        numeric: true,
        disablePadding: false,
        label: 'Email',
        sort: true,
        type: 'text',
    },
    {
        id: 'corporate_phone',
        numeric: true,
        disablePadding: false,
        label: 'Phone',
        sort: true,
        type: 'text',
    },
    {
        id: 'corporate_creation_date',
        numeric: true,
        disablePadding: false,
        label: 'Created',
        sort: true,
        type: 'date',
    },
    {
        id: 'corporate_last_ordered',
        numeric: true,
        disablePadding: false,
        label: 'Last order',
        sort: true,
        type: 'date',
    },
    {
        id: 'assigned_employees',
        numeric: true,
        disablePadding: false,
        label: 'Associated users',
        sort: true,
        type: 'text',
    },
    {
        id: 'corporate_active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    'corporate_name',
    'corporate_email',
    'corporate_phone',
    'corporate_creation_date',
    'corporate_last_ordered',
    'assigned_employees',
    'corporate_active',
];
