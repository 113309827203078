//import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';

import { Corporation } from '../type';
//import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getCorpUsers = async (id: number): Promise<Corporation[]> => {
    const { data } = await axios.get(`v1/get-corporation-users/${id}`);
    return data.users;
};

//type QueryFnType = typeof getAllCorps;

//type UseCorpsOptions = {
//    config?: QueryConfig<QueryFnType>;
//};

//export const useCorps = ({ config }: UseCorpsOptions = {}) => {
//    return useQuery<ExtractFnReturnType<QueryFnType>>({
//        ...config,
//        queryKey: ['corps'],
//        queryFn: () => getAllCorps(),
//    });
//};
