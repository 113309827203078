import { Navigate, useRoutes } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { Landing } from '@/features/misc';
import { useAuth } from '@/lib/auth';

const RedirectChecker = (userDetails: any) => {
    const auth = useAuth();
    return auth.user ? (
        <Navigate
            to={userDetails?.role_name === 'General Manager' ? '/app/employees' : '/app/users'}
        />
    ) : (
        <Navigate to="/auth/login" />
    );
};

export const AppRoutes = () => {
    const [userDetails, setUserDetails] = useState<any>({});
    const ls_user_details: any = localStorage.getItem('userDetails');
    const parsedUserDetails = JSON.parse(ls_user_details);
    useEffect(() => {
        setUserDetails({ ...parsedUserDetails });
    }, []);

    const auth = useAuth();
    const commonRoutes = [
        { path: '/', element: <RedirectChecker userDetails={userDetails} /> },
        { path: '*', element: <RedirectChecker userDetails={userDetails} /> },
    ];

    const routes = auth.user ? protectedRoutes : publicRoutes;

    const element = useRoutes([...routes, ...commonRoutes]);

    return <>{element}</>;
};
