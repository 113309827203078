export const HeadCells = [
    {
        id: 'gift_card_code',
        numeric: false,
        disablePadding: true,
        label: 'Gift Card Code',
        sort: true,
        type: 'text',
    },
    {
        id: 'customer',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
        sort: true,
        type: 'text',
    },
    {
        id: 'gift_card_purchaser',
        numeric: false,
        disablePadding: false,
        label: 'Created By',
        sort: true,
        type: 'text',
    },
    {
        id: 'gift_card_amout',
        numeric: false,
        disablePadding: false,
        label: 'Amount',
        sort: true,
        type: 'text',
    },
    {
        id: 'gift_card_purchased',
        numeric: false,
        disablePadding: false,
        label: 'Purchased',
        sort: true,
        type: 'dateTime',
    },
    {
        id: 'active',
        numeric: true,
        disablePadding: false,
        label: 'Active',
        sort: true,
        type: 'boolean',
    },
];
export const dataFields = [
    'gift_card_code',
    'customer',
    'gift_card_purchaser',
    'gift_card_amout',
    'gift_card_purchased',
    'active',
];
