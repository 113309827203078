/* eslint-disable jsx-a11y/no-onchange */
import { Button } from '@/components/Elements';
import { svgPaths } from '@/components/Layout/SvgPaths';
import { useNotificationStore } from '@/stores/notifications';

export function TableHeader(
    searchVal: string,
    handleSearch: (e: any) => void,
    statusFilter: string,
    setStatusFilter?: (e?: any) => void,
    checkboxFilter?: string,
    setCheckboxFilter?: any,
    selectedUserAction?: any,
    locations?: any,
    setSelectedLocation?: (e: any) => void,
    selectedLocation?: any,
    isShowLocationDropdown = true
) {
    const order_status_list = ['new', 'received', 'packing', 'assigned', 'dispatched', 'delivered'];
    return (
        <div className="table-header">
            <form>
                <div className="row">
                    <div className="col-12 col-md-10">
                        <div className="employee-table-head make-order flex gap-4">
                            <div className="checkboxes flex align-items-center gap-4">
                                <div className="all-bx">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="all"
                                            name="all"
                                            value="All"
                                            checked={checkboxFilter === 'all'}
                                            onChange={() =>
                                                checkboxFilter !== 'all' && setCheckboxFilter('all')
                                            }
                                        />
                                        <span>All</span>
                                    </label>
                                </div>
                                <div className="all-bx Pickup">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="Pickup"
                                            name="Pickup"
                                            value="Pickup"
                                            checked={checkboxFilter === 'Pickup'}
                                            onChange={() => {
                                                checkboxFilter === 'Pickup'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('Pickup');
                                            }}
                                        />
                                        <span>Pickup</span>
                                    </label>
                                </div>
                                <div className="all-bx Delivery">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="Delivery"
                                            name="Delivery"
                                            value="Delivery"
                                            checked={checkboxFilter === 'Delivery'}
                                            onChange={() => {
                                                checkboxFilter === 'Delivery'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('Delivery');
                                            }}
                                        />
                                        <span>Delivery</span>
                                    </label>
                                </div>
                                <div className="all-bx In-Store">
                                    <label className="checkbox mb-0">
                                        <input
                                            type="checkbox"
                                            id="In-Store"
                                            name="In-Store"
                                            value="In-Store"
                                            checked={checkboxFilter === 'In-Store'}
                                            onChange={() => {
                                                checkboxFilter === 'In-Store'
                                                    ? setCheckboxFilter('all')
                                                    : setCheckboxFilter('In-Store');
                                            }}
                                        />
                                        <span>In-Store</span>
                                    </label>
                                </div>
                            </div>
                            <div className="input-group d-block">
                                <div
                                    className="form-group w-full flex items-center"
                                    style={{ columnGap: '10px' }}
                                >
                                    <select
                                        value={statusFilter}
                                        className="form-control w-full"
                                        style={{ maxWidth: '200px' }}
                                        onChange={(e) =>
                                            setStatusFilter && setStatusFilter(e.target.value)
                                        }
                                    >
                                        <option value="">Select Status</option>
                                        {order_status_list.map((status) => (
                                            <option
                                                key={status}
                                                value={status}
                                                className="capitalize"
                                            >
                                                {status}
                                            </option>
                                        ))}
                                    </select>
                                    {isShowLocationDropdown ? (
                                        <select
                                            value={selectedLocation?.location_name}
                                            className="form-control w-100 max-w-[100px]"
                                            onChange={(e) =>
                                                setSelectedLocation &&
                                                setSelectedLocation(e.target.value)
                                            }
                                            style={{ maxWidth: '200px' }}
                                        >
                                            <option value="">Select Location</option>
                                            {locations?.length > 0
                                                ? locations.map((location: any) => (
                                                      <option
                                                          key={location?.id}
                                                          value={location?.location_name}
                                                          className="capitalize"
                                                      >
                                                          {location?.location_name}
                                                      </option>
                                                  ))
                                                : null}
                                        </select>
                                    ) : null}
                                    <div className="input-group search w-full">
                                        <input
                                            type="text"
                                            className="form-control w-full"
                                            placeholder="Search"
                                            value={searchVal}
                                            onChange={(e) => handleSearch && handleSearch(e)}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <i className="fas fa-search"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2">
                        <div className="add-btn" style={{ float: 'right' }}>
                            <Button
                                startIcon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={svgPaths['Orders'].width}
                                        height={svgPaths['Orders'].height}
                                        viewBox={svgPaths['Orders'].viewBox}
                                        fill="none"
                                    >
                                        <path d={svgPaths['Orders'].path} fill="#fff" />
                                    </svg>
                                }
                                onClick={() => selectedUserAction({ action: 'create', data: null })}
                            >
                                Make Order
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}
