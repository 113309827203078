import { PersonAddAltOutlined } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { addUser } from '../api/addUser';
import { Button } from '@/components/Elements';
import { useNotificationStore } from '@/stores/notifications';
import { handleDateFormat } from '@/utils/commonHelperFunc';


function ShowUsers({ usersData, showList, corpId, closeDialog }) {
    const { addNotification } = useNotificationStore();
    const [list, setList] = useState<string>(usersData);
    const [selectedUser, setSelectedUser] = useState<any>(false);
    const [isPrimaryUser, setIsPrimaryUser] = useState<boolean>(false);
    useEffect(() => {
        setList(usersData);
    }, [usersData]);
    const handleSubmit = () => {
        closeDialog();
        const { success, message } = addUser(selectedUser.id, corpId, isPrimaryUser);
        addNotification({
            type: 'success',
            title: "User Added",
        });
    };
    return (
        <>
            {!selectedUser ? (
                <>
                    <ul
                        className="showuser-list"
                        style={{
                            width: '100%',
                            maxHeight: '50vh',
                            boxShadow:
                                '0px 0px 4px rgba(0, 0, 0, 0.1), 0px 16px 20px rgba(0, 0, 0, 0.06)',
                            padding: '4px',
                            margin: '0 auto',
                            border: '1px solid #A3BED9',
                            overflow: 'auto',
                            marginBottom: '10px',
                            borderRadius: '6px',
                        }}
                    >
                        {showList ? (
                            list.length > 0 ? (
                                list.map((user) => {
                                    const { id, first_name, last_name, user_creation_date } = user;
                                    return (
                                        <li key={id} onClick={() => setSelectedUser(user)}>
                                            {`${first_name} ${last_name} - ${handleDateFormat(
                                                user_creation_date
                                            )}`}
                                        </li>
                                    );
                                })
                            ) : (
                                <li style={{ background: '#fff' }}>not found</li>
                            )
                        ) : null}
                    </ul>
                    {showList && list.length == 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '1.5rem 0 0 0',
                            }}
                        >
                            <Button
                                startIcon={<PersonAddAltOutlined />}
                                style={{ width: 'fit-content', marginRight: '10px' }}
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                            //data-bs-dismiss="modal"
                            >
                                Add new user
                            </Button>
                            <Button
                                data-bs-target="#employeeModal1"
                                data-bs-toggle="modal"
                                style={{ width: 'fit-content' }}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <div className="view-user-details">
                    <ul className="user-details-list">
                        <li>
                            <strong>Birthday:</strong>
                            <span>{handleDateFormat(selectedUser.user_birthday)}</span>
                        </li>
                        <li>
                            <strong>Email:</strong> <span>{selectedUser.user_email}</span>
                        </li>
                        <li>
                            <strong>Phone:</strong> <span>{selectedUser.user_phone}</span>
                        </li>
                        <li>
                            <strong>Created:</strong>
                            <span>{handleDateFormat(selectedUser.user_creation_date)}</span>
                        </li>
                    </ul>
                    <div className="form-group border-bottom">
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Primary User"
                            value={isPrimaryUser}
                            onChange={(e) => setIsPrimaryUser(e.target.checked)}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '1.5rem 0 0 0',
                        }}
                    >
                        <Button
                            startIcon={<PersonAddAltOutlined />}
                            style={{ width: 'fit-content', marginRight: '10px' }}
                            onClick={handleSubmit}
                        >
                            Add User
                        </Button>
                        <Button
                            style={{ width: 'fit-content' }}
                            variant="secondary"
                            onClick={() => setSelectedUser(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default ShowUsers;
