import { axios } from '@/lib/axios';

export const getCart = async (id: number, isDeliveryType = false, lat?: number, long?: number) => {
    const URL = isDeliveryType ? `v1/get-cart/${id}?lat=${lat}&long=${long}` : `v1/get-cart/${id}`;
    const { data } = await axios.get(URL);
    return data;
};

export const getDeferredCart = async () => {
    const { data } = await axios.get(`v1/get-deferred-cart-group`);
    return data?.groups;
};
