/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react/no-unknown-property */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect, useState } from 'react';
import * as z from 'zod';

import moment from 'moment';
import { getAllMenu } from '../api/CRUD/getAllMenu';
import { Button } from '@/components/Elements';
import { Form } from '@/components/Form';

import { axios } from '@/lib/axios';

const schema = z.object({
    // price_override: z.string(),
});

type LoginValues = {
    price_override: string;
    status: boolean;
};

const EditProduct = ({ menuData, action, closeDialog, selectedUserAction, value }: any) => {
    const [priceOverride, setPriceOverride] = useState(null);
    const [menuGroupData, setMenuGroupData] = useState<any>(null);
    const [locations, setLocations] = useState<any>();
    const intialMenuData: any = {
        location_id: menuData?.location_id,
        menu_id: menuData?.id,
        menu_group_id: menuData?.menu_group_id,
        menu_group_name: menuData?.menu_group_name || '',
        product_name: menuData?.product_name,
        price_override: menuData?.price || '',
        active: menuData?.active,
        activation: menuData?.activation,
        activation_time: moment.utc(menuData?.activation).format('HH:mm:ss'),
        activation_date: moment.utc(menuData?.activation).format('YYYY-MM-DD'),
        expiration: menuData?.expiration,
        expiration_time: moment.utc(menuData?.expiration).format('HH:mm:ss'),
        expiration_date: moment.utc(menuData?.expiration).format('YYYY-MM-DD'),
    };
    const [data, setData] = useState<any>(intialMenuData);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        if (name == 'active') {
            setData({ ...data, active: data?.active === 1 ? 0 : 1 });
        }
    };
    const getMenuGroupData = async () => {
        const data = await getAllMenu();
        setMenuGroupData(data);
    };
    const getAllLocations = async () => {
        const { data } = await axios.get(`v1/get-locations/all`);
        const filteredActiveLocations = data?.locations?.filter((el: any) => !!el?.location_active);
        const selectedLocation = filteredActiveLocations?.find(
            (el: any) => el?.location_name === menuData?.location_name
        );
        setData((prev: any) => ({ ...prev, location_id: selectedLocation?.id }));
        setLocations(filteredActiveLocations);
    };
    const updateProduct = async (params: any) => {
        //         {
        //     "active": true,
        //     "price_override": 2.0,
        //     "activation_time": "2022-02-03 00:00:00",
        //     "expiration_time" :  "2023-05-31 23:22:59"
        // }
        const updateData = {
            menu_group_id: data.menu_group_id,
            price_override: data.price_override,
            active: data.status,
            activation_time: data.activation_time,
            expiration_time: data.expiration_time,
        };
        const { success, message }: any = await axios.post(
            `v1/update-live-menu/${data.menu_id}`,
            params
        );
        return { success, message };
    };

    const updateMenuGroup = async (params: any) => {
        //         {
        //     "active": true,
        //     "price_override": 2.0,
        //     "activation_time": "2022-02-03 00:00:00",
        //     "expiration_time" :  "2023-05-31 23:22:59"
        // }
        const updateData = {
            menu_group_id: data.menu_group_id,
            menu_group_name: data.menu_group_name,
        };
        const menu_group_id = value === 0 ? menuData?.id : data.menu_group_id;
        const { success, message }: any = await axios.post(
            `v1/update-menu-group/${menu_group_id}`,
            params
        );
        return { success, message };
    };

    const handleSubmit = async () => {
        const utcConvertedActivation = moment(
            data?.activation_date + ' ' + data?.activation_time
        ).format('YYYY-MM-DD HH:mm:ss');
        const utcConvertedExpiration = moment(
            data?.expiration_date + ' ' + data?.expiration_time
        ).format('YYYY-MM-DD HH:mm:ss');
        const params = {
            active: data?.active === 1 ? true : false,
            location_id: data?.location_id,
            menu_goup_id: data?.menu_group_id,
            // price_override: priceOverride !== null ? priceOverride * 100 : data?.price_override,
            activation: utcConvertedActivation,
            expiration: utcConvertedExpiration,
            // new changes below :-
        };
        const groupData = {
            active: true,
            menu_group_name: data?.menu_group_name || '',
        };
        await updateProduct(params)
            .then(() => {
                closeDialog();
            })
            .catch();
    };
    const handleAllMenuEditSubmit = async () => {
        const params = {
            active: data?.active === 0 ? false : true,
            menu_group_name: data?.menu_group_name,
        };
        await updateMenuGroup(params).then(() => {
            closeDialog();
        });
    };
    useEffect(() => {
        getMenuGroupData();
        getAllLocations();
    }, []);
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {menuData.menu_group_name ? `${menuData.menu_group_name} / ` : ''}
                        {value === 0 ? 'Edit Menu' : 'Edit Menu Live'}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                {/* <div style={{ marginTop: '10px' }} className="modal-header">
                    <strong>{data?.product_name}</strong>
                    <div>
                        <strong>
                            Based Price: <span>${data?.price_override}</span>
                        </strong>
                    </div>
                </div> */}
                <div className="modal-body">
                    <div className="view-user-details">
                        <Form<LoginValues, typeof schema> onSubmit={() => {}} schema={schema}>
                            {({ register, formState }) => (
                                <>
                                    <div className="flex justify-between items-center w-full row">
                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <label
                                                    htmlFor="price_override"
                                                    className="control-label"
                                                >
                                                    Price Override
                                                </label>
                                                <div className="form-group w-full">
                                                    <InputField
                                                        onChange={(e) =>
                                                            setPriceOverride(e?.target.value)
                                                        }
                                                        className="form-control min-w-full"
                                                        name="price_override"
                                                        placeholder="$80"
                                                        value={
                                                            priceOverride !== null
                                                                ? priceOverride
                                                                : data.price_override
                                                        }
                                                        error={formState.errors['price_override']}
                                                        registration={register('price_override')}
                                                    />
                                                </div>
                                            </div> */}

                                            <div className="pt-4 col-md-3 ">
                                                <div className="flex gap-3">
                                                    <label htmlFor="status">Active:</label>
                                                    {data?.active === 1 ? 'Yes' : 'No'}
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id="flexSwitchCheckChecked"
                                                            name="active"
                                                            checked={
                                                                data?.active === 1 ? true : false
                                                            }
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {/* <div className="col-md-6">
                                                <label
                                                    htmlFor="activation_date"
                                                    className="control-label"
                                                >
                                                    Menu Group Name
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        name="menu_group_name"
                                                        type="text"
                                                        defaultValue={data?.menu_group_name}
                                                        // value={data?.menu_name}
                                                        className=""
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div> */}
                                            {value === 1 ? (
                                                <div className="col-md-6">
                                                    <label
                                                        htmlFor="location_id"
                                                        className="control-label"
                                                    >
                                                        Assign Menu
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            name="location_id"
                                                            onChange={handleChange}
                                                            value={data.location_id}
                                                            error={formState.errors['location_id']}
                                                            registration={register('location_id')}
                                                        >
                                                            <option value="">Select</option>
                                                            {locations?.length > 0 &&
                                                                locations?.map(
                                                                    ({
                                                                        id,
                                                                        location_name,
                                                                    }: any) => (
                                                                        <option value={id} key={id}>
                                                                            {location_name}
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {value === 0 ? (
                                                <div
                                                    className={`${
                                                        value === 1 ? 'col-md-6' : 'col-md-12'
                                                    } `}
                                                >
                                                    <label
                                                        htmlFor="menu_group_name"
                                                        className="control-label"
                                                    >
                                                        Menu Group Name
                                                    </label>
                                                    <input
                                                        onChange={handleChange}
                                                        type="text"
                                                        className="form-control"
                                                        name="menu_group_name"
                                                        placeholder="Menu Group Name"
                                                        required
                                                        value={data?.menu_group_name}
                                                    />
                                                    {/* <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            name="menu_group_name"
                                                            onChange={handleChange}
                                                            value={data?.menu_group_name}
                                                            error={
                                                                formState.errors['menu_group_name']
                                                            }
                                                            registration={register(
                                                                'menu_group_name'
                                                            )}
                                                        >
                                                            <option value="">
                                                                Select Menu Group
                                                            </option>
                                                            {menuGroupData?.map(
                                                                ({ id, menu_group_name }: any) => (
                                                                    <option
                                                                        value={menu_group_name}
                                                                        key={id}
                                                                    >
                                                                        {menu_group_name}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <div className={`col-md-6`}>
                                                    <label
                                                        htmlFor="menu_group_id"
                                                        className="control-label"
                                                    >
                                                        Menu Group Name
                                                    </label>
                                                    <div className="form-group">
                                                        <select
                                                            className="form-control"
                                                            name="menu_group_id"
                                                            onChange={handleChange}
                                                            value={data?.menu_group_id}
                                                            error={formState.errors['id']}
                                                            registration={register('id')}
                                                        >
                                                            <option value="">
                                                                Select Menu Group
                                                            </option>
                                                            {menuGroupData?.map(
                                                                ({ id, menu_group_name }: any) => (
                                                                    <option value={id} key={id}>
                                                                        {menu_group_name}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                            {value === 1 ? (
                                                <>
                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="activation_date"
                                                            className="control-label"
                                                        >
                                                            Activation Date
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="activation_date"
                                                                type="date"
                                                                value={data?.activation_date}
                                                                className="form-control timePicker"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="activation_time"
                                                            className="control-label"
                                                        >
                                                            Activation Time
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="activation_time"
                                                                type="time"
                                                                value={data?.activation_time}
                                                                className="form-control timePicker"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="expiration_date"
                                                            className="control-label"
                                                        >
                                                            Expiration Date
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="expiration_date"
                                                                type="date"
                                                                value={data?.expiration_date}
                                                                className="form-control"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="expiration_time"
                                                            className="control-label"
                                                        >
                                                            Expiration Time
                                                        </label>
                                                        <div className="form-group">
                                                            <input
                                                                name="expiration_time"
                                                                type="time"
                                                                value={data?.expiration_time}
                                                                className="form-control timePicker"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className="modal-footer"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '1rem',
                                        }}
                                    >
                                        {value === 0 ? (
                                            <Button
                                                type="submit"
                                                startIcon={<CheckCircleOutlineIcon />}
                                                style={{ width: 'fit-content' }}
                                                onClick={handleAllMenuEditSubmit}
                                            >
                                                Save Changes
                                            </Button>
                                        ) : (
                                            <Button
                                                type="submit"
                                                startIcon={<CheckCircleOutlineIcon />}
                                                style={{ width: 'fit-content' }}
                                                onClick={handleSubmit}
                                            >
                                                Save Changes
                                            </Button>
                                        )}
                                        <Button
                                            variant="secondary"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => closeDialog()}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
