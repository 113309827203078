import { CorporateFare, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { Menu, User } from '../type';
import { Button } from '@/components/Elements';
import Delete from '@/components/Icons/Delete';
import { handleDateFormat } from '@/utils/commonHelperFunc';

import { axios } from '@/lib/axios';

const ViewInquiry = ({ inquiryData, closeDialog, selectedUserAction }) => {
    const [data, setData] = useState<Menu | null | any>(null);

    useEffect(() => {
        setData(inquiryData);
    }, [inquiryData]);

    const addCorporateAccountRequest = async () => {
        const newData = await axios.post('v1/create-corporation', {
            corporate_name: data.corporate_request_corporate_name,
            corporate_email: data.corporate_request_email,
            corporate_phone: data.corporate_request_phone,
        });
        const responseOfSecond = await axios.post(`v1/add-corporation-address/${newData.data.id}`, {
            address_name: 'Office',
            address_1: data.corporate_request_address_1,
            address_2: data.corporate_request_address2,
            city: data.corporate_request_city,
            state: data.corporate_request_state,
            zip: data.corporate_request_zip,
            country: 'USA',
        });
        deleteCorporateInquiry(data.id);
        closeDialog();
    };

    const updateCustomInquiry = async (id, flag) => {
        await axios.post('v1/update-custom-orders-inquiries', {
            id: id,
            inquiry_read: flag == 0 ? false : true,
        });
    };
    const updateCorporateInquiry = async (id, flag) => {
        await axios.post('v1/update-corporate-request-inquiries', {
            id: id,
            inquiry_read: flag == 0 ? false : true,
        });
    };
    const updateDonationInquiry = async (id, flag) => {
        await axios.post('v1/update-donation-inquiries', {
            id: id,
            inquiry_read: flag == 0 ? false : true,
        });
    };

    const updateContactInquiry = async (id, flag) => {
        await axios.post('v1/update-contact-inquiries', {
            id: id,
            inquiry_read: flag == 0 ? false : true,
        });
    };

    const deleteCustomInquiry = async (id) => {
        await axios.post('v1/update-custom-orders-inquiries', {
            id: id,
            inquiry_active: true,
        });
    };
    const deleteCorporateInquiry = async (id) => {
        await axios.post('v1/update-corporate-request-inquiries', {
            id: id,
            inquiry_read: true,
            inquiry_status: 'approved',
        });
    };
    const deleteDonationInquiry = async (id) => {
        await axios.post('v1/update-donation-inquiries', {
            id: id,
            inquiry_active: true,
        });
    };
    const deleteContactInquiry = async (id) => {
        await axios.post('v1/update-contact-inquiries', {
            id: id,
            inquiry_active: true,
        });
    };

    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        {data?.req_type} #{data?.id}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="view-user-details company-details">
                        <div className="flex justify-between">
                            <div>
                                <strong>Created:</strong>{' '}
                                {moment(data?.created_at).format('MM/DD/YYYY, HH:mm A')}
                            </div>
                            <div>
                                <strong>
                                    {data?.req_type == 'Corporate Account Request'
                                        ? data.inquiry_status == 'new'
                                            ? 'New'
                                            : 'Approved'
                                        : data?.inquiry_active
                                        ? 'Active'
                                        : 'Deleted'}
                                </strong>
                            </div>
                        </div>

                        <hr className="mt-3 mb-3" />
                        {data?.req_type == 'Custom Order Request' && (
                            <div>
                                <ul className="custom-orders" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Full Name:</strong> <span>{data?.customer}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong> <span>{data?.custom_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong> <span>{data?.custom_phone}</span>
                                    </li>
                                </ul>
                                <div className="req-question pt-3">
                                    <strong>What type of order request is this?</strong>
                                    <hr className="mt-2 mb-2" />
                                    <p>{data?.custom_request_type}</p>
                                </div>
                                <div className="req-question pt-3">
                                    <strong>What type of order is this?</strong>
                                    <hr className="mt-2 mb-2" />
                                    <p>{data?.custom_order_type}</p>
                                </div>
                                <div className="req-question pt-3">
                                    <strong>What is your desired quantities of coockies?</strong>
                                    <hr className="mt-2 mb-2" />
                                    <p>{data?.custom_cookie_quantity}</p>
                                </div>
                                <div className="req-question pt-3">
                                    <strong>Tell us more about your order</strong>
                                    <hr className="mt-2 mb-2" />
                                    <p>{data?.custom_special_instructions}</p>
                                </div>
                            </div>
                        )}
                        {data?.req_type == 'Corporate Account Request' && (
                            <div>
                                <strong>Company Information</strong>
                                <hr className="mt-3 mb-3" />
                                <ul
                                    className="custom-orders mb-2"
                                    style={{ paddingBottom: '10px' }}
                                >
                                    <li>
                                        <strong>Company:</strong>{' '}
                                        <span>{data?.corporate_request_corporate_name}</span>
                                    </li>
                                    <li>
                                        <strong>Primary Contact:</strong>{' '}
                                        <span>{data?.corporate_request_corporate_name}</span>
                                    </li>
                                    <li>
                                        <strong>Position:</strong>{' '}
                                        <span>{data?.corporate_request_user_position}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong>{' '}
                                        <span>{data?.corporate_request_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong>{' '}
                                        <span>{data?.corporate_request_phone}</span>
                                    </li>
                                </ul>
                                <strong>Billing Information</strong>
                                <hr className="mt-2 mb-4" />
                                <ul className="custom-orders " style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Address 1:</strong>{' '}
                                        <span>{data?.corporate_request_address_1}</span>
                                    </li>
                                    <li>
                                        <strong>Address 2:</strong>{' '}
                                        <span>{data?.corporate_request_address2}</span>
                                    </li>
                                    <li>
                                        <strong>City :</strong>{' '}
                                        <span>{data?.corporate_request_city}</span>
                                    </li>
                                    <li>
                                        <strong>State:</strong>{' '}
                                        <span>{data?.corporate_request_state}</span>
                                    </li>
                                    <li>
                                        <strong>Zip:</strong>{' '}
                                        <span>{data?.corporate_request_zip}</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                        {data?.req_type == 'Contact Inquiries' && (
                            <div>
                                <ul className="custom-orders" style={{ paddingBottom: '10px' }}>
                                    <li>
                                        <strong>Full Name:</strong>
                                        <span>{data?.contact_full_name}</span>
                                    </li>
                                    <li>
                                        <strong>Email:</strong> <span>{data?.contact_email}</span>
                                    </li>
                                    <li>
                                        <strong>Phone:</strong> <span>{data?.contact_phone}</span>
                                    </li>
                                </ul>
                                <hr className="mt-2 mb-4" />
                                <strong>Give us the details</strong>
                                <hr className="mt-2 mb-2" />
                                <p>{data?.contact_comment}</p>
                            </div>
                        )}

                        {data?.req_type == 'Donation Request' && (
                            <div>
                                <div className="flex gap-4">
                                    <div className="flex-1">
                                        <strong>Customer</strong>
                                        <hr className="mt-3 mb-3" />
                                        <ul
                                            className="inquery-custom"
                                            style={{ paddingBottom: '40px' }}
                                        >
                                            <li>
                                                <strong>Full Name:</strong>
                                                <span>
                                                    {data?.donation_first_name}{' '}
                                                    {data?.donation_last_name}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Email:</strong>{' '}
                                                <span>{data?.donation_email}</span>
                                            </li>
                                            <li>
                                                <strong>Phone:</strong>{' '}
                                                <span>{data?.donation_phone}</span>
                                            </li>
                                            <li>
                                                <strong>City:</strong>{' '}
                                                <span>{data?.donation_city}</span>
                                            </li>
                                        </ul>

                                        <strong>Event Information</strong>
                                        <hr className="mt-2 mb-4" />
                                        <ul className="inquery-custom">
                                            <li>
                                                <strong>Event:</strong>{' '}
                                                <span>{data?.donation_event_name}</span>
                                            </li>
                                            <li>
                                                <strong>Website:</strong>{' '}
                                                <span>{data?.donation_event_site}</span>
                                            </li>
                                        </ul>
                                        <div className="event-d">
                                            <strong>Event Description</strong>
                                            <p>{data?.donation_event_description}</p>
                                        </div>
                                        <ul className="inquery-custom">
                                            <li>
                                                <strong>Event Date:</strong>{' '}
                                                <span>
                                                    {moment(data?.donation_event_datetime).format(
                                                        'MM/DD/YYYY'
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Event Time:</strong>{' '}
                                                <span>
                                                    {moment(data?.donation_event_datetime).format(
                                                        'HH:mm A'
                                                    )}
                                                </span>
                                            </li>
                                            <li>
                                                <strong>Event Location:</strong>{' '}
                                                <span>{data?.donation_event_location_name}</span>
                                            </li>
                                        </ul>
                                        <div className="event-d">
                                            <strong>Event Location Address</strong>
                                            <p>{data?.donation_event_location_address}</p>
                                        </div>
                                        <div className="event-d">
                                            <strong>Number of People Attending</strong>
                                            <p>{data?.donation_event_attendance}</p>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <strong>Organization Information</strong>
                                        <hr className="mt-3 mb-3" />
                                        <ul className="inquery-custom">
                                            <li>
                                                <strong>Organization:</strong>{' '}
                                                <span>{data?.donation_company}</span>
                                            </li>
                                            <li>
                                                <strong>Website:</strong>{' '}
                                                <span>{data?.donation_company_site}</span>
                                            </li>
                                        </ul>
                                        <div className="event-d">
                                            <strong>
                                                Is your organization a 501(c)(3) (non-profit
                                                organization)?
                                            </strong>
                                            <p>No</p>
                                        </div>
                                        <ul
                                            className="inquery-custom"
                                            style={{ paddingBottom: '24px' }}
                                        >
                                            <li>
                                                <strong>Tax form/w9:</strong> <span>zxv.file</span>
                                            </li>
                                        </ul>

                                        <strong>Additional</strong>
                                        <hr className="mt-2 mb-4" />
                                        <div className="event-d">
                                            <strong>
                                                Has Hot Box Cookies donated to your organization
                                                before?
                                            </strong>
                                            <p>{data?.donation_is_previous_done ? 'Yes' : 'No'}</p>
                                        </div>
                                        <div className="event-d">
                                            <strong>
                                                Will Hot Box Cookies be the exclusive DESSERT
                                                offering at your event?
                                            </strong>
                                            <p>
                                                {data?.donation_is_hbexclusivedessert
                                                    ? 'Yes'
                                                    : 'No'}
                                            </p>
                                        </div>
                                        <div className="event-d">
                                            <strong>
                                                Will Hot Box Cookies be the exclusive COOKIE
                                                offering at your event?
                                            </strong>
                                            <p>
                                                {data?.donation_is_hbexclusivecookie ? 'Yes' : 'No'}
                                            </p>
                                        </div>
                                        <div className="event-d">
                                            <strong>Desired Donation</strong>
                                            <p>{data?.donation_desired}</p>
                                        </div>
                                        <div className="event-d">
                                            <strong>Additional Comments</strong>
                                            <p>{data?.donation_comments}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                        border: 'none',
                    }}
                >
                    <Button
                        onClick={() => {
                            // selectedUserAction({
                            //     action: 'edit',
                            //     data: data,
                            // })
                            if (data.inquiry_read) {
                                data?.req_type == 'Custom Order Request'
                                    ? updateCustomInquiry(data.id, !data.inquiry_read)
                                    : data?.req_type == 'Corporate Account Request'
                                    ? updateCorporateInquiry(data.id, !data.inquiry_read)
                                    : data?.req_type == 'Donation Request'
                                    ? updateDonationInquiry(data.id, !data.inquiry_read)
                                    : updateContactInquiry(data.id, !data.inquiry_read);

                                closeDialog();
                            } else {
                                closeDialog();
                            }
                        }}
                        startIcon={''}
                        style={{ width: 'fit-content' }}
                    >
                        Mark as Unread
                    </Button>
                    <Button
                        startIcon={
                            data?.req_type == 'Corporate Account Request' &&
                            data.inquiry_status == 'new' ? (
                                ''
                            ) : (
                                <Delete />
                            )
                        }
                        style={{
                            width: 'fit-content',
                            display:
                                data?.req_type == 'Corporate Account Request' &&
                                data?.inquiry_status == 'approved'
                                    ? 'none'
                                    : 'flex',
                        }}
                        variant="secondary"
                        onClick={() => {
                            if (
                                data?.req_type == 'Corporate Account Request' &&
                                data.inquiry_status == 'new'
                            ) {
                                addCorporateAccountRequest();
                            } else {
                                if (data?.inquiry_active) {
                                    selectedUserAction({
                                        action: 'delete',
                                        data: data,
                                    });
                                } else {
                                    data?.req_type == 'Custom Order Request'
                                        ? deleteCustomInquiry(data.id)
                                        : data?.req_type == 'Corporate Account Request'
                                        ? deleteCorporateInquiry(data.id)
                                        : data?.req_type == 'Donation Request'
                                        ? deleteDonationInquiry(data.id)
                                        : deleteContactInquiry(data.id);
                                    closeDialog();
                                }
                            }
                        }}
                    >
                        {data?.req_type == 'Corporate Account Request' &&
                        data.inquiry_status == 'new'
                            ? 'Approve'
                            : data?.inquiry_active
                            ? 'Delete'
                            : 'Undo Delete'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ViewInquiry;
