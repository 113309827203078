import { useQuery } from 'react-query';

import { CustomInquiry } from '../../type';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';


export const getCustomInquiry = async (): Promise<CustomInquiry[]> => {
    const { data } = await axios.get(`v1/get-custom-orders-inquiries`);
    return data.inquiries;
};

type QueryFnType = typeof getCustomInquiry;

type UseCorpsOptions = {
    config?: QueryConfig<QueryFnType>;
};

export const useCustomInquiry = ({ config }: UseCorpsOptions = {}) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: ['customInquiry'],
        queryFn: () => getCustomInquiry(),
    });
};
