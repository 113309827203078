import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useState } from 'react';
import { Button } from '@/components/Elements';
import { InputField } from '@/components/Form';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

function EditMenu({ posData, closeDialog, callbackFn }: any) {
    const { addNotification } = useNotificationStore();
    const [editMenuData, setEditMenuData] = useState({
        listOrder: posData?.menu_list_order,
        price_override: posData?.price_override,
    });
    const [priceOverride, setPriceOverride] = useState(null);
    const handleUpdateEditMenu = async () => {
        try {
            const data = {
                active: posData?.active === 1 ? true : false,
                list_order: editMenuData?.listOrder,
                price_override:
                    priceOverride !== null ? priceOverride * 100 : editMenuData?.price_override,
            };
            await axios.post(`v1/update-menu/${posData?.menu_id}`, data).then(() => {
                addNotification({
                    type: 'success',
                    title: 'Updated Successfully',
                    message: 'Update Assignment Products Successfully.',
                });
                callbackFn(posData?.menu_group_id);
            });
        } catch (e) {
            console.log('error from handle update edit menu-->', e);
        }
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="viewModalLabel">
                        Edit - {posData?.product_name}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeDialog()}
                    ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <label
                                htmlFor="menu_group_name"
                                className="control-label editLocationLabels"
                            >
                                List Order
                            </label>
                            <div className="form-group ">
                                <input
                                    style={{ width: '100%', marginTop: '5px' }}
                                    type="text"
                                    onChange={(e: any) =>
                                        setEditMenuData((prev): any => ({
                                            ...prev,
                                            listOrder: e?.target?.value,
                                        }))
                                    }
                                    className="form-control"
                                    name="menu_group_name"
                                    placeholder="List Order"
                                    value={editMenuData.listOrder}
                                    // error={formState.errors['menu_group_name']}
                                    // registration={register('menu_group_name')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label
                                htmlFor="user_phone"
                                className="control-label editLocationLabels"
                            >
                                Price Override
                            </label>
                            <div className="form-group">
                                <input
                                    style={{ marginTop: '5px' }}
                                    type="text"
                                    className="form-control"
                                    name="price_override"
                                    placeholder="Product Override"
                                    value={
                                        priceOverride !== null
                                            ? priceOverride
                                            : (editMenuData?.price_override / 100).toFixed(2)
                                    }
                                    onChange={(e: any) => setPriceOverride(e?.target?.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal-footer"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '1rem',
                    }}
                >
                    <Button
                        onClick={() => {
                            handleUpdateEditMenu();
                        }}
                        startIcon={<CheckCircleOutlineIcon />}
                        style={{ width: 'fit-content' }}
                    >
                        Assign Products
                    </Button>
                    <Button
                        style={{ width: 'fit-content' }}
                        variant="secondary"
                        onClick={() => closeDialog()}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default EditMenu;
